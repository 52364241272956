/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dashboardIcons from '../../icons'
import { dispatch, subscribe } from '../../redux'
import HeaderBar from '../../components/HeaderBar'
import ColourPickers from '../../components/ColourPickers'
import SaveButton from '../../components/SaveButton'
import CodeEditor2 from '../../components/CodeEditor2'
import Box from '../../components/Box'
import H2 from '../../components/H2'
import ApplicationBlocksList from '../../components/ApplicationBlocksList'
import ListEditor from '../../components/ListEditor'

import { icons, isUuid, CustomHelmet, List, ListItem, Loading, Label, Input, UploadImage, Button, LinkButton, Form, ColourPicker } from '@openbox-app-shared'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .buttons {
    margin-bottom: 0 !important;
  }
  .component--header-bar .component--h1 {
    color: unset;
  }
  > .component--form + .component--box,
  > .component--form + .component--form,
  > .component--box + .component--form
  {
    margin-top: 1rem;
  }
  .component--colour-pickers {
    margin-top: 1rem;
  }
  .config-application-blocks {
    .component--h2 + * {
      margin-top: 1rem;
    }
  }
`

const genericCeProps = {
  controls: ['expand', 'save']
}

function RenderChild ({ context, dbfProps }) {
  const { user, match } = context.props 
  const { subEntity } = match.params
  const [hasMadeUpdate, setHasMadeUpdate] = useState(false)
  const [applicationBlocks, setApplicationBlocks] = useState()
  const [applicationBases, setApplicationBases] = useState()

  !applicationBlocks && (() => {
    window.sticky.applications.blocks.getAll(user)
      .then(setApplicationBlocks)
  })()

  function onAction (id, action) {
    action === 'delete' && (() => {
      const entity = applicationBases.find(_ => _.id === id)
      dispatch('APPLICATION_BASE_DELETE', { entity })
    })()
  }

  async function getApplicationBases () {
    const _ = (await window.sticky.applications.bases.getAll())
      .filter(_ => {
        if (_.hasPartnerId) {
          return user.isLoggedInAsPartner
        }
        if (_.hasUserId) {
          return true
        }
        return false
      })
    setApplicationBases(_)
  }

  useEffect(
    () => {
      const subscriptions = [
        subscribe(
          'APPLICATION_BASE_CREATE_GOOD',
          ({ why, id }) => {
            why === 'TabApplicationBases' && (async () => {
              await getApplicationBases()
              dispatch('BLINK', { ids: [id] })
            })()
          }
        ),
        subscribe(
          'APPLICATION_BASE_DELETE_GOOD',
          () => {
            getApplicationBases()
          }
        )
      ]
      ;(async () => {
        try {
          await getApplicationBases()
        } catch (e) {
          dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
        }
      })()
      return () => {
        subscriptions.forEach(s => s())
      }
    },
    []
  )

  const currentApplicationBase = applicationBases && applicationBases.find(_ => _.id === subEntity)

  async function onSave () {
    try {
      await window.sticky.applications.bases.save(currentApplicationBase)
      setHasMadeUpdate(false)
    } finally {
      getApplicationBases()
    }
  }

  function onChange (k, v) {
    const isJsony = ['price', 'copy'].includes(k)
    isJsony && (() => {
      try {
        currentApplicationBase[k] = JSON.parse(v)
      } catch (_) {}
    })()
    !isJsony && (() => {
      currentApplicationBase[k] = v
      setApplicationBases([...applicationBases]) // hack as calling setHasMadeUpdate(---) more than once only gets you one render cycle
    })()
    setHasMadeUpdate(true)
  }
  const caFormProps = {
    onChange
  }

  // 1 name: this.name
  // 2 new_name: this.newName
  // 3 background_color: this.backgroundColor
  // 4 foreground_color: this.foregroundColor
  // 6 settings_render: this.settingsRender
  // 7 category: this.category
  // 8 stickystore_application_id: this.stickystoreApplicationId
  // 9 price: JSON.stringify(this.price)
  // 1 icon: this.icon
  // 2 config_application_blocks: JSON.stringify(this.configApplicationBlocks)

  return (
    <StyledComponent>
      <CustomHelmet title={`Flow templates | Account | ${user.name}`} />
      {(!applicationBases || !applicationBlocks) && (
        <Loading />
      )}
      {currentApplicationBase && (
        <>
          <CustomHelmet title={currentApplicationBase.name} />
          <div className='buttons'>
            <SaveButton
              color='#26de81'
              onSave={onSave}
              canSave={hasMadeUpdate}
              handleErrors
            />
            <LinkButton
              to='/my/account/flow-templates'
              isSecondary
              sameTab
              className='go-back'
            >
              ← Back to flow templates
            </LinkButton>
          </div>
          <Form {...caFormProps} className='limit-width-1'>
            <Input
              value={currentApplicationBase.name}
              label='Name'
              name='name'
            />
            <Input
              value={currentApplicationBase.newName}
              label='(Optional) New flow(s) name'
              name='newName'
            />
          </Form>
          <Box className='limit-width-1'>
            <Form {...caFormProps}>
              <UploadImage
                label='Icon'
                onChange={v => onChange('icon', v.url)}
                value={currentApplicationBase.icon}
                show
              />
            </Form>
            <ColourPickers>
              <Form {...caFormProps}>
                <ColourPicker
                  name='backgroundColor'
                  label='Background color'
                  currentColour={currentApplicationBase.backgroundColor}
                />
                <ColourPicker
                  name='foregroundColor'
                  label='Foreground color'
                  currentColour={currentApplicationBase.foregroundColor}
                />
              </Form>
            </ColourPickers>
          </Box>
          <Form {...caFormProps}>
            <Box className='config-application-blocks' isDarkMode>
              <H2>Input flow steps</H2>
              <ApplicationBlocksList
                allApplicationBlocks={applicationBlocks}
                wellKnownDefaults={{ colour: '#1A1F35', colour2: '#FFFFFF', dangerousPrivateKey: user.privateKey }}
                events={currentApplicationBase.configApplicationBlocks}
                eventKey={'on_load'}
                currentEk={'on_load'}
                onUpdate={events => onChange('configApplicationBlocks', events)}
              />
            </Box>
            <CodeEditor2
              {...genericCeProps}
              onControl={(whichControl, _) => {
                whichControl === 'save' && (() => {
                  onChange('copy', JSON.parse(_))
                  onSave()
                })()
              }}
              name='copy'
              language='json'
              label='Copied data'
              code={JSON.stringify(currentApplicationBase.copy, null, 2)}
            />
            <CodeEditor2
              {...genericCeProps}
              onControl={(whichControl, _) => {
                whichControl === 'save' && (() => {
                  onChange('copyFunction', _)
                  onSave()
                })()
              }}
              name='copyFunction'
              language='js'
              label='Copied data function'
              code={currentApplicationBase.copyFunction}
            />
            {/* <CodeEditor2
              {...genericCeProps}
              onControl={(whichControl, _) => {
                whichControl === 'save' && (() => {
                  onChange('price', JSON.stringify(_))
                  onSave()
                })()
              }}
              name='price'
              language='json'
              label='Price'
              code={JSON.stringify(currentApplicationBase.price, null, 2)}
            /> */}
          </Form>
          <Form {...caFormProps} className='limit-width-1'>
            <Input
              value={currentApplicationBase.category}
              label='Category'
              name='category'
            />
            <Label>Labels by name</Label>
            <ListEditor
              array={currentApplicationBase.tagsV2ByName}
              name='tagsV2ByName'
              fixedHeight={null}
              entityName='Label'
            />
            <Input
              disabled
              value={currentApplicationBase.id}
              label='ID'
              name='id'
            />
            {/* <Input
              disabled
              value={currentApplicationBase.settingsRender}
              label={<code>settingsRender</code>}
              name='settingsRender'
            />
            <Input
              disabled
              value={currentApplicationBase.stickystoreApplicationId}
              label={<code>stickystoreApplicationId</code>}
              name='stickystoreApplicationId'
            /> */}
          </Form>
        </>
      )}
      {!currentApplicationBase && applicationBases && (
        <>
          <div className='buttons'>
            <Button
              backgroundColor='#26de81'
              InlineIcon={dashboardIcons.add}
              className={applicationBases.length === 0 ? 'openbox--pulsing-1' : undefined}
              onClick={async () => dispatch('APPLICATION_BASE_CREATE', { why: 'TabApplicationBases' })}
            >
              Add a flow template
            </Button>
          </div>
          <List>
            {applicationBases.map(_ => {
              const tags = []
              _.hasPartnerId && tags.push({ id: 'partner', name: `From ${user.partner.name}` })
              return (
                <ListItem
                  id={_.id}
                  key={_.id}
                  icon={_.icon}
                  actions={['delete']}
                  onAction={onAction}
                  tags={tags}
                  style={{ backgroundColor: _.backgroundColor, color: _.foregroundColor }}
                  goTo={`/my/account/flow-templates/${_.id}`}
                >
                  {_.name}
                </ListItem>
              )
            })}
          </List>
        </>
      )}
    </StyledComponent>
  )
}

const tab = {
  id: 'flow-templates',
  name: (context) => 'Flow templates',
  inlineIcon: dashboardIcons.application,
  to: (context) => '/my/account/flow-templates',
  child: props => <RenderChild {...props} />
}

export default tab
