/* eslint-disable quotes */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { icons, isPrivateKey, Button, CustomHelmet, Loading, List, ListItem } from '@openbox-app-shared'
import dashboardIcons from '../../../icons'
import _ from '../../../_'
import UserCircle from '../../../components/UserCircle'
import FilterButton from '../../../components/FilterButton'
import TagChooserUser from '../../../components/TagChooserUser'
import { dispatch, subscribe } from '../../../redux'

const StyledRightSide = styled.div`
  > * {
    float: left;
  }
  > * + * {
    margin-left: 0.25rem;
  }
`

function RenderChild ({ context }) {
  const { user } = context.props
  const [selectMode, setSelectMode] = useState()
  let [selectedIndices, setSelectedIndices] = useState([])
  const [pFilter, setPFilter] = useState('')
  const { things, federatedUsers } = context.state

  async function indent (delta) {
    dispatch('LOADING')
    for (let i = 0; i < selectedIndices.length; i++) {
      const si = selectedIndices[i]
      const thing = things[si]
      thing.indentation += delta
      if (thing.indentation < 0) {
        thing.indentation = 0
      }
      if (thing.indentation > 10) {
        thing.indentation = 10
      }
      await window.sticky.things.save(thing, undefined, ['indentation'])
    }
    setSelectedIndices([])
    dispatch('STOP_LOADING')
  }

  useEffect(
    () => {
      if (!user.federatedUserCan('things')) {
        window.sticky.applications.blocks.showError('You do not have permission to work on stickies.')
      }
    },
    []
  )
  useEffect(() => {
    const subscriptions = [
      subscribe(
        'CHOOSE_APPLICATIONS_GOOD',
        async ({ why, applications }) => {
          why === 'thing--change--application' && await (async () => {
            dispatch('LOADING')
            for (let i = 0; i < selectedIndices.length; i++) {
              const si = selectedIndices[i]
              const thing = things[si]
              thing.applicationId = applications[0].id
              await window.sticky.things.save(thing, undefined, ['applicationId'])
            }
            setSelectedIndices([])
            setSelectMode(undefined)
            dispatch('STOP_LOADING')
          })()
        }
      ),
      subscribe(
        'CHOOSE_TAGS_GOOD',
        async ({ why, tags }) => {
          why === 'thing--change--tags' && await (async () => {
            dispatch('LOADING')
            for (let i = 0; i < selectedIndices.length; i++) {
              const si = selectedIndices[i]
              const thing = things[si]
              thing.tags = window.sticky.newPatchableSet([...tags])
              await window.sticky.things.save(thing, undefined, ['tags'])
            }
            setSelectedIndices([])
            dispatch('STOP_LOADING')
          })()
        }
      ),
      subscribe(
        'GET_INPUT_GOOD',
        async ({ why, string }) => {
          why === 'things--move' && await (async () => {
            const privateKey = string.trim()
            if (!isPrivateKey(privateKey)) {
              dispatch('SHOW_MESSAGE', { message: <p>You didn't enter a private key!</p>, canBeBadded: '' })
              return
            }
            dispatch('LOADING')
            for (let i = 0; i < selectedIndices.length; i++) {
              const si = selectedIndices[i]
              const thing = things[si]
              await window.sticky.internals.trigger('move-thing', { privateKey, thingId: thing.id })
            }
            setSelectedIndices([])
            setSelectMode(undefined)
            dispatch('STOP_LOADING')
            await context.refreshThings()
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  return (
    <div className='tab'>
      <CustomHelmet title={`Stickies | ${user.name}`} />
      <div className='buttons'>
        {!selectMode && <>
          {(user.partner ? user.partner.recommendedThingId : true) && <Button
            id='NEW_THING_1'
            InlineIcon={dashboardIcons.add}
            backgroundColor='#f7b731'
            onClick={() => dispatch('GET_THINGS', { user })}
          >
            Get stickies
          </Button>}
          <Button
            InlineIcon={dashboardIcons.getStarted}
            isSecondary
            onClick={() => {
              window.sticky.applications.blocks.renderInlineEvents(
                [
                  {
                    'id': '6121bb17-a3b4-4df4-b64e-1149ce4d7140',
                    'config': {}
                  },
                  {
                    'id': '71d05208-3781-4c24-996e-c4c0d1c6b228',
                    'config': {
                      'what': '<strong>(1)</strong> Tap a sticky',
                      'font': '#1A1F35--center--100%--false',
                      'backgroundColour': '#FFFFFF',
                      'icon': ''
                    }
                  },
                  {
                    'id': '6121bb17-a3b4-4df4-b64e-1149ce4d7140',
                    'config': {}
                  },
                  {
                    'id': '6121bb17-a3b4-4df4-b64e-1149ce4d7140',
                    'config': {}
                  },
                  {
                    'id': '71d05208-3781-4c24-996e-c4c0d1c6b228',
                    'config': {
                      'what': '<strong>(2)</strong> Use your activation code:',
                      'font': '#1A1F35--center--100%--false',
                      'backgroundColour': '#FFFFFF',
                      'icon': ''
                    }
                  },
                  {
                    'id': '71d05208-3781-4c24-996e-c4c0d1c6b228',
                    'config': {
                      'what': user.activationCode,
                      'font': '#322CBE--center--150%--false',
                      'backgroundColour': '#FFFFFF',
                      'icon': ''
                    }
                  },
                  {
                    'id': '6121bb17-a3b4-4df4-b64e-1149ce4d7140',
                    'config': {}
                  },
                  {
                    'id': '6121bb17-a3b4-4df4-b64e-1149ce4d7140',
                    'config': {}
                  },
                  {
                    'id': '71d05208-3781-4c24-996e-c4c0d1c6b228',
                    'config': {
                      'what': '<strong>(3)</strong> Build the future!',
                      'font': '#1A1F35--center--100%--false',
                      'backgroundColour': '#FFFFFF',
                      'icon': ''
                    }
                  },
                  {
                    'id': '6121bb17-a3b4-4df4-b64e-1149ce4d7140',
                    'config': {}
                  },
                  {
                    'id': 'a21eddf2-aa86-4b6a-a2af-8ac279b246f7',
                    'config': {
                      'label': 'Close',
                      'action': 'application--inline-destroy~~||~~~~||~~false',
                      'colour': '#1A1F35',
                      'foregroundColour': '#FFFFFF',
                      'icon': 'cross',
                      'fullWidth': false,
                      'dropShadowAndRoundedCorners': true
                    }
                  },
                  {
                    'id': '6121bb17-a3b4-4df4-b64e-1149ce4d7140',
                    'config': {}
                  }
                ]
              )
            }}
          >
            Activate
          </Button>
          <Button
            onClick={async () => {
              const values = await window.sticky.applications.blocks.renderInlineEventsButton(
                [
                  {
                    "id": "a21eddf2-aa86-4b6a-a2af-8ac279b246f7",
                    "config": {
                      "label": "Add by name",
                      "action": "focusToNextStep~~||~~B~~||~~false",
                      "colour": "#1A1F35",
                      "foregroundColour": "#FFFFFF",
                      "icon": "plus",
                      "fullWidth": false,
                      "dropShadowAndRoundedCorners": true,
                      "letterSpacing": 1,
                      "backgroundImage": "",
                      "treatAsFormSubmitButton": false
                    }
                  },
                  {
                    "id": "a21eddf2-aa86-4b6a-a2af-8ac279b246f7",
                    "config": {
                      "label": "List of names",
                      "action": "focusToNextStep~~||~~C~~||~~false",
                      "colour": "#f0f1f3",
                      "foregroundColour": "#1A1F35",
                      "fullWidth": false,
                      "dropShadowAndRoundedCorners": true,
                      "letterSpacing": 1,
                      "backgroundImage": "",
                      "treatAsFormSubmitButton": false
                    }
                  },
                  {
                    "id": "81e88b40-b16a-4619-a659-2881ea326217",
                    "config": {
                      "colour": "#1A1F35",
                      "foregroundColour": "#FFFFFF",
                      "canGoBack": false,
                      "canGoForward": false,
                      "nextButtonText": "Next",
                      "nextButtonIcon": "arrowRight",
                      "name": "B"
                    }
                  },
                  {
                    "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
                    "config": {
                      "label": "Start name with",
                      "type": "Text",
                      "value": "Sticky"
                    }
                  },
                  {
                    "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
                    "config": {
                      "label": "How many?",
                      "type": "Number",
                      "value": "1"
                    }
                  },
                  {
                    "id": "a21eddf2-aa86-4b6a-a2af-8ac279b246f7",
                    "config": {
                      "label": "Add",
                      "action": "~~||~~~~||~~false",
                      "colour": "#1A1F35",
                      "foregroundColour": "#FFFFFF",
                      "icon": "check",
                      "fullWidth": false,
                      "dropShadowAndRoundedCorners": true,
                      "letterSpacing": 1,
                      "backgroundImage": "",
                      "treatAsFormSubmitButton": false
                    }
                  },
                  {
                    "id": "81e88b40-b16a-4619-a659-2881ea326217",
                    "config": {
                      "colour": "#1A1F35",
                      "foregroundColour": "#FFFFFF",
                      "canGoBack": false,
                      "canGoForward": false,
                      "nextButtonText": "Next",
                      "nextButtonIcon": "arrowRight",
                      "name": "C"
                    }
                  },
                  {
                    "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
                    "config": {
                      "label": "List",
                      "type": "Long text",
                      "value": "",
                      "disabled": false,
                      "required": false,
                      "stashUser": false,
                      "isHidden": false,
                      "priceMin": 0,
                      "priceMax": 50000000,
                      "stashPayment": false,
                      "stashThing": false
                    }
                  },
                  {
                    "id": "a21eddf2-aa86-4b6a-a2af-8ac279b246f7",
                    "config": {
                      "label": "Add",
                      "action": "~~||~~~~||~~false",
                      "colour": "#1A1F35",
                      "foregroundColour": "#FFFFFF",
                      "icon": "check",
                      "fullWidth": false,
                      "dropShadowAndRoundedCorners": true,
                      "letterSpacing": 1,
                      "backgroundImage": "",
                      "treatAsFormSubmitButton": false
                    }
                  }
                ],
                null
              )
              if (!values) {
                return
              }

              if (values['List'].length > 0) {
                await context.createThingArray(values['List'].split('\n').map(_ => _.trim()).filter(_ => _))
              } else {
                await context.createThingDynamic(values['Start name with'], values['How many?'])
              }
            }}
            InlineIcon={dashboardIcons.add}
            id='NEW_THING_2'
            isSecondary
          >
            Add virtual stickies
          </Button>
        </>}
        {Array.isArray(things) && things.length > 0 && !selectMode && <Button
          onClick={() => {
            setSelectMode('select')
          }}
          isSecondary
          title='Select'
          InlineIcon={dashboardIcons.select}
        >Select</Button>}
        {!selectMode && Array.isArray(things) && things.length > 0 && (
          <FilterButton
            user={user}
            onChange={setPFilter}
            turnedOnColor='#26de81'
            memoryKey='things'
          />
        )}
        {selectedIndices.length > 0 && <>
          <Button
            disabled={context.state.applications.length === 0}
            InlineIcon={dashboardIcons.application}
            onClick={() => dispatch('CHOOSE_APPLICATIONS', { why: 'thing--change--application' })}
          >
            Change flow
          </Button>
          {user.tags.get().length > 0 && <Button
            isSecondary
            disabled={context.state.applications.length === 0}
            InlineIcon={dashboardIcons.tags}
            onClick={() => dispatch(
              'CHOOSE_TAGS',
              {
                why: 'thing--change--tags',
                tags: (() => {
                  const r = []
                  context.state.things
                    .filter((_, i) => selectedIndices.includes(i))
                    .forEach(a => {
                      a.tags.forEach(t => r.push(t))
                    })
                  return window.sticky.newPatchableSet(r)
                })()
              }
            )}
          >
            Label
          </Button>}
          <Button
            InlineIcon={dashboardIcons.indentOut}
            title='Indent out'
            isSecondary
            onClick={() => indent(-1)}
          />
          <Button
            InlineIcon={dashboardIcons.indentIn}
            title='Indent in'
            isSecondary
            onClick={() => indent(1)}
          />
          <Button
            isSecondary
            InlineIcon={dashboardIcons.move}
            onClick={() => {
              dispatch('GET_INPUT', { why: 'things--move', hint: 'Enter the private key of the destination dashboard:', string: '', doValidate: false })
            }}
          >
            Move dashboard
          </Button>
        </>}
        {Array.isArray(things) && things.length > 0 && selectMode && things.length !== selectedIndices.length && <Button
          onClick={() => {
            const newIndices = []
            things.forEach((thing, aIndex) => {
              if (thing.doesMatchFilter(pFilter)) {
                newIndices.push(aIndex)
              }
            })
            setSelectedIndices(newIndices)
          }}
          isSecondary
          InlineIcon={dashboardIcons.selectAll}
        >
          Select all
        </Button>}
        {Array.isArray(things) && things.length > 0 && selectMode && <Button
          onClick={() => {
            setSelectMode(undefined)
            setSelectedIndices([])
          }}
          isSecondary
        >
          Cancel
        </Button>}
      </div>
      {!Array.isArray(things) && <Loading />}
      {Array.isArray(things) && (
        <>
          <div>
            <List
              emptyText={(pFilter && pFilter.hasSomethingInIt) ? 'No stickies match your filter.' : null}
              className='main-dish-2 things'
              draggable
              onDrag={async (from, to) => {
                dispatch('LOADING')
                const now = Math.floor(window.sticky.dateTime.getNowUtcLegacy() / 1000)
                const containers = [...things]
                window.sticky.swapArrayElements(containers, from, to)
                for (let i = 0; i < containers.length; i++) {
                  const si = containers[i]
                  si.createdAt = now + i
                  await window.sticky.things.save(si, undefined, ['createdAt'])
                }
                await context.refreshThings()
                dispatch('STOP_LOADING')
              }}
            >
              {things
                .map((thing, aIndex) => {
                  if (!thing.doesMatchFilter(pFilter)) {
                    return null
                  }
                  const foundApplication = context.state.applications && context.state.applications.find($ => $.id === thing.applicationId)
                  const tags = []
                  foundApplication && tags.push({ name: foundApplication.name, color: '#F0F1F3', foregroundColor: '#1A1F35', icon: foundApplication.baseIcon })
                  thing.session && tags.push({ name: `Locked to ${thing.session.identifierUniquePure}`, color: '#ff3838', foregroundColor: '#FFF', InlineIcon: dashboardIcons.teamMember })
                  const actions = [
                    'rename',
                    'delete'
                  ]
                  const rightSideChildren = (thing.tags.hasAny || thing.federatedUserIds.length > 0) && <StyledRightSide>
                    <TagChooserUser can={false} user={user} tags={thing.tags} canManage={false} showAll={false} />
                    {thing.federatedUserIds
                      .map(fu => {
                        const foundFu = federatedUsers.find(_ => _.id === fu)
                        return foundFu ? <UserCircle name={foundFu.name} color={foundFu.color} fixedWidthHeight={2} key={fu} photoUrl={foundFu.photoUrl} /> : undefined
                      })
                      .filter(_ => _)
                    }
                  </StyledRightSide>
                  return (
                    <ListItem
                      doBoxShadow
                      actions={actions}
                      onAction={context.onThingAction}
                      key={thing.id}
                      id={thing.id}
                      icon={selectMode ? (selectedIndices.includes(aIndex) ? icons.generic.check : icons.generic.uncheck) : thing.designUrl}
                      goTo={!selectMode && `/me/stickies/${thing.id}`}
                      indentationOuter={thing.indentation * 48}
                      onChoose={(id) => {
                        if (!selectMode) {
                          return
                        }
                        if (selectedIndices.includes(aIndex)) {
                          selectedIndices = selectedIndices.filter(si => si !== aIndex)
                        } else {
                          selectedIndices.push(aIndex)
                        }
                        setSelectedIndices([...selectedIndices])
                      }}
                      tags={tags}
                      rightSideChildren={rightSideChildren}
                    >
                      {thing.name}
                    </ListItem>
                  )
                })
                .filter(_ => _)
              }
            </List>
          </div>
        </>
      )}
    </div>
  )
}

const tab = {
  id: 'stickies',
  name: (context) => _('THINGS'),
  inlineIcon: dashboardIcons.thing,
  to: (context) => context.props.user.federatedUserCan('things') && '/me/stickies',
  child: function Child (props) {
    return <RenderChild {...props} />
  }
}

export default tab
