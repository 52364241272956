import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CustomHelmet, Loading, Button, Price } from '@openbox-app-shared'

import CoolCart from '../../../components/CoolCart'
import ButtonGrid from '../../../components/ButtonGrid'
import _ from '../../../_'

import EposCart from '../opsManager/components/Cart'

import { log } from '../../../log'
import { dispatch, subscribe } from '../../../redux'
import classnames from 'classnames'
import dashboardIcons from '../../../icons'
import ThingButton from '../../../components/ThingButton'
import { TagChooser } from '../../../components/TagChooser'
import H1 from '../../../components/H1'

const DO_REFRESH = true
const REFRESH_INTERVAL = 5 * 1000

function getAllPaymentsTags (paymentsAll, allTags) {
  return Array.from(new Set(paymentsAll.map(_ => _.tags.toArray()).flat()))
    .map(_ => allTags.find(__ => __.id === _))
    .filter(_ => _)
}

function KeyCircle ({ color, children }) {
  return (
    <div>
      <div className='circle' style={{ backgroundColor: color }} />
      <span style={{ color: 'white' }}>{children}</span>
    </div>
  )
}
KeyCircle.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
}

const StyledRoute = styled.div`
  min-height: 100vh;
  .content {
    .component--button-grid {
      padding-top: 1rem;
    }
    .component--button {
      border-width: 4px;
    }
    .component--button.stage-0 {
      strong {
        opacity: 0.5;
      }
    }
    .component--button.stage-1 {
      border-color: #fd9644;
      background-color: #fd964416;
    }
    .component--button.stage-2 {
      border-color: #b33939;
      background-color: #b3393916;
    }
  }
  .to-buttons {
    display: grid;
    grid-gap: 1rem;
  }
  .to-buttons.grid-2 {
    grid-template-columns: 1fr 1fr;
  }
  .to-buttons.grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .to-buttons + .to-buttons {
    margin-top: 1rem;
  }
  > aside {
    width: 100%;
    padding: 1rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0.15);
    overflow: scroll;
    .component--box + .component--box, .settings + *, .component--cool-cart + .component--cool-cart {
      margin-top: 1rem;
    }
    .settings {
      width: calc(100% - 2rem);
    }
    > .component--h1 {
      margin-bottom: 1rem;
      text-align: center;
      color: white;
    }
    > .component--h1.comp {
      color: #f7b731;
    }
  }
  @media only screen and (min-width: 960px) {
    > aside {
      position: fixed;
      top: 0;
      right: 0;
      width: 400px;
      height: 100vh;
    }
    > .content {
      margin-right: 400px;
      max-width: 1072px;
    }
  }
  * + .component--h1 {
    margin-top: 1rem;
  }
  // .key {
  //   width: 100%;
  //   > * {
  //     display: inline-block;
  //     vertical-align: top;
  //     height: 1.5rem;
  //     line-height: 1.5rem;
  //     margin: 0 1rem 1rem 0;
  //     .circle {
  //       float: left;
  //       width: 1.5rem;
  //       height: 1.5rem;
  //       border-radius: 5000px;
  //     }
  //     span {
  //       margin-left: 0.5rem;
  //     }
  //   }
  // }
`

const generateKey = (i = '???', productId, questions) => {
  return `${i}---${productId}---${(questions || []).map(q => `${q.question}=${q.answer}`).join('--')}`
}

export default class Route extends Component {
  constructor() {
    super()
    window.sticky.pay.setQuery('thingsOverview')
    this.state = {}
    this.isWorking = false
    this.doWork = this.doWork.bind(this)
    this.onVoidPayment = this.onVoidPayment.bind(this)
  }

  async doWork (extraState = {}) {
    const { user, userPreferences } = this.props
    const { thingsOverviewView } = userPreferences
    console.warn('[DebugLater] [Fab]', { thingsOverviewView })
    if (this.isWorking) {
      this.setState(extraState)
      return
    }
    const { currentFd: oldCurrentFd } = this.state
    this.isWorking = true

    const payments = [...(await window.sticky.pay.getAll(true))]
      .reverse()
      .filter(p => p.newStatusDoneDontSet === true || p.newStatusDone === false || p.isUnpaid || (p.gateway === 'GATEWAY_LATER' && p.isUnpaid))
      .map(payment => {
        payment.rawCart = payment.rawCart
          .map(ci => {
            ci.key = generateKey(undefined, ci.productId, ci.questions)
            return ci
          })
        return payment
      })

    const federatedUsers = await window.sticky.users.federated.getAll(true)
    const things = await window.sticky.things.getAll(true)
    const tags = user.tags.get()

    let finalData = []
    things
      .forEach(thing => {
        const paymentsAll = payments.filter(p => p.thingId === thing.id)
        const paymentsUnpaid = paymentsAll.filter(p => p.isUnpaid || (p.gateway === 'GATEWAY_LATER' && p.isUnpaid))
        const allPaymentsTags = getAllPaymentsTags(paymentsAll, tags)
        const howManyPaymentsNotDone = paymentsAll
          .filter(p => p.newStatusDone === false)
          .length
        const howManyPaymentsUnpaid = paymentsUnpaid.length
        const style = allPaymentsTags.length > 0 ? { borderColor: allPaymentsTags[allPaymentsTags.length - 1].color } : undefined
        finalData.push({
          thing,
          paymentsAll,
          paymentsUnpaid,
          allPaymentsTags,
          howManyPaymentsNotDone,
          howManyPaymentsUnpaid,
          stage: (() => {
            if (allPaymentsTags.length > 0) {
              return 100
            }
            if (howManyPaymentsNotDone > 0) {
              return 1
            }
            if (howManyPaymentsUnpaid > 0) {
              return 2
            }
            return 0
          })(),
          style
        })
      })
    finalData = [
      ...finalData.filter(_ => _.stage),
      ...finalData.filter(_ => !_.stage)
    ]

    const potentialNewFd = oldCurrentFd && finalData.find(_ => _.thing.id === oldCurrentFd.thing.id)
    const state = {
      federatedUsers,
      things,
      finalData,
      currentFd: (potentialNewFd && potentialNewFd.stage > 0) ? potentialNewFd : undefined,
      ...extraState
    }
    return new Promise(resolve => {
      this.setState(
        state,
        () => {
          this.isWorking = false
          resolve()
        }
      )
    })
  }

  async componentDidMount() {
    document.querySelector('main').style.maxWidth = 'none'
    document.querySelector('.component--navbar').style.display = 'none'

    await this.doWork()

    if (DO_REFRESH) {
      this.refreshTimer = setInterval(
        this.doWork,
        REFRESH_INTERVAL
      )
    }

    const wrapUpHighLevel = (finalData, newCurrentFd) => {
      this.setState({
        currentFd: newCurrentFd,
        finalData: finalData.map(fd => {
          if (fd.thing.id === newCurrentFd.thing.id) {
            return newCurrentFd
          } else {
            return fd
          }
        })
      })
    }

    this.subscriptions = [
      subscribe(
        'CHOOSE_THING_GOOD',
        async ({ thing, why, miscEntity: whichPayment }) => {
          if (!thing) {
            return
          }
          why === 'thingsOverview' && await (async () => {
            dispatch('LOADING')
            try {
              whichPayment.thingId = thing.id
              whichPayment.thing = thing
              await window.sticky.pay.save(whichPayment, false, ['thingId'])
              await this.doWork()
            } catch ({ message }) {
              window.sticky.applications.blocks.showError(message, true)
            } finally {
              dispatch('STOP_LOADING')
            }
          })()
        }
      ),
      subscribe(
        'CHOOSE_TAGS_GOOD',
        async ({ why, tags }) => {
          const { currentFd, finalData } = this.state
          const wrapUpLowLevel = () => {
            const allPaymentsTags = getAllPaymentsTags(currentFd.paymentsAll, tags.toArray().map(_ => ({ id: _ })))
            const style = allPaymentsTags.length > 0 ? { borderColor: allPaymentsTags[allPaymentsTags.length - 1].color } : undefined
            let newCurrentFd = {
              ...currentFd,
              allPaymentsTags,
              style
            }
            wrapUpHighLevel(finalData, newCurrentFd)
          }
          typeof why === 'string' && why.startsWith('thingsOverview--payment--') && await (async () => {
            dispatch('LOADING')
            try {
              const whichPaymentId = why.substring('thingsOverview--payment--'.length)
              const whichPayment = currentFd.paymentsAll.find(p => p.id === whichPaymentId)
              whichPayment.tags.patch(tags.toArray())
              await window.sticky.pay.save(whichPayment, false, ['tags'])
              wrapUpLowLevel()
            } catch ({ message }) {
              window.sticky.applications.blocks.showError(message, true)
            } finally {
              dispatch('STOP_LOADING')
            }
          })()
          why === 'thingsOverview' && await (async () => {
            dispatch('LOADING')
            try {
              await window.sticky.forEach(
                currentFd.paymentsAll,
                async _ => {
                  _.tags.patch(tags.toArray())
                  await window.sticky.pay.save(_, false, ['tags'])
                }
              )
              wrapUpLowLevel()
            } catch ({ message }) {
              window.sticky.applications.blocks.showError(message, true)
            } finally {
              dispatch('STOP_LOADING')
            }
          })()
        }
      )
    ]
  }

  async componentWillUnmount() {
    log('[Route-view-thingsOverview] [componentWillUnmount]')
    clearInterval(this.refreshTimer)
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  doneSomething(state) {
    const finalState = {
      ...this.state,
      state
    }
    log('[Route-view-thingsOverview] [doneSomething]', { finalState })
  }

  getWillPay (currentFd) {
    log('[Route-view-thingsOverview] [getWillPay] 1 currentFd', currentFd)
    const { paymentsUnpaid: payments } = currentFd
    let mergedCart = []
    payments.forEach(p => {
      log('[Route-view-thingsOverview] [getWillPay] 2 p', p)
      mergedCart = [
        ...mergedCart,
        ...p.rawCart
      ]
      mergedCart = mergedCart.map((mci, i) => {
        return {
          ...mci,
          key: i
        }
      })
    })
    const toReturn = {
      mergedCart
    }
    const currency = payments.length > 0 ? payments[0].currency : undefined
    if (typeof currency === 'string') {
      toReturn.currency = currency
    }
    log('[Route-view-thingsOverview] [getWillPay] 3 toReturn', toReturn)
    return toReturn
  }

  async onVoidPayment () {
    const { currentFd } = this.state
    dispatch('LOADING')
    for (let i = 0; i < currentFd.paymentsAll.length; i++) {
      await window.sticky.pay.remove(currentFd.paymentsAll[i], true)
    }
    Object.keys(currentFd.thing.customData.get())
      .filter(_ => _.endsWith(' → paid manually') && _.length === '7abd2063-e9a0-4464-b4cc-5c5385df0db2 → paid manually'.length)
      .forEach(k => currentFd.thing.customData.writeTo(k, undefined))
    currentFd.thing.customData.writeTo('Total paid manually', undefined)
    currentFd.thing.customData.writeTo('Comp', undefined)
    currentFd.thing.customData.writeTo('Comp name', undefined)
    await window.sticky.things.save(currentFd.thing, undefined, ['customData'])
    await this.doWork({
      currentFd: undefined
    })
    dispatch('STOP_LOADING')
  }

  render() {
    const { user } = this.props
    const {
      finalData,
      federatedUsers,
      things,
      currentFd
    } = this.state

    const canRender = [finalData, things].every(_ => _)

    const willPayAll = currentFd ? this.getWillPay(currentFd) : undefined
    const cpCart = willPayAll ? willPayAll.mergedCart : []

    const { paymentsAll = [] } = currentFd || {}
    log('[DebugLater] [Route-view-thingsOverview] [render]', { finalData, currentFd, willPayAll, canRender, cpCart })

    let totalPaidManually = currentFd && currentFd.thing ? currentFd.thing.customData.readFrom('Total paid manually') : undefined
    totalPaidManually = totalPaidManually || 0

    let totalComp = currentFd && currentFd.thing ? currentFd.thing.customData.readFrom('Comp') : undefined
    totalComp = totalComp || 0

    let compdName = currentFd && currentFd.thing ? currentFd.thing.customData.readFrom('Comp name') : 'Custom'
    let currentFdUnpaid = currentFd && window.sticky.sum(currentFd.paymentsUnpaid.map(_ => _.total))
    currentFdUnpaid = (currentFdUnpaid || 0) - totalPaidManually
    const currentFdAllTags = currentFd ? [
      currentFd.howManyPaymentsNotDone > 0 && {
        id: 'notDone',
        name: <>{currentFd.howManyPaymentsNotDone} incomplete</>,
        color: '#fd9644'
      },
      willPayAll && willPayAll.mergedCart.length > 0 && totalPaidManually > 0 && {
        id: 'paid',
        name: <><Price currency={willPayAll.currency} price={totalPaidManually} /> paid</>,
        color: '#40cf7a'
      },
      currentFdUnpaid > 0 && {
        id: 'notPaid',
        name: <><Price price={currentFdUnpaid} currency={willPayAll.currency} /> unpaid</>,
        color: '#b33939'
      }
    ]
      .filter(_ => _) : []

    return (
      <StyledRoute
        className='level--styled-route'
      >
        <CustomHelmet
          title='Pay later'
        />

        {!canRender && <Loading />}
        {canRender && (
          <>
            <aside>
              <H1>
                {currentFd && currentFd.thing.name}
              </H1>
              {totalComp > 0 && <H1 className='comp'>
                Comp: {totalComp}% ({compdName})
              </H1>}
              <>
                {currentFd && <div className={`to-buttons grid-${user.federatedUserCan('payment-delete') ? 2 : 1}`}>
                  <Button
                    InlineIcon={dashboardIcons.check}
                    bigIcon
                    backgroundColor='#40cf7a'
                    disabled={window.parent === window}
                    onClick={() => {
                      const thingId = currentFd.thing.id
                      const paymentIds = currentFd.paymentsUnpaid.map(_ => _.id)
                      const paymentsTotal = currentFdUnpaid
                      const paymentsCurrency = currentFd.paymentsUnpaid[0].currency
                      const paymentsCart = cpCart.map(_ => ({ ..._, product: undefined }))
                      const finalMessage = { event: 'HOW_TO_PAY', thingId, paymentIds, paymentsTotal, paymentsCurrency, paymentsCart }
                      window.parent.postMessage(finalMessage)
                    }}
                  >
                    Pay
                  </Button>
                  {user.federatedUserCan('payment-delete') && <Button
                    InlineIcon={dashboardIcons.void}
                    bigIcon
                    backgroundColor='#ff3938'
                    disabled={!user.federatedUserCan('payment-delete')}
                    onClick={this.onVoidPayment}
                  >
                    Void
                  </Button>}
                  {/* {tags.length > 0 && <Button
                    InlineIcon={dashboardIcons.tags}
                    bigIcon
                    backgroundColor='#6C7A89'
                    onClick={() => dispatch(
                      'CHOOSE_TAGS',
                      {
                        why: 'thingsOverview',
                        tags: (() => {
                          const r = currentFd.allPaymentsTags.map(_ => _.id)
                          return window.sticky.newPatchableSet(r)
                        })()
                      }
                    )}
                  >
                    {currentFd.allPaymentsTags.length === 0 ? 'Label' : `${currentFd.allPaymentsTags.length} label${currentFd.allPaymentsTags.length !== 1 ? 's' : ''}`}
                  </Button>} */}
                  {/* <Button
                    bigIcon
                    className='pop-up-thing'
                    InlineIcon={dashboardIcons.thing}
                    onClick={() => {
                      window.sticky.popUpIframe({
                        src: window.sticky.things.goReal(currentFd.thing.id),
                        maxWidth: '376px',
                        maxHeight: '680px'
                      })
                    }}
                  >
                    Open
                  </Button> */}
                  {/* <Button
                    inlineIconString={window.sticky.internals.icons.get('hand')}
                    bigIcon
                    backgroundColor='#f7b731'
                    disabled={!user.federatedUserCan('payment-discount')}
                    onClick={() => {
                      const { currentFd } = this.state
                      const r = await window.sticky.applications.blocks.renderInlineEventsButton(
                        [
                          {
                            'id': '5dc584b0-b0ef-4e4f-a8cc-476e3c8fc36b',
                            'config': {
                              'label': '',
                              'list': [
                                '25%: Student - Byron Club',
                                '20%: Student - Student Beans',
                                '20%: Student - Totum',

                                '20%: Byron Services',
                                '25%: Blue Light',

                                '25%: John Lewis',
                                '15%: Vectis',
                                '20%: Friends with Benefits',

                                '20%: Byron Club',
                                '50%: Staff',

                                '10%: Manager 10%',
                                '20%: Manager 20%',
                                '25%: Manager 25%',
                                '30%: Manager 30%',
                                '50%: Manager 50%',
                                '100%: Manager 100%',
                              ],
                              'columns': 1,
                              'buttonFontSize': 20,
                              'buttonPadding': '8px'
                            }
                          },
                          {
                            'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                            'config': {
                              'label': 'Custom %',
                              'type': 'Number',
                              'value': '',
                              'priceMin': 0,
                              'priceMax': 50000000
                            }
                          }
                        ],
                        'Comp',
                        {
                          buttonIcon: 'hand'
                        }
                      )
                      if (!r) {
                        return
                      }

                      const unpaidTotal = window.sticky.sum(currentFd.paymentsUnpaid.map(_ => _.total))
                      const maybePaidSoFar = currentFd.thing.customData.readFrom('Total paid manually')
                      const paidSoFar = typeof maybePaidSoFar === 'number' ? maybePaidSoFar : 0
                      const leftToPay = unpaidTotal - paidSoFar
                      let [percentageString, compdName] = r[''].split(': ')
                      compdName = r['Custom %'] ? 'Custom' : compdName

                      const customPercentage = r['Custom %'] ? parseInt(r['Custom %'], 10) : undefined
                      const toCompThisTime = customPercentage ? customPercentage : parseInt(percentageString.substring(0, percentageString.length - 1), 10)
                      currentFd.thing.customData.writeTo('Comp', toCompThisTime)
                      currentFd.thing.customData.writeTo('Comp name', compdName)

                      log('[Route-view-thingsOverview] [Comp]', { unpaidTotal, maybePaidSoFar, paidSoFar, leftToPay, percentageString, compdName, toCompThisTime })
                      dispatch('LOADING')
                      const { user } = this.props
                      await window.sticky.session.createEvent(
                        {
                          type: 'COMP_PAYMENT',
                          currency: user.currency,
                          totalBefore: unpaidTotal,
                          totalAfter: Math.floor(unpaidTotal * (1 - (toCompThisTime / 100))),
                          compdAmount: `${toCompThisTime}%`,
                          compdName
                        },
                        'doesnt-matter',
                        currentFd.thing.applicationId,
                        currentFd.thing.id,
                        (e) => {
                          if (e) {
                            dispatch('STOP_LOADING')
                            window.sticky.applications.blocks.showError(e.message, true)
                          } else {
                            window.sticky.things.save(currentFd.thing, undefined, ['customData'])
                              .catch(() => dispatch('STOP_LOADING'))
                              .then(() => {
                                dispatch('STOP_LOADING')
                                this.forceUpdate()
                              })
                          }
                        }
                      )
                    }}
                  >
                    Comp
                  </Button> */}
                </div>}
                {cpCart.length > 0 && (
                  <>
                    <H1>Overview</H1>
                    <CoolCart>
                      {/* <Button
                        onClick={() => {
                          this.setState({ cartCollapsed: !cartCollapsed })
                        }}
                        className={`expand-collapse ${cartCollapsed ? 'collapsed' : 'not-collapsed'}`}
                      >&#8963;</Button> */}
                      {/* {cartProductCount} product{cartProductCount !== 1 ? 's' : ''} */ }
                      <EposCart
                        cart={cpCart}
                        canVoid={false}
                      />
                    </CoolCart>
                  </>
                )}
                {currentFd && <>
                  <hr />
                  <TagChooser
                    all={currentFdAllTags}
                    set={window.sticky.newPatchableSet(currentFdAllTags.map(_ => _.id))}
                    can={false}
                  />
                </>}
                {/* {currentFd && <H1>{paymentsAll.length} payment{paymentsAll.length !== 1 ? 's' : ''}</H1>} */}
                {paymentsAll.map(pa => {
                  const isNotDone = pa.newStatusDone === false
                  const isNotPaid = pa.isUnpaid
                  const allTags = [
                    isNotDone && {
                      id: 'notDone',
                      name: 'Incomplete',
                      color: '#fd9644'
                    },
                    !isNotPaid && {
                      id: 'paid',
                      name: 'Paid',
                      color: '#40cf7a'
                    },
                    isNotPaid && {
                      id: 'notPaid',
                      name: 'Unpaid',
                      color: '#b33939'
                    },
                    ...user.tags.get().filter(_ => pa.tags.has(_.id))
                  ]
                    .filter(_ => _)
                  const theseTags = window.sticky.newPatchableSet(allTags.map(_ => _.id))
                  return (
                    <CoolCart
                      key={pa.id}
                      headline={pa.getDescriptionThingsOverview(federatedUsers.find(_ => _.id === pa.lastFederatedUserId))}
                      buttons={
                        <>
                          {user.tags.get().length > 0 && <Button
                            InlineIcon={dashboardIcons.tags}
                            isSecondary
                            isThin
                            onClick={() => dispatch(
                              'CHOOSE_TAGS',
                              {
                                why: `thingsOverview--payment--${pa.id}`,
                                tags: pa.tags
                              }
                            )}
                          >
                            Labels
                          </Button>}
                          <Button
                            onClick={() => dispatch('CHOOSE_THING', { why: 'thingsOverview', miscEntity: pa, hint: 'Which sticky should this be assigned to?' })}
                            InlineIcon={dashboardIcons.thing}
                            isSecondary
                            isThin
                          >
                            Move
                          </Button>
                        </>
                      }
                    >
                      <TagChooser
                        all={allTags}
                        set={theseTags}
                        can={false}
                      />
                      <EposCart
                        cart={pa.rawCart}
                        canVoid={user.federatedUserCan('payment-delete') && '#ff3838'}
                        onVoid={cartItem => {
                          // dispatch('CHOOSE_FEDERATED_USER', { why: 'thingsOverview--discount-ci', entity: { payment: pa, cartItem }, canBeClosed: true })
                          ;(async () => {
                            let cart = pa.cart.get()
                            dispatch('LOADING')

                            cartItem.quantity -= 1
                            if (cartItem.quantity <= 0) {
                              log('[Route-view-thingsOverview] [Comp] inside if')
                              cart = cart.filter(ci => {
                                const keepThisOne = ci.key !== cartItem.key
                                log('[Route-view-thingsOverview] [Comp] each ci key', { v1: ci.key, v2: cartItem.key, keepThisOne })
                                return keepThisOne
                              })
                            }
                            pa.cart.set(cart)

                            const patchable = {
                              currency: await window.sticky.Stickypay.getCurrency(cart),
                              total: await window.sticky.Stickypay.getTotal(cart),
                              cart
                            }
                            pa.patch(patchable)
                            log('[Route-view-thingsOverview] [Comp]', { cart, patchable })

                            await window.sticky.pay.save(pa, false, ['cart', 'total', 'currency'])

                            await window.sticky.session.createEvent(
                              {
                                type: 'VOID_PRODUCT',
                                paId: pa.id,
                                productId: cartItem.product.id
                              },
                              'doesnt-matter',
                              currentFd.thing.applicationId,
                              currentFd.thing.id,
                              (e) => {
                                if (e) {
                                  window.sticky.applications.blocks.showError(e.message, true)
                                }
                                this.doWork()
                                dispatch('STOP_LOADING')
                              }
                            )

                            cart.length === 0 && this.onVoidPayment()
                          })()
                        }}
                      />
                    </CoolCart>
                  )
                })}
              </>
            </aside>
            <div className='content'>
              <ButtonGrid>
                {finalData.map(p => {
                  return (
                    <ThingButton
                      key={`p--${p.thing.id}`}
                      className={classnames({ selected: (p && currentFd) && (p.thing.id === currentFd.thing.id) }, `stage-${p.stage}`)}
                      style={p.style}
                      thing={p.thing}
                      onClick={() => {
                        p.stage > 0 && this.setState({
                          currentFd: p
                        })
                      }}
                    />
                  )
                })}
              </ButtonGrid>
            </div>
          </>
        )}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  userPreferences: PropTypes.object
}
