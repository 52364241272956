import React, { PureComponent, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  icons,
  Button,
  Input,
  TimePicker,
  Dropdown,
  DayPicker,
  Label,
} from '@openbox-app-shared'
import Box from './Box'
import KeyValuePairs from './KeyValuePairs'
import H2 from './H2'
import TabBar from './TabBar'
import AbstractAbstractPayments from './AbstractAbstractPayments'
import dashboardIcons from '../icons'
import { dispatch, subscribe } from '../redux'
import ApplicationChooser from './ApplicationChooser'
import SaveButton from './SaveButton'

const StyledComponent = styled.div`
  .tab-container,
  .component--button + .component--abstracted-payments {
    margin-top: 0.5rem;
  }
  .buttons {
    > * {
      vertical-align: top;
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
  .component--table {
    width: 100%;
  }
  p.code {
    margin-top: 1rem;
    color: #607481;
    font-size: 80%;
  }
  label + .component--list {
    margin-top: 0.5rem;
  }
  .component--button + .scheduled-notifications {
    margin-top: 1rem;
  }
  .scheduled-notifications > .component--box {
    padding-bottom: 0;
    > * + * {
      margin-top: 1rem;
    }
  }
  .scheduled-notifications > * + * {
    margin-top: 1rem;
  }
  @media only screen and (min-width: 960px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }
  }
`

const writeCus = (session, name, value, onUpdate) => {
  session.crossUserSector.writeTo(name, value)
  onUpdate()
  window.sticky.session.updateSector(
    'cross-user',
    { [name]: value },
    session.id,
  )
}
const writeUser = async (session, name, value, onUpdate) => {
  const oldValue = session.userSector.readFrom(name)
  session.userSector.writeTo(name, value)
  dispatch('LOADING')
  try {
    await window.sticky.session.updateSector(
      'user',
      { [name]: value },
      session.id,
    )
  } catch ({ message }) {
    window.sticky.applications.blocks.showError(message, true)
    session.userSector.writeTo(name, oldValue)
  } finally {
    onUpdate()
    dispatch('STOP_LOADING')
  }
}
const writeUserPayload = async (session, payload, onUpdate) => {
  dispatch('LOADING')
  try {
    await window.sticky.session.updateSector('user', payload, session.id)
  } catch ({ message }) {
    window.sticky.applications.blocks.showError(message, true)
  } finally {
    onUpdate()
    dispatch('STOP_LOADING')
  }
}

function convertLocalMinutesToUTCMinutes(localMinutes) {
  const today = new Date()
  const localHours = Math.floor(localMinutes / 60)
  const localMinutesPastHour = localMinutes % 60

  // Set the local time using the minutes passed since midnight
  today.setHours(localHours, localMinutesPastHour, 0, 0)

  // Calculate UTC hours and minutes
  const utcHours = today.getUTCHours()
  const utcMinutes = today.getUTCMinutes()

  // Return total minutes in UTC since midnight
  return utcHours * 60 + utcMinutes
}

function convertUTCMinutesToLocalMinutes(utcMinutes) {
  const today = new Date()
  const utcHours = Math.floor(utcMinutes / 60)
  const utcMinutesPastHour = utcMinutes % 60

  // Set the UTC time to get the equivalent local time
  today.setUTCHours(utcHours, utcMinutesPastHour, 0, 0)

  // Calculate local hours and minutes
  const localHours = today.getHours()
  const localMinutes = today.getMinutes()

  // Return total minutes in local time since midnight
  return localHours * 60 + localMinutes
}

function formatSchedules(schedules) {
  return schedules.map((schedule) => ({
    ...schedule,
    time: convertLocalMinutesToUTCMinutes(schedule.time),
  }))
}

function showImageUrl(url) {
  window.sticky.applications.blocks.renderInlineEvents([
    {
      id: '9545852c-d64a-457a-8ca0-2b2d1173de9b',
      config: {
        url,
        corners: 'Square',
        dropShadow: false,
        specialEffect: 'None',
        lockAspectRatio: true,
      },
    },
    {
      id: 'a21eddf2-aa86-4b6a-a2af-8ac279b246f7',
      config: {
        label: 'Close',
        action: 'application--inline-destroy~~||~~~~||~~false',
        colour: '#1A1F35',
        foregroundColour: '#FFFFFF',
        icon: 'cross',
        fullWidth: false,
        dropShadowAndRoundedCorners: true,
      },
    },
  ])
}

export default class Session extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      lastQuantumBalance: 2500,
      lastQuantumDiscount: 10,
      notifications: [],
      hasMadeScheduledPushNotificationUpdate: false,
    }
    this.onReallyUpdated = this.onReallyUpdated.bind(this)
  }

  onReallyUpdated() {
    const { onUpdate } = this.props
    onUpdate && onUpdate()
    this.forceUpdate()
  }

  componentDidMount() {
    this.subscriptions = [
      subscribe('CREATE_PAYMENT_GOOD', () => {
        this.paymentsReRender && this.paymentsReRender()
      }),
      subscribe('GET_INPUT_GOOD', async ({ why, string: value }) => {
        why === 'Session-id' &&
          (() => {
            const { session } = this.props
            const finalValue =
              typeof value === 'string' ? value.trim().toUpperCase() : value
            writeUser(session, 'ID', finalValue, this.onReallyUpdated)
          })()
        why.startsWith('executable-codes-create--') &&
          (async () => {
            dispatch('LOADING')
            const type = why.substring('executable-codes-create--'.length)
            const { session } = this.props
            type === 'StickyBalance' &&
              (() => {
                session.userSector.writeTo('Sticky Balance', value)
                session.userSector.writeTo(
                  'Sticky Last Balance Code',
                  'STICKY_B',
                )
                writeUserPayload(
                  session,
                  {
                    'Sticky Balance': value,
                    'Sticky Last Balance Code': 'STICKY_B',
                  },
                  this.onReallyUpdated,
                )
                this.setState({ lastQuantumBalance: value })
              })()
            type === 'StickyDiscount' &&
              (() => {
                session.userSector.writeTo('Sticky Discount', value)
                session.userSector.writeTo(
                  'Sticky Last Discount Code',
                  'STICKY_D',
                )
                writeUserPayload(
                  session,
                  {
                    'Sticky Discount': value,
                    'Sticky Last Discount Code': 'STICKY_D',
                  },
                  this.onReallyUpdated,
                )
                this.setState({ lastQuantumDiscount: value })
              })()
            dispatch('STOP_LOADING')
          })()
      }),
      subscribe('CHOOSE_APPLICATIONS_GOOD', ({ why, applications }) => {
        why === 'SESSION' &&
          (() => {
            const url = window.sticky.session.getImage({
              toApplicationId: applications[0].id,
            })
            showImageUrl(url)
          })()
        why === 'notify' &&
          (async () => {
            const {
              session: { id: sessionId },
            } = this.props
            const { Message: alert } =
              await window.sticky.applications.blocks.getInput(
                undefined,
                'Message',
                '',
                'Text',
                'Send',
              )
            try {
              dispatch('LOADING')
              const response = await window.sticky.notify.notify(sessionId, {
                alert,
                applicationId: applications[0].id,
              })
              await sticky.applications.blocks.showMessage(
                response,
                undefined,
                true,
                true,
              )
            } catch ({ message }) {
              await window.sticky.applications.blocks.showError(message, true)
            } finally {
              dispatch('STOP_LOADING')
            }
          })()
      }),
      subscribe('CHOOSE_THING_GOOD', ({ why, thing }) => {
        why === 'SESSION' &&
          (() => {
            const url = window.sticky.session.getImage({ toThingId: thing.id })
            showImageUrl(url)
          })()
        why === 'SESSION--lock' &&
          (() => {
            ;(async () => {
              dispatch('LOADING')
              const { session } = this.props
              thing.lockSession(session)
              await window.sticky.things.save(thing, undefined, [
                'lockedSessionId',
              ])
              dispatch('STOP_LOADING')
            })()
          })()
      }),
    ]
    this.fetchAndSetNotificationSchedules()
  }

  async fetchAndSetNotificationSchedules(force = false) {
    const response = await window.sticky.notificationSchedule.get(
      this.props.session.id,
      force,
    )

    const schedules = response?.schedules ?? []
    this.setState({
      notifications: schedules.map((schedule) => ({
        ...schedule,
        time: convertUTCMinutesToLocalMinutes(schedule.time),
      })),
    })
  }

  async saveNotificationSchedules(schedules) {
    try {
      await window.sticky.notificationSchedule.upsert({
        sessionId: this.props.session.id,
        schedules: formatSchedules(schedules),
      })
      this.setState({
        hasMadeScheduledPushNotificationUpdate: false,
      })
    } catch ({ message }) {
      window.sticky.applications.blocks.showError(message, true)
    }
  }

  componentWillUnmount() {
    this.subscriptions && this.subscriptions.forEach((s) => s())
  }

  addNotification = () => {
    this.setState((prevState) => ({
      hasMadeScheduledPushNotificationUpdate: true,
      notifications: [
        ...prevState.notifications,
        {
          flowId: undefined,
          days: [],
          time: 60,
          message: '',
          sound: undefined,
        },
      ],
    }))
  }

  updateNotification = (index, field, value) => {
    const notifications = [...this.state.notifications]
    notifications[index][field] = value
    this.setState({
      hasMadeScheduledPushNotificationUpdate: true,
      notifications,
    })
  }

  duplicateNotification = (index) => {
    const notifications = [...this.state.notifications]
    const notificationToDuplicate = notifications[index]
    notifications.push({ ...notificationToDuplicate })
    this.setState({
      hasMadeScheduledPushNotificationUpdate: true,
      notifications,
    })
  }

  deleteNotification = (index) => {
    const notifications = [...this.state.notifications]
    notifications.splice(index, 1)
    this.setState({
      hasMadeScheduledPushNotificationUpdate: true,
      notifications,
    })
  }

  render() {
    const { user, session, onClose, applications } = this.props
    const {
      lastQuantumBalance,
      lastQuantumDiscount,
      notifications,
      hasMadeScheduledPushNotificationUpdate,
    } = this.state

    const tabs = [
      {
        id: 'main',
        name: session.identifierUnique,
        inlineIcon: dashboardIcons.teamMember,
        child: (
          <>
            <div className="buttons">
              <Button
                InlineIcon={dashboardIcons.teamMember}
                isSecondary
                onClick={() => {
                  dispatch('SESSION_BECOME', { session })
                  onClose && onClose()
                }}
              >
                Act as {session.identifierUniquePure}
              </Button>
              <Button
                isSecondary
                icon={window.sticky.applications.blocks.getIcon(
                  '7078f355-74cf-4c5e-b713-02de63c44610',
                )}
                listItems={[
                  {
                    id: 'application',
                    name: 'By flow',
                    InlineIcon: dashboardIcons.application,
                    onChoose: () =>
                      dispatch('CHOOSE_APPLICATIONS', { why: 'SESSION' }),
                  },
                  {
                    id: 'thing',
                    name: 'By sticky',
                    InlineIcon: dashboardIcons.thing,
                    onChoose: () =>
                      dispatch('CHOOSE_THING', { why: 'SESSION' }),
                  },
                ]}
              >
                Get identity code
              </Button>
              <Button
                isSecondary
                InlineIcon={dashboardIcons.thing}
                onClick={() =>
                  dispatch('CHOOSE_THING', { why: 'SESSION--lock' })
                }
              >
                Lock to sticky
              </Button>
            </div>
            <div className="grid">
              <Box>
                <H2>At {user.name}</H2>
                <KeyValuePairs
                  user={user}
                  object={session.userSector.toObject()}
                  onUpdate={({ name, value }) =>
                    writeUser(session, name, value, this.onReallyUpdated)
                  }
                  onAction={(action) => {
                    action === 'id' &&
                      dispatch('GET_INPUT', {
                        string: session.userSector.readFrom('ID') || '1',
                        hint: 'What ID?',
                        why: 'Session-id',
                        selectAll: true,
                      })
                    action === 'verify' &&
                      writeUser(
                        session,
                        'Verified',
                        Math.floor(
                          window.sticky.dateTime.getNowUtcLegacy() / 1000,
                        ),
                        this.onReallyUpdated,
                      )
                    action === 'unverify' &&
                      writeUser(session, 'Verified', null, this.onReallyUpdated)
                    action === 'executable-codes-create--StickyBalance' &&
                      dispatch('GET_INPUT', {
                        type: 'price',
                        string: lastQuantumBalance,
                        hint: <>How much ({user.currency})?</>,
                        why: 'executable-codes-create--StickyBalance',
                        selectAll: true,
                      })
                    action === 'executable-codes-create--StickyDiscount' &&
                      dispatch('GET_INPUT', {
                        type: 'number',
                        string: lastQuantumDiscount,
                        hint: <>How much (%)?</>,
                        why: 'executable-codes-create--StickyDiscount',
                        selectAll: true,
                      })
                  }}
                  actions={(() => {
                    const isVerified =
                      session.userSector.readFrom('Verified') ||
                      session.userSector.readFrom('stickyVerified')
                    const r = [
                      'id',
                      isVerified ? 'unverify' : 'verify',
                      'executable-codes-create--StickyBalance',
                      'executable-codes-create--StickyDiscount',
                    ]
                    return r
                  })()}
                  newDefaults={['name', 'value']}
                />
              </Box>
              <Box>
                <H2>Everywhere</H2>
                <KeyValuePairs
                  user={user}
                  object={session.crossUserSector.toObject()}
                  onUpdate={({ name, value }) =>
                    writeCus(session, name, value, this.onReallyUpdated)
                  }
                  newDefaults={['name', 'value']}
                />
              </Box>
            </div>
          </>
        ),
      },
      {
        id: 'payments',
        name: 'Payments',
        inlineIcon: dashboardIcons.payment,
        child: (
          <>
            <Button
              InlineIcon={dashboardIcons.add}
              onClick={() =>
                dispatch('CREATE_PAYMENT', { sessionId: session.id })
              }
            >
              Payment
            </Button>
            <AbstractAbstractPayments
              user={user}
              query={`dynamicExternalSticky&sessionId=${session.id}`}
              onSomethingClicked={() => dispatch('SESSION_CLOSE')}
              onReady={({ reRender }) => {
                this.paymentsReRender = reRender
              }}
            />
          </>
        ),
      },
      {
        id: 'scheduled-notifications',
        name: 'Scheduled notifications',
        inlineIcon: dashboardIcons.stickypay,
        child: (
          <>
            <SaveButton
              canSave={hasMadeScheduledPushNotificationUpdate}
              onSave={async () =>
                await this.saveNotificationSchedules(this.state.notifications)
              }
            />
            <div className="scheduled-notifications">
              {notifications.map((notification, index) => (
                <Box key={index} className="notification-item">
                  <DayPicker
                    name="days"
                    days={notification.days}
                    label="Days"
                    turnedOnColor="#26de81"
                    onChange={(days) => {
                      this.updateNotification(index, 'days', days)
                    }}
                  />
                  <TimePicker
                    time={notification.time}
                    label="Time"
                    timezone={0}
                    onChange={(value) => {
                      this.updateNotification(index, 'time', value)
                    }}
                  />
                  <Dropdown
                    label="Choose sound (optional)"
                    items={[
                      { id: 'default', name: 'Default' },
                      { id: 'beeping_alarm', name: 'Beeping Alarm' },
                      { id: 'high_ding_bell', name: 'High Ding Bell' },
                      { id: 'watch_alarm_tone', name: 'Watch Alarm Tone' },
                      { id: 'bubble_pop_sound', name: 'Bubble Pop Sound' },
                    ]}
                    onChoose={(value) =>
                      this.updateNotification(index, 'sound', value)
                    }
                    selected={notification.sound || 'default'}
                  />
                  <Input
                    id={`message-${index}`}
                    type="textarea"
                    name="message"
                    label="Message"
                    value={notification.message}
                    onChange={(value) =>
                      this.updateNotification(index, 'message', value)
                    }
                    placeholder="Enter your message here..."
                  />
                  <Label>Go to flow</Label>
                  <ApplicationChooser
                    applicableEntities={applications}
                    selected={[notification.flowId]}
                    allowNone="(Don't)"
                    onChange={([flowId]) => {
                      this.updateNotification(index, 'flowId', flowId)
                    }}
                  />
                  <div className="buttons">
                    <Button
                      InlineIcon={dashboardIcons.copy}
                      onClick={() => this.duplicateNotification(index)}
                      isSecondary
                    >
                      Copy
                    </Button>
                    <Button
                      InlineIcon={dashboardIcons.delete}
                      backgroundColor="#ff3838"
                      onClick={() => this.deleteNotification(index)}
                    >
                      Delete
                    </Button>
                  </div>
                </Box>
              ))}
              <Button
                InlineIcon={dashboardIcons.add}
                isSecondary
                onClick={this.addNotification}
                title="Add notification"
              >
                Notification
              </Button>
            </div>
          </>
        ),
      },
    ]

    return (
      <StyledComponent className="component--session">
        <TabBar tabs={tabs} />
        <p className="code">
          <strong>
            <code>{session.id}</code>
          </strong>
        </p>
      </StyledComponent>
    )
  }
}

Session.propTypes = {
  user: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
  applications: PropTypes.object,
}
