import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link, HotImage } from '@openbox-app-shared'

import ScrollableTable from './ScrollableTable'

const COLUMNS = {
  logo: '',
  name: '',
  actions: ''
}

const StyledComponent = styled.div`
  /* logo */
    .component--table td:nth-child(1) {
      min-width: 4rem; max-width: 4rem;
    }
  /* name */
    .component--table td:nth-child(2) {
      min-width: 16rem;
    }
  /* actions */
    .component--table td:nth-child(3) {
      min-width: 8rem;
    }
`

export default function PartnerUsers ({ users }) {
  const rows = users ? users.map(({ user, partner })=> {
    return {
      logo: <HotImage src={user.logoUrl} alt={user.name} />,
      name: <Link dontAttachQueryParameters to={`/me/flows?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}&partnerPrivateKey=${partner.privateKey}`} target='_blank'>{user.name}</Link>,
      actions: <>
        <Link dontAttachQueryParameters to={`/me/flows?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}`} target='_blank'>Log in as dashboard →</Link>
      </>
    }
  }) : []

  return (
    <StyledComponent>
      <ScrollableTable columns={COLUMNS} rows={rows} />
    </StyledComponent>
  )
}
PartnerUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object)
}
