/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import GenericModal from '../../../components/modals/generic'
import { Form, PriceInput, NumberInput, Input, Banner, Price } from '@openbox-app-shared'

import _ from '../../../_'
import { dispatch } from '../../../redux'

const StyledModal = styled.div`
  .component--modal {
    max-width: 20rem;
  }
  .component--form + .component--banner, .component--banner + .component--banner {
    margin-top: 1rem;
  }
`

const ITEMS = [
  {
    id: 'STICKER',
    name: 'Sticker',
    defaultPrice: 50 * 100
  },
  {
    id: 'TEAM_TIME',
    name: 'Day',
    defaultPrice: 1500 * 100
  }
]

function ThisModal ({ otherUser, onDone, onClose }) {
  const { defaultTax: taxRate } = window.sticky.internals.ISO_COUNTRIES.find(c => c[0] === otherUser.country)[1]
  const tax = Math.ceil(5000 * ((taxRate || 0) / 1000))
  const [payload, setPayload] = useState({ price: 100, whatIsIt: 'STICKER', howMany: 50, totalBeforeTax: ITEMS[0].defaultPrice, tax, totalAfterTax: ITEMS[0].defaultPrice + tax })

  return (
    <StyledModal>
      <GenericModal
        onGood={() => onDone(otherUser)}
        onClose={onClose}
      >
        <Form
          onSubmit={() => onDone(otherUser)}
          onChange={(k, v) => {
            const finalPayload = { ...payload, [k]: v }
            const totalBeforeTax = Math.ceil(finalPayload.price * finalPayload.howMany)
            const tax = Math.ceil(totalBeforeTax * ((taxRate || 0) / 1000))
            const totalAfterTax = totalBeforeTax + tax
            setPayload({ ...finalPayload, totalBeforeTax, tax, totalAfterTax })
          }}
        >
          <Input
            name='whatIsIt'
            label='Item'
            value={payload.whatIsIt}
          />
          <PriceInput
            name='price'
            label='Price before tax'
            value={payload.price}
          />
          <NumberInput
            name='howMany'
            label='Quantity'
            value={payload.howMany}
          />
        </Form>
        {taxRate === undefined && <Banner mood='very-bad'>
          <p>
            We don't know the {otherUser.country} tax rate. Proceed with caution.
          </p>
        </Banner>}
        <Banner>
          <p>Country: {otherUser.country}</p>
          <p>Currency: {otherUser.currency}</p>
          <p>Tax rate: {(taxRate || 0) / 10}%</p>
        </Banner>
        <Banner mood='good'>
          <p>Before tax: <Price price={payload.totalBeforeTax} currency={otherUser.currency} /></p>
          <p>Tax: <Price price={payload.tax} currency={otherUser.currency} /></p>
          <p>After tax: <Price price={payload.totalAfterTax} currency={otherUser.currency} /></p>
        </Banner>
      </GenericModal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, why, otherUser }) => {
    setState({ USER_BILL: { why, otherUser } })
  },
  render: function USER_BILL ({ state, setState }) {
    const { USER_BILL } = state
    if (!USER_BILL) {
      return null
    }
    const { why, otherUser } = USER_BILL
    const onDone = async user => {
      dispatch('LOADING')
      try {
        // go!!!
      } catch (e) {
        dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
      }
      dispatch('STOP_LOADING')
      setState({
        USER_BILL: undefined
      })
    }
    const onClose = () => {
      setState({
        USER_BILL: undefined
      })
    }
    return (
      <ThisModal
        otherUser={otherUser}
        onDone={onDone}
        onClose={onClose}
      />
    )
  }
}
