import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isUuid, CustomHelmet, Loading, Question, Button, Price, PartnerCell } from '@openbox-app-shared'

import Box from '../../../components/Box'
import GenericModal from '../../../components/modals/generic'
import _ from '../../../_'

import ProductsV2 from './components/ProductsV2'
import EposCart from './components/Cart'
import EposSummaryPayment from './components/SummaryPaymentV2'

import { log } from '../../../log'
import { dispatch, subscribe } from '../../../redux'
import ButtonGrid from '../../../components/ButtonGrid'
import CoolCart from '../../../components/CoolCart'

async function getProductsAndProductCategories () {
  const products = await window.sticky.products.getAll()
  const productCategories = (await window.sticky.products.getByCategories())
    .filter(pc => pc.isEnabled)
  return {
    products,
    productCategories
  }
}

const load = (k) => window.sticky.internals.readFromLocalStorage(`dashboard--opsManager-v2--${k}`)
const save = (k, v) => window.sticky.internals.writeToLocalStorage(`dashboard--opsManager-v2--${k}`, v)

const StyledRoute = styled.div`
  min-height: 100vh;
  .component--cool-cart {
    position: fixed;
    bottom: 1rem;
    left: unset;
    right: 1rem;
    z-index: 3;
    max-width: min(calc(100vw - 2rem), 388px);
  }
  > aside {
    width: 100%;
    position: relative;
    padding: 1rem 1rem 0 1rem;
    .component--box + .component--box {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 632px) {
    > aside {
      float: left;
      padding: 1rem 0 1rem 1rem;
      width: 400px;

    }
    > .content {
      margin-left: 400px;
      width: fit-content;
      max-width: 720px;
    }
  }
  .questions {
    .component--modal {
      max-width: 320px;
      .component--question + .component--question {
        margin-top: 1.5rem;
      }
    }
  }
  .component--partner-cell {
    margin-bottom: 1rem;
  }
  .applications-wrapper + .component--epos-products .component--button-grid {
    padding-top: 0;
  }
`

const generateKey = (i = '???', productId, questions) => {
  return `${i}---${productId}---${(questions || []).map(q => `${q.question}=${q.answer}`).join('--')}`
}

const getCartMargin = cpCart => {
  if (!cpCart) {
    return '0rem'
  }
  const allLength = (cpCart.reduce((acc, v) => acc + 1 + v.questions.length, 0) * 3) + ((cpCart.length - 1) * 0.125) + 2
  const num = Math.max(allLength, 9)
  log('[Route-view-opsManager-v2] [getCartMargin]', { allLength, num })
  return `${num}rem`
}

const getCartProductCount = cpCart => {
  if (!cpCart) {
    return 0
  }
  return cpCart.reduce((acc, v) => acc + v.quantity, 0)
}

export default class Route extends Component {
  constructor () {
    super()
    this.state = {
      currentlyAddingProduct: undefined,
      currentPayment: undefined,
      customTotal: 0
    }

    this.chooseProduct = this.chooseProduct.bind(this)
    this.addPayment = this.addPayment.bind(this)
    this.removeProduct = this.removeProduct.bind(this)
    this.onAction = this.onAction.bind(this)
    this.onMessage = this.onMessage.bind(this)

    this.GET_INPUT_GOOD = this.GET_INPUT_GOOD.bind(this)
  }

  onMessage (e) {
    e.data.event === 'HOW_TO_PAY' && (async () => {
      const { thingId, paymentIds, paymentsTotal, paymentsCurrency, paymentsCart } = e.data
      const currentPayment = await this.addPayment({
        thingId,
        originPaymentIdsDelete: paymentIds,
        total: paymentsTotal,
        currency: paymentsCurrency,
        cart: paymentsCart
      })
      this.setState(
        {
          currentPayment
        },
        () => {
          dispatch('GET_PRICE_GOODBYE')
          dispatch('HOW_TO_PAY_READY_1')
        }
      )
    })()
  }

  async onPaymentUpdate (customTotal) {
    const { currentPayment } = this.state
    log('[Route-view-opsManager-v2] [onPaymentUpdate] 1', { currentPayment, customTotal })
    const cart = currentPayment.cart.get()
    let total = await window.sticky.Stickypay.getTotal(cart)
    if (typeof customTotal === 'number') {
      total += customTotal
    }
    const patchable = {
      currency: await window.sticky.Stickypay.getCurrency(cart),
      total
    }
    log('[Route-view-opsManager-v2] [onPaymentUpdate] 2', { cart, patchable, total })
    currentPayment.patch(patchable)
    this.forceUpdate()
  }

  async saveCurrentPayment (extraState = {}) {
    const { currentPayment } = this.state
    log('[Route-view-opsManager-v2] [saveCurrentPayment]', { currentPayment })
    await window.sticky.pay.save(currentPayment, false)
    if (typeof extraState !== 'object') {
      return
    }
    if (Object.keys(extraState).length === 0) {
      this.forceUpdate()
    } else {
      return new Promise(resolve => this.setState(extraState, resolve))
    }
  }

  async addProduct (product, extraState = {}) {
    const { currentPayment } = this.state
    log('[Route-view-opsManager-v2] [addProduct]', { product, currentPayment: JSON.stringify(currentPayment, null, 2) })
    const customTotal = this.getCustomTotal()
    const cart = currentPayment.cart.get()
    const key = generateKey(undefined, product.id, product.questions)
    const foundCartItem = cart.find(ci => ci.key === key)
    const productPrice = window.sticky.Stickypay.getProductPrice(product, product.questions)
    product.changeStock(-1)
    if (foundCartItem) {
      foundCartItem.quantity += 1
      foundCartItem.productName = product.name
    } else {
      cart.push({
        key,
        product,
        productName: product.name,
        productCurrency: product.currency,
        productId: product.id,
        productPrice,
        quantity: 1,
        questions: product.questions.map(q => q.cloneToJson())
      })
    }
    await this.onPaymentUpdate(customTotal)
    await this.saveCurrentPayment(extraState)
  }

  async removeProduct (whichCi) {
    const customTotal = this.getCustomTotal()
    dispatch('LOADING')
    log('[Route-view-opsManager-v2] [removeProduct]', { whichCi, customTotal })
    const { currentPayment } = this.state
    let cart = currentPayment.cart.get()
    whichCi.quantity -= 1
    whichCi.product.changeStock(1)
    if (whichCi.quantity <= 0) {
      log('[Route-view-opsManager-v2] [removeProduct] inside if')
      cart = cart.filter(ci => {
        const keepThisOne = ci.key !== whichCi.key
        // log('[Route-view-opsManager-v2] [removeProduct] each ci key', ci.key, keepThisOne)
        return keepThisOne
      })
      currentPayment.cart.set(cart)
    }

    await this.onPaymentUpdate(customTotal)
    await this.saveCurrentPayment()

    const eventPayload = {
      type: 'VOID_PRODUCT',
      paymentId: currentPayment.id,
      productId: whichCi.product.id
    }
    await window.sticky.session.createEvent(
      eventPayload,
      'doesnt-matter',
      currentPayment.application && currentPayment.application.id,
      currentPayment.thing && currentPayment.thing.id
    )

    dispatch('STOP_LOADING')
  }

  async chooseProduct (product) {
    log('[Route-view-opsManager-v2] [chooseProduct]', { product })
    if (product.questions.length === 0) {
      this.addProduct(product)
    } else {
      this.setState({
        currentlyAddingProduct: product
      })
    }
  }

  async addPayment (createPaymentPayload = {}) {
    const { currentApplication } = this.state
    const currentPayment = await window.sticky.pay.create(currentApplication ? currentApplication.id : undefined, createPaymentPayload)
    save('lastPaymentId', currentPayment.id)
    return currentPayment
  }

  async componentDidMount () {
    const { user } = this.props
    window.addEventListener('message', this.onMessage)
    this.subscriptions = [
      subscribe('REDUX_HANDLER_READY', () => {
        const { federatedUser } = user

        dispatch('LOADING')
        ;(async () => {
          try {
            window.sticky.assert(federatedUser, 'You should never see this message. Please contact us.')
            const things = await window.sticky.things.getAll()
            const applications = await window.sticky.applications.getAll()
            const applicationsStickyretail2 = applications.filter(a => a.stickyretail.readFrom('showInStickyretail2') === true)
            const applicationsMoto = applications
              .filter(a => a.baseSettingsRender === 'stickypay' && a.stickyretail.readFrom('isMoto'))
            const applicationsNotMoto = applications
              .filter(a => a.baseSettingsRender === 'stickypay' && !a.stickyretail.readFrom('isMoto'))
            const productsAndProductCategories = await getProductsAndProductCategories()

            let currentPayment
            const maybeFinalPaymentId = load('lastPaymentId')
            try {
              window.sticky.assert(isUuid(maybeFinalPaymentId))
              currentPayment = await window.sticky.pay.get(maybeFinalPaymentId)
            } catch (e) {
              currentPayment = await this.addPayment()
            }
            
            const state = {
              currentFederatedUser: federatedUser,
              things,
              applicationsMoto,
              applicationsNotMoto,
              applications,
              applicationsStickyretail2,
              ...productsAndProductCategories,
              currentPayment
            }
            this.setState(state)
            dispatch('STOP_LOADING')
            if (productsAndProductCategories.productCategories.length === 0) {
              dispatch('GET_PRICE', { why: 'epos--custom-total', price: currentPayment.total, canBeClosed: false, mustBeMoreThanZero: true })
            }
          } catch ({ message }) {
            window.sticky.applications.blocks.showError(message)
          } finally {
            dispatch('STOP_LOADING')
          }

          
        })()
      })
    ]

    const { userPreferences: { lastPaymentId } } = this.props

    log('[Route-view-opsManager-v2] [componentDidMount]', { lastPaymentId })
    document.querySelector('main').style.maxWidth = 'none'
    document.querySelector('.component--navbar').style.display = 'none'

    window.sticky.bodgeZone.triggerConsumerApp = async (what, args, callback) => {
      const formChecklist = ([masterReference, index, isChecked]) => {
        // coming from "real" flows and steps, not the question modal
        if (typeof masterReference !== 'number') {
          return
        }
        const foundToBeModified = this.state.currentlyAddingProduct.questions[masterReference]
        const toPush = this.state.currentlyAddingProduct.questions[masterReference].options[index].name
        log('[App] [componentDidMount]->triggerConsumerApp->formChecklist 1', { masterReference, index, isChecked, foundToBeModified, toPush, final: foundToBeModified.answer })
        if (isChecked) {
          foundToBeModified.answer = [...foundToBeModified.answer, toPush]
        } else {
          foundToBeModified.answer = foundToBeModified.answer.filter(_ => _ !== toPush)
        }
        this.forceUpdate()
      }
      const formChooser = async ([masterReference, newValue]) => {
        // coming from "real" flows and steps, not the question modal
        if (typeof masterReference !== 'number') {
          return
        }
        const foundToBeModified = this.state.currentlyAddingProduct.questions[masterReference]
        foundToBeModified.answer = newValue
        this.forceUpdate()
      }
      const whatFunction = new Map([
        ['form--checklist', formChecklist],
        ['form--chooser', formChooser]
      ]).get(what)
      const whatFunctionResult = whatFunction ? await whatFunction(args) : undefined
      return whatFunctionResult
    }

    window.sticky.Stickypay.setMinimum(0)

    this.subscriptions = [
      ...this.subscriptions,
      subscribe('GET_INPUT_GOOD', this.GET_INPUT_GOOD),
      subscribe(
        'PAY_WAIT_ORIGIN_BAD',
        (...args) => {}
      ),
      subscribe(
        'PAY_WAIT_ORIGIN_CANCEL',
        (...args) => {}
      ),
      subscribe(
        'GET_PRICE_GOOD',
        ({ why, price }) => {
          why === 'epos--custom-total' && (() => {
            const { productCategories } = this.state
            if (productCategories.length === 0) {
              this.setCustomTotal(price)
              dispatch('HOW_TO_PAY_READY_2')
            } else {
              this.setCustomTotal(this.state.currentPayment.total + price)
            }
          })()
        }
      )
    ]
  }

  componentWillUnmount () {
    window.sticky.bodgeZone.triggerConsumerApp = undefined
  }

  async GET_INPUT_GOOD ({ why, string }) {
    why === 'stickyretail2-email' && (async () => {
      const { currentPayment } = this.state
      currentPayment.patch({ email: string })
      await this.saveCurrentPayment()
      dispatch('TRIGGER', { trigger: 'public--emailPayment', forcePublicKey: true, body: { paymentId: currentPayment.id, sessionId: currentPayment.sessionId } })
    })()
  }

  async componentWillUnmount () {
    log('[Route-view-opsManager-v2] [componentWillUnmount]')
    clearInterval(this.refreshTimer)

    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  async reallyAddPayment () {
    const currentPayment = await this.addPayment()
    this.setState({
      currentPayment
    })
    const { productCategories } = this.state
    if (productCategories.length === 0) {
      dispatch('GET_PRICE', { why: 'epos--custom-total', price: currentPayment.total, mustBeMoreThanZero: true })
    }
  }

  async onAction (action, payload = {}, doShowLoading = true) {
    let { currentPayment } = this.state
    doShowLoading && dispatch('LOADING')

    await ({
      'pay': async () => {
        const { toDo, thing, newApplicationId } = payload
        let cart
        const HOW_TOS = {
          'GATEWAY_CASH': {
            patchAndSave: true,
            changes: {
              gateway: 'GATEWAY_CASH',
              sessionPaidAt: Math.floor((+window.sticky.dateTime.getNowUtcLegacy()) / 1000),
              cart,
              newStatusDone: currentPayment.originPaymentIdsDelete.length === 0 ? false : null
            },
            postLogic: async () => {
              await this.reallyAddPayment()
            }
          },
          'GATEWAY_CARD': {
            patchAndSave: true,
            changes: {
              gateway: 'GATEWAY_CARD',
              sessionPaidAt: Math.floor((+window.sticky.dateTime.getNowUtcLegacy()) / 1000),
              cart,
              newStatusDone: currentPayment.originPaymentIdsDelete.length === 0 ? false : null
            },
            postLogic: async () => {
              await this.reallyAddPayment()
            }
          },
          'sticky': {
            patchAndSave: true,
            changes: {
              thingId: thing ? thing.id : undefined,
              applicationId: thing ? thing.applicationId : undefined,
              cart,
              newStatusDoneDontSet: true
            },
            thingLogic: async () => {
              thing.customData.writeTo('paymentId', currentPayment.id)
              thing.patch({
                customData: thing.customData.get(),
                applicationIdHotSwap: thing.applicationId,
                applicationId: newApplicationId,
              })
            },
            postLogic: async () => {
              dispatch(
                'PAY_WAIT_ORIGIN',
                {
                  why: 'epos--pay--thing',
                  extra: {
                    howToLinkGeneric: sessionId => window.sticky.things.goTest(thing.id, sessionId, undefined, 'previewLinkCounter=1'),
                    linkImage: window.sticky.things.getImage(thing.id),
                    referenceKey: 'thingId',
                    referenceValue: thing.id,
                    showQrImmediate: thing.isVirtual
                  }
                }
              )
            }
          }
        }

        const foundHowTo = HOW_TOS[toDo]
        const { changes, thingLogic, preLogic, postLogic } = foundHowTo
        log('[Route-view-opsManager-v2] [onAction]->pay', { action, currentPayment, changes, toDo, foundHowTo, thing })

        try {
          const preLogicResult = preLogic && await preLogic()
          if (typeof preLogicResult === 'object') {
            const { changes: preLogicChanges } = preLogicResult
            currentPayment.patch(preLogicChanges)
          }
          // preLogic is an exit routine; if it throws don't do anything else!
          thing && thingLogic && await (async () => {
            await thingLogic()
            await window.sticky.things.save(thing, undefined, ['customData', 'applicationId', 'applicationIdHotSwap'])
          })()
          currentPayment.patch(changes)
          const scpDelta = {}
          await this.saveCurrentPayment(scpDelta)
          postLogic && await postLogic()
        } catch (e) {
          dispatch('SHOW_MESSAGE', { message: <><p><strong>Sorry, that didn't work.</strong></p><p>{e.message}</p></>, canBeBadded: '' })
        }
      },
      'epos--GATEWAY_LATER': async () => {
        const { thing } = payload
        log('[Route-view-opsManager-v2] [onAction]->epos--GATEWAY_LATER 1', { thing })
        currentPayment.patch(
          {
            thingId: thing.id,
            newStatusDone: false
          }
        )
        currentPayment.thing = thing
        log('[Route-view-opsManager-v2] [onAction]->move 2')
        await this.saveCurrentPayment({})
        dispatch('TRIGGER', { trigger: 'printPayment', body: { paymentId: currentPayment.id }, showMessage: false })
        const newPayment = await this.addPayment()
        this.setState({
          currentPayment: newPayment
        })
      },
      'patch': async () => {
        currentPayment.patch({ [payload.k]: payload.v })
        await this.saveCurrentPayment({})
      },
      'extra': async () => {
        currentPayment.extra = payload.string
        await this.saveCurrentPayment({})
      },
      'v2VoidThenAddPayment': async () => {
        const oldPayment = this.state.currentPayment
        await window.sticky.pay.remove(oldPayment)
        await this.reallyAddPayment()
        const finalMessage = { event: 'HOW_TO_PAY_BACK' }
        window.parent.postMessage(finalMessage)
      },
      'v2ChangeTotal': async () => {
        const { productCategories } = this.state
        if (productCategories.length === 0) {
          dispatch('GET_PRICE', { why: 'epos--custom-total', price: this.state.currentPayment.total, canBeClosed: false, mustBeMoreThanZero: true })
        }
      },
      'addPayment': async () => {
        await this.reallyAddPayment()
      },
      'print': async () => {
        log('[Route-view-opsManager-v2] [onAction]->print')
        dispatch('TRIGGER', { trigger: 'printPayment', body: { paymentId: currentPayment.id } })
      },
      'email': async () => {
        dispatch('GET_INPUT', { type: 'email', why: 'stickyretail2-email', hint: 'Choose an email:', string: currentPayment.email || '', selectAll: true })
      }
    })[action]()
    doShowLoading && dispatch('STOP_LOADING')
  }

  getCustomTotal () {
    const { currentPayment } = this.state
    const rawPaymentTotal = currentPayment.total
    const rawCartTotal = window.sticky.Stickypay.getPreDiscountTotalSync(currentPayment.cart.get())
    log('[Route-view-opsManager-v2] [getCustomTotal] rawCartTotal', rawCartTotal)
    log('[Route-view-opsManager-v2] [getCustomTotal] currentPayment.total', currentPayment.total)
    return (rawCartTotal !== rawPaymentTotal ? rawPaymentTotal - rawCartTotal : undefined)
  }

  render () {
    const { user, userPreferences } = this.props
    const {
      things,
      applicationsMoto,
      applicationsNotMoto,
      applicationsStickyretail2,
      productCategories,
      currentlyAddingProduct,
      currentPayment,
      lastPaymentId
    } = this.state

    const canRender = [currentPayment, things, productCategories].every(_ => _)
    if (!canRender) {
      return null
    }

    const hasPaid = typeof currentPayment.sessionPaidAt === 'number'
    log('[Route-view-opsManager-v2] [render]', { currentPayment, lastPaymentId, productCategories })

    function generateCiBodge (key, name, number) {
      return {
        key: `bodge---${key}`,
        productCurrency: currentPayment.currency,
        product: {
          price: Math.abs(number),
          name
        },
        productPrice: Math.abs(number),
        quantity: 1,
        questions: [],
        quantitySymbolOverride: number >= 0 ? '+' : '-'
      }
    }

    const originalCpCart = currentPayment.cart.get()
    const cartProductCount = getCartProductCount(originalCpCart)
    const customTotal = this.getCustomTotal()
    let cpCart = originalCpCart
    if (typeof customTotal === 'number') {
      cpCart = [
        ...originalCpCart,
        generateCiBodge('customTotal', cartProductCount > 0 ? 'Plus' : 'Total', customTotal)
      ]
    }
    const asideMarginBottom = getCartMargin(cpCart)

    return (
      <StyledRoute
        className='level--styled-route'
        style={{ paddingBottom: asideMarginBottom }}
      >
        <CustomHelmet
          title={window.sticky._('STICKY_PAY')}
        />

        {currentlyAddingProduct && (
          <GenericModal
            className='questions'
            onGood={() => {
              this.addProduct(currentlyAddingProduct, { currentlyAddingProduct: undefined })
            }}
            onClose={() => {
              this.setState({
                currentlyAddingProduct: undefined
              })
            }}
          >
            {currentlyAddingProduct.questions.map((q, i) => {
              return (
                <Question
                  key={`${i}-${q.question}`}
                  question={q}
                  masterReference={i}
                  currency={currentlyAddingProduct.currency}
                />
              )
            })}
          </GenericModal>
        )}

        {!canRender && <Loading />}
        {canRender && (
          <>
            {cpCart && cpCart.length > 0 && (
              <CoolCart isCollapsedByDefault headline={<><Price price={currentPayment.total} currency={currentPayment.currency} />{cartProductCount > 0 ? ` | ${cartProductCount} ${cartProductCount !== 1 ? 'products' : 'product'}` : ''}</>}>
                <EposCart
                  cart={cpCart}
                  canVoid={!hasPaid ? '#ff3838' : false}
                  onVoid={cartItem => {
                    const handlers = {
                      'bodge---customTotal': () => {
                        this.setCustomTotal(currentPayment.total - customTotal)
                      }
                    }
                    const foundHandler = handlers[cartItem.key]
                    if (foundHandler) {
                      foundHandler()
                    } else {
                      this.removeProduct(cartItem)
                    }
                  }}
                  canNote
                  onNote={whichCi => {
                    ;(async () => {
                      const { Message: customNote } = await window.sticky.applications.blocks.getInput(
                        undefined,
                        'Message',
                        whichCi.customNote || '',
                        'Text',
                        'Done',
                        undefined,
                        undefined,
                        undefined,
                        false
                      )
                      let cart = currentPayment.cart.get()
                      cart = cart.map(ci => {
                        if (ci.key === whichCi.key) {
                          ci.customNote = customNote
                        }
                        return ci
                      })
                      currentPayment.cart.set(cart)
                      dispatch('LOADING')
                      try {
                        await this.saveCurrentPayment()
                        dispatch('STOP_LOADING')
                      } catch ({ message }) {
                        dispatch('STOP_LOADING')
                        window.sticky.applications.blocks.showError(message)
                      }
                    })()
                  }}
                />
              </CoolCart>
            )}
            <aside>
              <PartnerCell partner={user.partner} />
              <Box>
                <EposSummaryPayment
                  user={user}
                  userPreferences={userPreferences}
                  payment={currentPayment}
                  things={things}
                  thingsVirtual={things.filter(_ => _.isVirtual)}
                  thingsNotVirtual={things.filter(_ => !_.isVirtual)}
                  applicationsMoto={applicationsMoto}
                  applicationsNotMoto={applicationsNotMoto}
                  canMove={things.length > 0}
                  onAction={this.onAction}
                />
              </Box>
            </aside>
            <div className='content'>
              {applicationsStickyretail2.length > 0 && (<div className='applications-wrapper'>
                <ButtonGrid>
                  {applicationsStickyretail2.map(a => (
                    <Button
                      key={a.id}
                      icon={a.baseIcon}
                      isSecondary
                      color='white'
                      backgroundColor='#1A1F35'
                      onClick={() => {
                        window.sticky.popUpIframe({
                          src: sticky.applications.test(a.id),
                          maxWidth: '376px',
                          maxHeight: '680px'
                        })
                      }}
                    >
                      {a.name}
                    </Button>
                  ))}
                </ButtonGrid>
              </div>)}
              <ProductsV2
                productCategories={productCategories}
                onChoose={this.chooseProduct}
              />
            </div>
          </>
        )}
      </StyledRoute>
    )
  }

  async setCustomTotal (total, extraState = {}) {
    this.state.currentPayment.patch(
      {
        total,
        ...extraState
      }
    )
    this.saveCurrentPayment({})
  }
}

Route.propTypes = {
  user: PropTypes.object,
  userPreferences: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
