import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, List, ListItem, UploadImage, Dropdown, Switch, Button } from '@openbox-app-shared'
import H2 from '../components/H2'
import Box from '../components/Box'
import { log } from '../log'
import { dispatch, subscribe } from '../redux'
import dashboardIcons from '../icons'

const StyledMediaEditor = styled.div`
  .grid {
    > * + * {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 960px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0 !important;
      }
    }
  }
  > .buttons {
    height: 2.5rem;
    margin-bottom: 1rem;
    > * {
      float: left;
    }
    > * + * {
      margin-left: 0.5rem;
    }
  }
  * + .component--switch {
    margin-top: 1rem;
  }
  .component--list {
    max-width: 32rem;
  }
  .component--list + .component--box, .component--box + .component--box, .component--box + .component--upload {
    margin-top: 1rem;
  }
  .middle-switch {
    max-width: 20rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .do-upload {
    max-width: 24rem;
  }
`

export default function MediaEditor ({ media, user, onChange, defaultToDoLinear = false }) {
  const [showInLinearOperatorView, setShowInLinearOperatorView] = useState(defaultToDoLinear)
  const [showInEpos, setShowInEpos] = useState(false)
  const [federatedUsers, setFederatedUsers] = useState()
  const [currentFederatedUser, setCurrentFederatedUser] = useState('all')

  useEffect(() => {
    const subscriptions = [
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, string }) => {
          let [realIndex, realWhy] = why.split('---')
          realIndex = parseInt(realIndex, 10)
          realWhy === 'media-editor--add-text-message' && (() => {
            const toAdd = {
              type: 'text',
              text: string,
              showInLinearOperatorView,
              federatedUserId: showInLinearOperatorView ? currentFederatedUser : undefined,
              showInEpos
            }
            log('[MediaEditor] [UploadVideo->onChange]', { toAdd })
            add(toAdd)
          })()
          ;['media-editor--edit--image', 'media-editor--edit--video'].includes(realWhy) && (() => {
            maybeOnChange(media.map((lm, lIndex) => {
              if (lIndex === realIndex) {
                lm = { ...lm, url: string }
              }
              return lm
            }))
          })()
          realWhy === 'media-editor--edit--text' && (() => {
            maybeOnChange(media.map((lm, lIndex) => {
              if (lIndex === realIndex) {
                lm = { ...lm, text: string }
              }
              return lm
            }))
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })
  useEffect(() => {
    async function getFederatedUsers () {
      setFederatedUsers(
        await window.sticky.users.federated.getAll()
      )
    }
    !Array.isArray(federatedUsers) && getFederatedUsers()
  })
  if (!federatedUsers) {
    return null
  }

  const maybeOnChange = (newMedia) => {
    onChange && onChange(newMedia)
  }
  const add = (object) => {
    maybeOnChange([
      ...media,
      object
    ])
  }

  const onAction = (id, action) => {
    log('[MediaEditor] [onAction]', { id, action })
    const index = parseInt(id.substring('media-'.length), 10)
    const foundMedia = media[index]
    const actions = {
      'edit': () => {
        const mediaTypes = {
          'image': () => {
            dispatch('GET_INPUT', { why: `${index}---media-editor--edit--image`, type: 'url', string: foundMedia.url, selectAll: true })
          },
          'video': () => {
            dispatch('GET_INPUT', { why: `${index}---media-editor--edit--video`, type: 'url', string: foundMedia.url, selectAll: true })
          },
          'text': () => {
            dispatch('GET_INPUT', { why: `${index}---media-editor--edit--text`, string: foundMedia.text, selectAll: true })
          }
        }
        mediaTypes[foundMedia.type]()
      },
      'delete': () => {
        maybeOnChange(
          media.filter(m => m !== foundMedia)
        )
      }
    }
    actions[action]()
  }

  return (
    <StyledMediaEditor className='component--media-editor'>
      {media.length > 0 && (<List
        draggable
        onDrag={(from, to) => {
          window.sticky.swapArrayElements(media, from, to)
          maybeOnChange(media)
        }}
      >
        {media.map((m, i) => {
          let finalText
          const tags = []
          if (m.type === 'text') {
            finalText = m.text
          } else {
            finalText = [
              `${m.type === 'video' ? 'Video' : 'Image'} ${i + 1}`
            ]
              .filter(e => e)
              .join(' ')
          }
          m.showInEpos && tags.push({ name: window.sticky._('STICKY_PAY') })
          if (m.showInLinearOperatorView) {
            tags.push({ name: 'Linear live payments' })
            if (m.federatedUserId === 'all' || !m.federatedUserId) {
              tags.push({ name: 'Everyone' })
            } else {
              const foundFu = federatedUsers.find(fu => fu.id === m.federatedUserId)
              if (foundFu) {
                tags.push({ name: foundFu.name, color: foundFu.color })
              } else {
                tags.push({ name: 'Deleted team member', color: '#FF3838' })
              }
            }
          }
          return (
            <ListItem
              id={`media-${i}`}
              key={`media-${i}`}
              icon={m.type === 'text' ? icons.generic.listItem : (m.posterUrl || m.url || icons.generic.video)}
              actions={['delete']}
              tags={tags}
              onAction={onAction}
              goTo={m.type === 'text' ? undefined : m.url}
              target='_blank'
            >
              {finalText}
            </ListItem>
          )
        })}
      </List>)}
      <Box className='do-upload'>
        <H2>Upload {media.length > 0 ? 'another' : 'an'} image</H2>
        <Switch
          className='middle-switch'
          checked={showInEpos}
          onChange={v => setShowInEpos(v)}
        >
          Show image in {window.sticky._('STICKY_PAY')}
        </Switch>
        {user.can('show-linear-operator-view') && (
          <>
            <Switch
              className='middle-switch'
              checked={showInLinearOperatorView}
              onChange={v => setShowInLinearOperatorView(v)}
            >
              Show in "Linear live payments"?
            </Switch>
            {showInLinearOperatorView && <Dropdown
              className='middle-switch'
              label='Team member'
              selected={currentFederatedUser}
              items={[
                { id: 'all', name: '(Everyone)' },
                ...federatedUsers.map(fu => ({ id: fu.id, name: fu.name }))
              ]}
              onChoose={setCurrentFederatedUser}
            />}
          </>
        )}
        <UploadImage
          color='#a55eea'
          onChange={({ url }) => {
            const toAdd = {
              type: 'image',
              url,
              showInLinearOperatorView,
              federatedUserId: showInLinearOperatorView ? currentFederatedUser : undefined,
              showInEpos
            }
            log('[MediaEditor] [UploadImage->onChange]', { toAdd })
            add(toAdd)
          }}
        />
        {/* <div className='grid'>
          <div>
            <Box>
              <H2>Video</H2>
              <UploadVideo
                color='#a55eea'
                onChange={({ url }) => {
                  const toAdd = {
                    type: 'video',
                    url,
                    showInLinearOperatorView,
                    federatedUserId: showInLinearOperatorView ? currentFederatedUser : undefined,
                    showInEpos
                  }
                  log('[MediaEditor] [UploadVideo->onChange]', { toAdd })
                  add(toAdd)
                }}
              />
            </Box>
          </div>
        </div> */}
        {showInLinearOperatorView && <>
          <hr />
          <Button
            backgroundColor='#a55eea'
            InlineIcon={dashboardIcons.add}
            onClick={() => {
              dispatch('GET_INPUT', { why: '0---media-editor--add-text-message', string: 'Message', selectAll: true })
            }}
          >
            Add a text message
          </Button>
        </>}
      </Box>
      {/* {showChooseResource && <Box>
        <H2>Add a sample image</H2>
        <ChooseResource onChoose={add} />
      </Box>} */}
    </StyledMediaEditor>
  )
}

MediaEditor.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      url: PropTypes.string,
      posterUrl: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  user: PropTypes.object,
  onChange: PropTypes.func,
  defaultToDoLinear: PropTypes.bool
}
