import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Banner, Button } from '@openbox-app-shared'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util'

const StyledUpload = styled.div`
  .buttons {
    height: 2.5rem;
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
    > * {
      display: inline-block;
      vertical-align: top;
    }
    > * + * {
      margin-left: 0.5rem;
    }
  }
  .buttons + * {
    margin-top: 1rem;
  }
`

const LoadingOverlay = styled.div`
  margin: 0 !important;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: rgba(0,0,0,0.4);
  z-index: 9999;
  color: #fff;
  font-size: 18px;
  flex-direction: column;
  text-align: center;
`

const Spinner = styled.div`
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

const TIMEOUT_DURATION = 30 * 1000 // 30 seconds without logs considered a stall

export default function UploadCompressedVideo({ value = null, label, show = false, onChange }) {
  const [uploadedVideoUrl, setUploadedVideoUrl] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [statusMessage, setStatusMessage] = useState(null)
  const [error, setError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const ffmpegRef = useRef(new FFmpeg())

  const loadFFmpeg = async () => {
    if (loaded) return
    setStatusMessage('Loading FFmpeg...')
    const ffmpeg = ffmpegRef.current
    ffmpeg.on('log', ({ message }) => {
      console.log('[FFmpeg Log]:', message)
      resetWatchdog()
    })

    await ffmpeg.load({
      coreURL: await toBlobURL('/ffmpeg-core.js', 'text/javascript'),
      wasmURL: await toBlobURL('/ffmpeg-core.wasm', 'application/wasm'),
    })
    console.log('[UploadCompressedVideo]: FFmpeg loaded successfully.')
    setLoaded(true)
  }

  let watchdogTimer = null
  const resetWatchdog = () => {
    if (watchdogTimer) clearTimeout(watchdogTimer)
    watchdogTimer = setTimeout(() => {
      console.error('[UploadCompressedVideo]: No progress for a while, throwing error.')
      setError('Processing is taking too long. Please try again with a different file.')
      setIsProcessing(false)
      setStatusMessage(null)
    }, TIMEOUT_DURATION)
  }

  const handleVideoProcessing = async (file) => {
    setIsProcessing(true)
    setError(null)
    setStatusMessage('Preparing...')

    try {
      console.log('[UploadCompressedVideo]: Starting video processing...')
      await loadFFmpeg()
      const ffmpeg = ffmpegRef.current

      const videoUrl = URL.createObjectURL(file)
      console.log(`[UploadCompressedVideo]: Generated video URL: ${videoUrl}`)

      setStatusMessage('Reading video file...')
      const videoData = await fetchFile(videoUrl)
      console.log('[UploadCompressedVideo]: Video data fetched.')

      setStatusMessage('Writing video to virtual filesystem...')
      await ffmpeg.writeFile('input.mp4', videoData)
      console.log('[UploadCompressedVideo]: input.mp4 written.')

      // Start the watchdog before ffmpeg.exec
      resetWatchdog()

      setStatusMessage('Compressing and resizing video...')
      await ffmpeg.exec([
        '-i', 'input.mp4',
        '-vf', 'scale=448:-2',
        '-crf', '28',
        '-preset', 'fast',
        '-c:a', 'aac',
        'output.mp4'
      ])
      console.log('[UploadCompressedVideo]: Video processed to output.mp4.')

      resetWatchdog()
      setStatusMessage('Reading processed video from memory...')
      const processedVideoData = await ffmpeg.readFile('output.mp4')

      setStatusMessage('Uploading MP4 file...')
      const uploadResponse = await window.sticky.upload(
        new File([processedVideoData], 'video.mp4', { type: 'video/mp4' })
      )
      console.log('[UploadCompressedVideo]: Upload completed. Response:', uploadResponse)

      if (uploadResponse && uploadResponse.url) {
        console.log(`[UploadCompressedVideo]: MP4 uploaded successfully. URL: ${uploadResponse.url}`)
        setUploadedVideoUrl(uploadResponse.url)
        onChange({ url: uploadResponse.url })
      } else {
        throw new Error('Invalid response from upload endpoint.')
      }

      setStatusMessage('Upload complete!')
      console.log('[UploadCompressedVideo]: Processing and uploading completed successfully.')

      setTimeout(() => {
        setIsProcessing(false)
        setStatusMessage(null)
      }, 1000)

    } catch (err) {
      console.error('Error while processing the video:', err)
      setError('Error while processing the video. Please try again.')
      setIsProcessing(false)
      setStatusMessage(null)
    } finally {
      if (watchdogTimer) clearTimeout(watchdogTimer)
    }
  }

  return (
    <StyledUpload>
      <form>
        <input
          type="file"
          accept=".mp4"
          disabled={isProcessing}
          onChange={(e) => {
            const file = e.target.files[0]
            if (file) {
              // Check file size (100MB limit)
              if (file.size > 100 * 1024 * 1024) {
                setError('File is too large. Maximum 100MB allowed.')
                onChange({ url: null })
                return
              }
              setError(null)
              console.log(`[UploadCompressedVideo]: Selected file: ${file.name}, size: ${file.size} bytes`)
              onChange({ url: URL.createObjectURL(file) })
            } else {
              onChange({ url: null })
            }
          }}
        />
      </form>
      <div className="buttons">
        <Button
          onClick={async () => {
            const file = document.querySelector('input[type=file]').files[0]
            if (!file) {
              alert('Please select a file first.')
              return
            }
            console.log('[UploadCompressedVideo]: Starting process button clicked.')
            await handleVideoProcessing(file)
          }}
          disabled={isProcessing}
        >
            Process
        </Button>
      </div>
      {isProcessing && (
        <LoadingOverlay>
          <Spinner />
          <p>{statusMessage}</p>
        </LoadingOverlay>
      )}
      {!isProcessing && error && <Banner mood='bad'><p>{error}</p></Banner>}
      {!error && !isProcessing && show && uploadedVideoUrl && <Banner><p>Your video has been processed.</p></Banner>}
    </StyledUpload>
  )
}

UploadCompressedVideo.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  show: PropTypes.bool,
  onChange: PropTypes.func
}
