import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'

import { Button } from '@openbox-app-shared'
import { PaymentSummary } from '../../../../components/payments'
import Box from '../../../../components/Box'
import CoolButton from '../../../../components/CoolButton'
import CoolLinkButton from '../../../../components/CoolLinkButton'
import UserCircle from '../../../../components/UserCircle'
import TagChooserUser from '../../../../components/TagChooserUser'
import dashboardIcons from '../../../../icons'
import { PaymentHeadlines, PaymentExtra } from '../../../../components/payments'

const StyledComponent = styled.div`
  .struck-out {
    text-decoration: line-through;
    text-decoration-color: #EA2027;
  }
  > .component--box {
    position: relative;
    height: 100%;
    padding: 0.75rem;
    padding-bottom: 0;
    border: 0.25rem solid transparent;
    &.hidden {
      opacity: 0.35;
    }
  }
  .controls-1 {
    position: absolute;
    top: 1rem;
    right: 1rem;
    // background-color: green;
    > .component--button {
      display: inline-block;
      vertical-align: top;
    }
    > .component--button + .component--button {
      margin-left: 0.5rem;
    }
    .component--button {
      width: 4rem;
      height: 4rem;
      padding: 0;
      > svg {
        width: 2rem;
        height: 2rem;
        margin-bottom: 0;
      }
    }
  }
  .controls-2 {
    margin-top: 1rem;
    float: right;
    > * {
      display: inline-block;
      vertical-align: top;
      margin-right: 0.5rem;
      margin-bottom: 0.75rem;
    }
    > *:last-child {
      margin-right: 0;
    }
  }
  .details {
    position: relative;
    &.count--1 {
      margin-right: 4.5rem;
    }
    &.count--2 {
      margin-right: 9rem;
    }
    .other-stuff {
      display: block;
      position: relative;
      margin-right: 5rem;
      top: 0;
      left: 0;
      margin-bottom: 1rem;
      color: #6C7A89;
    }
    .component--tag-chooser {
      width: fit-content;
      ul li {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
    .cart {
      margin-top: 1rem;
      background-color: transparent;
      .cart-item {
        .component--payment-extra {
          margin-left: 3rem;
        }
        .summary {
          > * {
            display: inline-block;
            vertical-align: top;
          }
          .product-name {
            position: relative;
            padding-left: 3rem;
            img {
              width: 2rem;
              height: 2rem;
              vertical-align: inherit;
              border-radius: 4px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          > h1 {
            line-height: 1.5rem;
            font-size: 110%;
          }
          .quantity {
            color: #EA2027;
            margin-right: 1rem;
          }
          .product-price {
            color: #6C7A89;
          }
        }
        .questions {
          margin-top: 0.5rem;
          padding-left: 3rem;
          .question {
            font-size: 110%;
            color: #6C7A89;
            line-height: 1.25rem;
          }
          .question + .question {
            margin-top: 0.75rem;
          }
        }
      }
      .cart-item + .cart-item {
        margin-top: 1rem;
      }
    }
    .component--payment-extra {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  .cb-tag {
    font-size: 0.75rem;
    strong {
      min-height: 1rem;
      line-height: 1rem;
    }
  }
  .cb-tag:not(.has) {
    opacity: 0.5;
  }
  .cb-done.is-done {
    font-size: 0.6rem;
  }
  .cb-done:not(.is-done) {
    font-size: 0.7rem;
  }
`

export default function Card ({ userPreferences, rdic, user, entity, currentFederatedUser, isDone, onAction, filterByProductTags }) {
  const allTags = user.tags.get()
  const pCurrentFederatedUser = rdic.federatedUsers && rdic.federatedUsers.find(fu => fu.id === entity.lastFederatedUserId)
  const controls1ItemCount = 1
  const boxClasses = isDone ? ['hidden', 'clearfix'] : ['clearfix']

  const entityTags = user.tags.get().filter(_ => entity.tags.has(_.id))
  const theseEntityTags = window.sticky.newPatchableSet(allTags.map(_ => _.id))

  return (
    <StyledComponent>
      <Box className={boxClasses.join(' ')}>
        <div className={`controls-1 count--${controls1ItemCount}`}>
          {/* {entity.canBePaidForLater && entity.isUnpaid && (
            <CoolButton
              color={!entity.isUnpaid ? '#40cf7a' : '#1A1F35'}
              bigIcon
              InlineIcon={entity.isUnpaid ? dashboardIcons.payment : undefined}
              onClick={() => onAction('pay')}
            >
              {entity.isUnpaid ? 'Pay' : 'Paid'}
            </CoolButton>
          )} */}
          {user.tags.get().length > 0 && <CoolButton
            className='cb-tag'
            color='#1A1F35'
            bigIcon
            InlineIcon={dashboardIcons.tags}
            onClick={() => onAction('tags')}
          >
            Labels
          </CoolButton>}
          <Button
            className={classnames('cb-done', { 'is-done': isDone })}
            InlineIcon={dashboardIcons.check}
            isSecondary={isDone}
            bigIcon
            onClick={() => onAction('done')}
          >
            {!isDone ? 'Complete' : 'Uncomplete'}
          </Button>
        </div>
        <div className={`details count--${controls1ItemCount}`}>
          <span className='other-stuff'>
            <TagChooserUser
              user={user}
              tags={entity.tags}
              can={false}
              canManage={false}
              showAll={false}
            />
            <PaymentSummary user={user} payment={entity} showTime={false} />
          </span>
          <PaymentHeadlines user={rdic.user} entity={entity} />
          {entity.rawCart.length > 0 && <>
            <hr />
            <div className='cart'>
              {entity.rawCart
                .filter(ci => {
                  if (filterByProductTags.size === 0) {
                    return true
                  }
                  return ci.product ? ci.product.tags.toArray().some(pTag => filterByProductTags.has(pTag)) : true
                })
                .map((ci, i) => {
                  const m = ci.product && ci.product.media.find(m => m.type === 'image')
                  return (
                    <div
                      className={classnames('cart-item', { 'struck-out': ci.struckOut })}
                      key={i}
                      onClick={() => {
                        ci.struckOut = !ci.struckOut
                        onAction('ciLocalChange')
                      }}
                    >
                      <div className='summary'>
                        <h1
                          className={classnames('product-name', { 'no-image': !m, 'struck-out': ci.struckOut })}
                        >
                          {/* alt={ci.product ? ci.product.name : ''} */}
                          {m && <img src={m.url} alt='' />}
                          <span className='quantity'>× {ci.quantity}</span>
                          <span>{ci.productName || (ci.product ? ci.product.name : 'Unknown product')}</span>
                        </h1>
                      </div>
                      {Array.isArray(ci.questions) && ci.questions.length > 0 && <div className='questions'>
                        {ci.questions
                          .filter(ciq => Array.isArray(ciq.answer) ? ciq.answer.length > 0 : ciq.answer)
                          .map(ciq => {
                            return (
                              <div className='question' key={ciq.question}>
                                {[true, undefined].includes(userPreferences.toDoShowQuestionQuestions) && <><strong>{ciq.question}</strong><br /></>}
                                {Array.isArray(ciq.answer) ? ciq.answer.join(', ') : ciq.answer}
                              </div>
                            )
                          })}
                      </div>}
                      {ci.customNote && <PaymentExtra>
                        <strong>{ci.customNote}</strong>
                      </PaymentExtra>}
                    </div>
                  )
                })}
            </div>
          </>}
          {entity.extra && <PaymentExtra>
            <strong>{entity.extra}</strong>
          </PaymentExtra>}
        </div>
        <div className='controls-2 clearfix'>
          {user.can('has-printer') && <CoolButton
            color='#1A1F35'
            InlineIcon={dashboardIcons.printer}
            title='Print'
            onClick={() => onAction('print')}
          />}
          {[true, undefined].includes(userPreferences.toDoAccessCus) && <CoolButton
            color='#1A1F35'
            inlineIconString={window.sticky.internals.icons.get('person')}
            title='Person'
            onClick={() => onAction('session')}
          />}
          <CoolButton
            color='#1A1F35'
            InlineIcon={dashboardIcons.note}
            title='Edit note'
            onClick={() => onAction('note')}
          />
          {[true, undefined].includes(userPreferences.toDoAccessCus) && <CoolLinkButton color='#1A1F35' InlineIcon={dashboardIcons.expand} to={`/me/payments/${entity.id}`} />}
          {[true, undefined].includes(userPreferences.toDoAssignPayments) && rdic.federatedUsers && rdic.federatedUsers.length > 0 && currentFederatedUser && (
            <>
              <CoolButton
                color='#1A1F35'
                onClick={() => onAction(!entity.lastFederatedUserId ? 'changeFederatedUser' : 'removeFederatedUser')}
              >
                {!entity.lastFederatedUserId ? 'Assign' : 'Release'}
              </CoolButton>
              {pCurrentFederatedUser && <UserCircle fixedWidthHeight={2.5} name={pCurrentFederatedUser.name} color={pCurrentFederatedUser.color} photoUrl={pCurrentFederatedUser.photoUrl} />}
            </>
          )}
        </div>
      </Box>
    </StyledComponent>
  )
}
Card.propTypes = {
  userPreferences: PropTypes.object.isRequired,
  rdic: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  currentFederatedUser: PropTypes.object,
  isDone: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  filterByProductTags: PropTypes.any
}
