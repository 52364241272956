/* eslint-disable react/prop-types */

import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'

import { icons, Button, UploadCsv, Time, Switch, SearchInput } from '@openbox-app-shared'
import _ from '../../_'
import { log } from '../../log'
import ScrollableTable from '../ScrollableTable'
import Box from '../Box'
import H2 from '../H2'
import { dispatch, subscribe } from '../../redux'
import dashboardIcons from '../../icons'

const COLUMNS = {
  id: 'ID',
  identifier: '',
  createdAt: 'First seen',
  updatedAt: 'Last seen'
}

const StyledReport = styled.div`
  .column-debug {
    cursor: pointer;
    font-size: 110%;
    letter-spacing: 1px;
    text-decoration: none;
    code {
      font-weight: bold;
    }
  }
  a.column-debug {
    color: #322CBE;
  }
  .component--button + .component--scrollable-table {
    margin-top: 1rem;
  }
  tbody td .component--button {
    width: unset !important;
  }
  .controls {
    > * {
      display: inline-block;
      vertical-align: top;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
    .component--switch {
      margin-top: 0.25rem;
    }
  }
  .import-payments {
    .component--h2 {
      margin-bottom: 0.5rem;
    }
  }
  .import-payments + * {
    margin-top: 1rem;
  }
  .buttons {
    .component--button {
      display: inline-block;
      vertical-align: top;
      margin: 0 1rem 1rem 0;
    }
  }
  .component--scrollable-table {
    /* actions */
    .component--table td:nth-child(4) {
      ${props => {
    return css`
        min-width: calc(${props.$actionsCount * 1.75}rem + ${(props.$actionsCount - 1) * 0.5}rem);
        max-width: calc(${props.$actionsCount * 1.75}rem + ${(props.$actionsCount - 1) * 0.5}rem);
        `
  }};
    }
  }
`

function ReportChild({ user, query, data, customQuery, setCustomQuery, onRefresh, getData, actions = [], onAction }) {
  const { onlyMeaningful = false, filter = '' } = customQuery
  log('[reports->userSessions] [ReportChild]', { customQuery, query })
  useEffect(
    () => {
      const subscriptions = [
        subscribe(
          'SESSION_UPDATE',
          ({ why }) => {
            why === 'report--userSessions' && onRefresh()
          }
        )
      ]
      return () => {
        subscriptions.forEach(s => s())
      }
    },
    []
  )
  const columns = { ...COLUMNS }
  if (actions.length > 0) {
    columns.actions = ''
  }
  let isThereAnyIds = false

  function onSession (sessionId) {
    dispatch('LOADING')
    window.sticky.session.get(undefined, false, sessionId)
      .then(session => {
        dispatch('SESSION', { session, why: 'report--userSessions' })
        dispatch('STOP_LOADING')
      })
  }

  const rows = data.map(_ => {
    const session = window.sticky.session.createClass(_.session)
    // purely for performance (reducing iterations over `data`)
    if (session.userSector.readFrom('ID')) {
      isThereAnyIds = true
    }
    return {
      id: <a className='column-debug' onClick={() => onSession(_.sessionId)}><code>{session.userSector.readFrom('ID')}</code> →</a>,
      identifier: (
        <>
          <Button
            icon={(session.userSector.readFrom('Verified') || session.userSector.readFrom('stickyVerified')) ? icons.generic.verify : icons.generic.unverify}
            isSecondary
            onClick={() => onSession(_.sessionId)}
          >
            {session.identifierUnique}
          </Button>
        </>
      ),
      createdAt: _.createdAt ? <Time time={_.createdAt} /> : null,
      updatedAt: _.updatedAt ? <Time time={_.updatedAt} /> : null,
      actions: (<>
        {actions.includes('delete') ? <Button
          title='Delete'
          backgroundColor='#ff3838'
          InlineIcon={dashboardIcons.delete} onClick={() => onAction('delete', [_.sessionId])}
        /> : null}
      </>)
    }
  })
  if (!isThereAnyIds) {
    delete columns.id
  }
  return (
    <StyledReport $actionsCount={actions.length}>
      <div className='controls'>
        <SearchInput
          value={filter}
          onChange={_ => {
            setCustomQuery({ onlyMeaningful, filter: _ })
          }}
        />
        <Switch
          checked={onlyMeaningful}
          onChange={onlyMeaningful => {
            setCustomQuery({ onlyMeaningful, filter })
          }}
        >
          Show only people with name/email/phone
        </Switch>
      </div>
      {user.id === 'b9083b36-8a8f-48f7-9190-037ff80ea57f' && <Box className='import-payments'>
        <H2>Import payments</H2>
        <UploadCsv
          resolveLocally={false}
          onChange={({url}) => {
            ;(async () => {
              try {
                dispatch('LOADING', { loadingWhat: 'Processing... please wait!' })
                const response = await window.sticky.importCSVPayments.importCSVPayments({
                  url,
                  applicationId: '197a304d-39a1-4cfb-a24d-274fc046d134'
                })
                  
                await sticky.applications.blocks.showMessage(
                  response,
                  undefined,
                  true,
                  true,
                )
              } catch ({ message }) {
                await window.sticky.applications.blocks.showError(message, true)
              } finally {
                dispatch('STOP_LOADING')
              }
                  
            })()
          }}
        />
      </Box>}
      <div className='buttons'>
        <Button
          InlineIcon={dashboardIcons.add}
          onClick={() => {
            (async () => {
              try {
                const values = await window.sticky.applications.blocks.renderInlineEventsButton(
                  [
                    {
                      'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                      'config': {
                        'label': 'Name',
                        'type': 'Text',
                        'value': '',
                        'priceMin': 0,
                        'priceMax': 50000000
                      }
                    },
                    {
                      'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                      'config': {
                        'label': 'ID (optional reference)',
                        'type': 'Text',
                        'value': '',
                        'priceMin': 0,
                        'priceMax': 50000000
                      }
                    }
                  ],
                  'Add'
                )
                if (!values) {
                  return
                }
                dispatch('LOADING')
                const { sessionId } = await window.sticky.session.create({ crossUserSector: { name: values['Name'] }, userSector: { 'ID': values['ID (optional reference)'] || undefined } })
                const session = await window.sticky.session.get(undefined, false, sessionId)
                dispatch('SESSION', { session })
                onRefresh()
              } catch ({ message }) {
                window.sticky.applications.blocks.showError(message, true)
              } finally {
                dispatch('STOP_LOADING')
              }
            })()
          }}
        >
          Person
        </Button>
      </div>
      <ScrollableTable
        columns={columns}
        rows={rows}
      >

      </ScrollableTable>
    </StyledReport>
  )
}

export default ReportChild
