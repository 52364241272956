import React, { useRef, useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styled, { css } from 'styled-components'

import icons from '../icons'
import Empty from './Empty'
import Link from './Link'
import HotImage from './HotImage'
import Button from './Button'
import InlineHtml from './InlineHtml'
import { Tag } from './Tag'
import { Input } from './Input'

const StyledList = styled.ul`
  border-radius: 6px;
  .component--list {
    margin-left: 1.5rem;
    .component--list-item:first-of-type {
      margin-top: 0.375rem;
    }
  }
  &.empty {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  .component--list + .component--list-item {
    margin-top: 0.5rem;
  }
  .component--list-item + .component--list-item {
    margin-top: 0.5rem;
  }
  ${props => props.$limitHeight ? css`
    overflow: scroll;
    max-height: ${props.$limitHeight}px;
` : ''};
  ${props => props.fixedHeight ? css`
    overflow: scroll;
    height: ${props.fixedHeight}px;
` : ''};
  .show-all {
    width: 100%;
    height: 2rem;
    margin-bottom: 1rem;
    padding: 0rem;
  }
`

const StyledListItem = styled.li`
  position: relative;
  display: block;
  width: 100%;
  height: 2.5rem;
  list-style-type: none;
  padding: 2px;
  border-radius: 6px;
  transition: border-color 0.1s ease-in-out, box-shadow 0.3s ease-in-out;
  outline: 0;
  font-size: 90%;
  cursor: default;
  .right-hand-side {
    width: fit-content;
    float: right;
    margin-top: -9px;
    .tags {
      display: inline-block;
      vertical-align: top;
      height: 2rem;
      margin-top: 3px;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      > * {
        display: block;
        float: left;
      }
      > * + * {
        margin-left: 0.25rem;
      }
      .component--tag:last-child {
        margin-right: 0.5rem;
      }
    }
    .actions {
      display: inline-block;
      vertical-align: top;
      margin-top: 9px;
      margin-right: 0.625rem;
      height: 1.25rem;
      img {
        display: block;
        float: left;
        width: 1.125rem;
        height: 1.125rem;
        margin-left: 0.75rem;
        transition: background-color 0.3s ease-in-out;
        border-radius: 4px;
        &:focus {
          background-color: #cccbf0;
          outline: 0;
        }
      }
      img:first-child {
        margin-left: 0rem;
      }
    }
    .right-hand-side-2 {
      margin-right: 0.5rem;
    }
  }
  ${props => props.$doBoxShadow ? css`
    box-shadow: 0 2px 1px 0 rgb(60 66 87 / 8%), 0 0.5px 0px 0 rgb(0 0 0 / 8%);
  ` : ''}
  &.drag-here {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: linear-gradient(#1A1F35, #1A1F35);
    background-repeat: no-repeat;
    background-size: 2px 100%;
  }
  &:not(.selected):focus-within {
    outline: 0;
    box-shadow: #cccbf0 0px 0px 0px 3px;
    color: inherit;
  }
  &.selected {
    background-color: #A9B7C6;
    color: var(--v2-color-primary, white);
  }
  ${props => props.$doHover ? css`
    &:not(.selected):hover {
      background-color: #F0F1F3;
      color: inherit;
    }
  ` : ''}
  > .icon {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    img, svg {
      display: block;
      margin: 50% auto 0 auto;
      max-width: 100%;
      max-height: 100%;
      transform: translate(0, -50%);
    }
  }
  > a > span p {
    line-height: 1rem;
  }
  > span, > a {
    // background-color: purple;
    position: absolute;
    width: calc(100% - 4px);
    display: block;
    height: calc(2.5rem - 4px);
    color: inherit;
    text-decoration: none;
    > p {
      display: block;
      padding-left: 2.25rem;
      outline: 0;
      > span {
        display: block;
        margin-top: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.25rem;
      }
    }
  }
`

const handleKeypress = (e, fn) => e.key === 'Enter' && fn()

function Action ({ itemId, tabIndex = '0', actionId, handleOnAction, ...props }) {
  return (
    <img
      onClick={(e) => handleOnAction && handleOnAction(e, itemId, actionId)}
      className='action'
      alt=''
      tabIndex={tabIndex}
      onKeyPress={(e) => handleKeypress(e, () => handleOnAction && handleOnAction(e, itemId, actionId))}
      {...props}
    />
  )
}

const actions = {
  'load': (id, handleOnAction) => (<Action title='Load' key='action-load' itemId={id} actionId='load' handleOnAction={handleOnAction} src={icons.generic.actions.load} />),
  'add': (id, handleOnAction) => (<Action title='Add' key='action-add' itemId={id} actionId='add' handleOnAction={handleOnAction} src={icons.generic.actions.add} />),
  'paste': (id, handleOnAction) => (<Action title='Paste' key='action-paste' itemId={id} actionId='paste' handleOnAction={handleOnAction} src={icons.generic.actions.paste} />),
  'go-to': (id, handleOnAction) => (<Action title='Go to' key='action-go-to' itemId={id} actionId='go-to' handleOnAction={handleOnAction} src={icons.generic.actions.goTo} />),
  'move-up': (id, handleOnAction) => (<Action title='Move up' key='action-move-up' onClick={(e) => handleOnAction(e, id, 'move-up')} src={icons.generic.actions.move.up} />),
  'move-down': (id, handleOnAction) => (<Action title='Move down' key='action-move-down' itemId={id} actionId='move-down' handleOnAction={handleOnAction} src={icons.generic.actions.move.down} />),
  'move-upmost': (id, handleOnAction) => (<Action title='Move to top' key='action-move-upmost' onClick={(e) => handleOnAction(e, id, 'move-upmost')} src={icons.generic.actions.move.upmost} />),
  'move-downmost': (id, handleOnAction) => (<Action title='Move to bottom' key='action-move-downmost' itemId={id} actionId='move-downmost' handleOnAction={handleOnAction} src={icons.generic.actions.move.downmost} />),
  'rename': (id, handleOnAction) => (<Action title='Rename' key='action-rename' itemId={id} actionId='rename' handleOnAction={handleOnAction} src={icons.generic.actions.edit} />),
  'edit': (id, handleOnAction) => (<Action title='Edit' key='action-edit' itemId={id} actionId='edit' handleOnAction={handleOnAction} src={icons.generic.actions.edit} />),
  'copy': (id, handleOnAction) => (<Action title='Copy' key='action-copy' itemId={id} actionId='copy' handleOnAction={handleOnAction} src={icons.generic.actions.copy} />),
  'delete': (id, handleOnAction) => (<Action title='Delete' key='action-delete' itemId={id} actionId='delete' handleOnAction={handleOnAction} src={icons.generic.actions.delete} />)
  // 'none': (id, handleOnAction, index) => (<Action tabIndex='-1' title='None' key={`action-none-${index}`} itemId={id} actionId='none' src={icons.generic.actions.none} />)
}

const getAction = (name, id, onAction) => {
  const foundAction = actions[name]
  if (typeof foundAction === 'function') {
    return foundAction(id, onAction)
  }
  return undefined
}

function ListItemRightHandSide ({ tags, actions, rightSideChildren, id, handleOnAction }) {
  if (tags.length === 0 && actions.length === 0 && !rightSideChildren) {
    return
  }
  return (
    <div className='right-hand-side' onClick={(e) => e.preventDefault()}>
      <div className='tags'>
        {rightSideChildren && <div className='right-hand-side-2'>{rightSideChildren}</div>}
        {tags.map((tag, i) => <Tag key={`tag--${i + 1}`} tag={tag} />)}
      </div>
      <div className='actions'>
        {actions.map(a => getAction(a, id, handleOnAction))}
      </div>
  </div>
  )
}

export function ListItem ({ doHover = true, doBoxShadow = false, goTo, id, icon, InlineIcon, iconStyle = {}, style = {}, selected, actions, tags, children, rightSideChildren, onChoose, onAction, indentationInner, indentationOuter, disabled = false, draggable, tagBuffer, ...rest }) {
  const handleOnAction = (e, id, action) => {
    e.stopPropagation()
    e.preventDefault()
    onAction(id, action)
  }
  const finalIconStyle = {
    ...iconStyle,
    marginLeft: indentationInner > 0 ? `${indentationInner}px` : undefined
  }
  const props = {
    tabIndex: !goTo ? 0 : undefined,
    onKeyPress: !goTo ? (e) => handleKeypress(e, () => onChoose(id)) : undefined
  }
  const outerStyle = {
    ...style,
    opacity: disabled ? 0.5 : style.opacity,
    marginLeft: indentationOuter > 0 ? `${indentationOuter}px` : undefined,
    width: `calc(100% - ${indentationOuter}px)`
  }
  const innerStyle = {
    marginLeft: indentationInner > 0 ? `${indentationInner}px` : undefined
  }
  const canGoTo = !disabled
  const liRhsProps = {
    tags,
    actions,
    rightSideChildren,
    id,
    handleOnAction
  }
  return (
    <StyledListItem
      onClick={() => {
        canGoTo && onChoose(id)
      }}
      className={classnames('component--list-item', { selected })}
      {...props}
      style={outerStyle}
      id={id}
      $doHover={doHover}
      $doBoxShadow={doBoxShadow}
      draggable={draggable ? true : undefined}
      {...draggable}
    >
      {goTo && canGoTo && <Link draggable={false} to={goTo} {...rest}><p><ListItemRightHandSide {...liRhsProps} /><span style={innerStyle}>{children}</span></p></Link>}
      {(!goTo || !canGoTo) && <span><p><ListItemRightHandSide {...liRhsProps} /><span style={innerStyle}>{children}</span></p></span>}
      {(icon || InlineIcon) && <div className='icon'>
        {icon.startsWith('/') && <img src={icon} style={finalIconStyle} alt='' />}
        {icon.startsWith('http') && <HotImage src={icon} style={finalIconStyle} alt='' />}
        {icon.startsWith('<') && <InlineHtml html={icon} />}
        {InlineIcon && <InlineIcon />}
      </div>}
    </StyledListItem>
  )
}
ListItem.propTypes = {
  doHover: PropTypes.bool,
  doBoxShadow: PropTypes.bool,
  children: PropTypes.node,
  rightSideChildren: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  actions: PropTypes.array,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.any.isRequired,
      color: PropTypes.string
    })
  ),
  onChoose: PropTypes.func,
  onAction: PropTypes.func,
  indentationInner: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  indentationOuter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  iconStyle: PropTypes.object,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  tagBuffer: PropTypes.bool
}
ListItem.defaultProps = {
  icon: '',
  selected: false,
  actions: [],
  tags: [],
  onChoose: () => {},
  onAction: () => {},
  indentationInner: 0,
  indentationOuter: 0
}

function swapArrayElements (arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}

export function List ({ draggable = false, onDrag, limitHeight, fixedHeight, children, emptyText, className }) {
  const hasChildren = (typeof children === 'object' && children.props) || (Array.isArray(children) && children.length > 0)
  const howManyChildren = Array.isArray(children) ? children.length : 0
  const isEmpty = !hasChildren && emptyText !== null
  const classNames = classnames('component--list', className, { empty: isEmpty })
  const refList = useRef()
  const [isShowingAll, setIsShowingAll] = useState(false)
  let draggingI
  function binOff () {
    Array.from(refList.current.querySelectorAll('li')).forEach(el => {
      el.classList.remove('drag-here')
    })
  }
  return (
    <StyledList ref={refList} $limitHeight={!isShowingAll ? limitHeight : undefined} fixedHeight={fixedHeight} className={classNames}>
      {limitHeight && howManyChildren > 8 && (
        <Button
          className='show-all'
          onClick={() => setIsShowingAll(!isShowingAll)}
        >
          {isShowingAll && '↑ Hide all'}
          {!isShowingAll && '↓ Show all'}
        </Button>
      )}
      {isEmpty && <Empty>{emptyText}</Empty>}
      {React.Children.map(children, (child, i) => {
        const extraProps = {
          draggable: draggable ? {
            onDragStart: e => {
              e.dataTransfer.effectAllowed = 'copyMove'
            },
            onDragStartCapture: e => {
              draggingI = i
            },
            onDragEndCapture: () => {
              draggingI = undefined
              binOff()
            },
            onDragOverCapture: e => {
              e.preventDefault()
              const t = (e.nativeEvent.path || (e.nativeEvent.composedPath && e.nativeEvent.composedPath())).find(el => el.nodeName === 'LI' && el.classList.contains('component--list-item'))
              if (!t) {
                return
              }
              binOff()
              t.classList.add('drag-here')
              t.focus()
            },
            onDropCapture: e => {
              e.preventDefault()
              binOff()
              onDrag && draggingI !== i && onDrag(draggingI, i)
            }
          } : undefined
        }
        return cloneElement(
          child,
          extraProps
        )
      })}
    </StyledList>
  )
}
List.propTypes = {
  draggable: PropTypes.bool,
  onDrag: PropTypes.func,
  limitHeight: PropTypes.number,
  fixedHeight: PropTypes.number,
  emptyText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
}

const StyledPatchableSetList = styled.div`
  .psl-buttons {
    margin-top: 0.5rem;
    > * {
      display: inline-block;
      vertical-align: top;
      margin: 0 0.5rem 0 0;
      font-size: 80%;
    }
    .component--button {
      padding: 0 0.4rem 0 0.4rem;
    }
    .component--input {
      > input {
        padding: 0 0.4rem 0 0.4rem;
        height: 1.75rem;
        border: 2px solid #f0f1f3;
      }
    }
  }
`
export function PatchableSetList ({ all, set, filter, setFilter, onUpdate, draggable = false, canSelectAllNone = true, ...props }) {
  const allHas = Array.from(set)
    .map(as => all.find(i => i.id === as))
    .filter(as => as)
  const allNotHas = all.filter(i => !set.has(i.id))
  const allFinal = [...allHas, ...allNotHas]
  // console.log('[PatchableSetList]', { all, set, allHas, allNotHas, allFinal })

  return (
    <StyledPatchableSetList className='component--patchable'>
      <List
        {...props}
        draggable={draggable}
        onDrag={draggable ? (from, to) => {
          const newArray = Array.from(set)
          if (from >= newArray.length) {
            // dragging an unchecked item
            return
          }
          set.clear()
          swapArrayElements(newArray, from, to)
          newArray.forEach(nae => set.add(nae))
          onUpdate && onUpdate(set)
        } : undefined}
      >
        {allFinal.map(a => {
          const has = set.has(a.id)
          return (
            <ListItem
              icon={has ? icons.generic.check : icons.generic.uncheck}
              key={`patchable-list--${a.id}`}
              id={`patchable-list--${a.id}`}
              tags={a.tags}
              onChoose={() => {
                set.toggle(a.id)
                if (!draggable) {
                  const backupSet = new set.constructor([...set])
                  set.clear()
                  set.append(all.map(_ => _.id).filter(_ => backupSet.has(_)))
                }
                onUpdate && onUpdate(set)
              }}
            >
              {a.name}
            </ListItem>
          )
        })}
      </List>
      {canSelectAllNone && <div className='psl-buttons'>
        <Button
          isSecondary
          onClick={() => {
            set.clear()
            all.forEach(a => {
              set.add(a.id)
            })
            onUpdate && onUpdate(set)
          }}
        >Select all</Button>
        <Button
          isSecondary
          onClick={() => {
            set.clear()
            onUpdate && onUpdate(set)
          }}
        >Select none</Button>
        {typeof filter === 'string' && <Input
          placeholder='Search...'
          onChange={v => setFilter && setFilter(v)}
          value={filter}
        />}
      </div>}
    </StyledPatchableSetList>
  )
}
PatchableSetList.propTypes = {
  label: PropTypes.string,
  all: PropTypes.arrayOf(PropTypes.object),
  set: PropTypes.instanceOf(Set),
  onUpdate: PropTypes.func,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  draggable: PropTypes.bool,
  canSelectAllNone: PropTypes.bool
}

