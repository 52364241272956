/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { icons, Table, Button, InlineHtml } from '@openbox-app-shared'
import SerializableValue from './SerializableValue'
import dashboardIcons from '../icons'

const StyledComponent = styled.div`
  .component--table, .component--empty {
    margin-top: 0.5rem;
    table-layout: fixed;
    thead th:nth-child(3), tbody td:nth-child(3) {
      width: 5.5rem;
    }
  }
  .component--table {
    width: 100%;
  }
  .kvp-buttons {
    margin-top: 1rem;
    > * {
      display: inline-block;
      margin: 0 1rem 1rem 0;
      vertical-align: top;
    }
    > *:last-child {
      margin-right: 0;
    }
  }
  hr {
    margin-top: 0;
  }
  .component--serializable-value {
    margin-top: 1rem;
  }
  .component--table ul {
    padding-left: 1rem;
    li + li {
      margin-top: 1rem;
    }
  }
`

function Value ({ user, whichKey, whichValue }) {
  const asDeserialized = window.sticky.getDeserializedValue(whichValue, user, true, whichKey)
  return asDeserialized !== undefined ? <InlineHtml html={asDeserialized} /> : whichValue
}

export default function KeyValuePairs ({ user, object, onUpdate, onAction, canUpdate = true, actions = [], excludeKeys = [], newDefaults = ['Song', 'Sussudio'], actionText = 'Remember', emptyText }) {
  const [newDefaultName, newDefaultValue] = newDefaults

  const [toBeAdded, setToBeAdded] = useState()
  const COLUMNS = {
    name: '',
    value: ''
  }
  if (canUpdate) {
    COLUMNS.actions = ''
  }

  const keys = Object.keys(object)
    .filter(k => !excludeKeys.includes(k))

  const rows = keys.map(ok => {
    const value = object[ok]
    return {
      name: ok,
      value: <Value user={user} whichKey={ok} whichValue={value} />,
      actions: (
        canUpdate ? <>
          <Button
            isSecondary
            icon={icons.generic.edit}
            title='Edit'
            onClick={() => {
              setToBeAdded({ name: ok, value, whichToAutoFocus: 'value' })
            }}
          />
          <Button
            title='Delete'
            backgroundColor='#ff3838'
            InlineIcon={dashboardIcons.delete}
            onClick={() => {
              onUpdate({ name: ok, value: null })
            }}
          />
        </> : <></>
      )
    }
  })
  return (
    <StyledComponent className='component--key-value-pairs'>
      {canUpdate && !toBeAdded && (
        <div className='kvp-buttons'>
          {actions.includes('id') && <Button onClick={() => onAction('id')} InlineIcon={dashboardIcons.teamMember}>Set ID</Button>}
          {actions.includes('verify') && <Button backgroundColor='#40cf79' onClick={() => onAction('verify')} InlineIcon={dashboardIcons.verify}>Verify</Button>}
          {actions.includes('unverify') && <Button backgroundColor='#ff3838' onClick={() => onAction('unverify')} InlineIcon={dashboardIcons.verify}>Unverify</Button>}
          {actions.includes('executable-codes-create--StickyBalance') && <Button
            InlineIcon={dashboardIcons.add}
            backgroundColor='#218c74'
            onClick={() => onAction('executable-codes-create--StickyBalance')}
          >
            Top up
          </Button>}
          {actions.includes('executable-codes-create--StickyDiscount') && <Button
            InlineIcon={dashboardIcons.add}
            backgroundColor='#fd7272'
            onClick={() => onAction('executable-codes-create--StickyDiscount')}
          >
            Permanent discount
          </Button>}
          <Button isSecondary onClick={() => setToBeAdded({ name: newDefaultName, value: object['name'] || newDefaultValue, whichToAutoFocus: 'name' })} InlineIcon={dashboardIcons.add}>{actionText}</Button>
        </div>
      )}
      {canUpdate && toBeAdded && (
        <>
          <SerializableValue
            name={toBeAdded.name}
            value={toBeAdded.value}
            onUpdate={(name, value) => setToBeAdded({ name, value })}
            onDone={(name, value) => {
              toBeAdded.name = name
              toBeAdded.value = value
              setToBeAdded(undefined)
              onUpdate(toBeAdded)
            }}
            whichToAutoFocus={toBeAdded.whichToAutoFocus}
            onCancel={() => setToBeAdded(undefined)}
          />
          <hr />
        </>
      )}
      <Table
        columns={COLUMNS}
        rows={rows}
        emptyText={emptyText}
      />
    </StyledComponent>
  )
}
KeyValuePairs.propTypes = {
  object: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  canUpdate: PropTypes.bool,
  excludeKeys: PropTypes.arrayOf(PropTypes.string),
  actionText: PropTypes.string,
  emptyText: PropTypes.string
}
