/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Empty, Link, Time, Price, LinkButton } from '@openbox-app-shared'
import ScrollableTable from '../ScrollableTable'
import FederatedUser from '../FederatedUser'

const StyledComponent = styled.div`
  .component--table tr > td:first-child > strong {
    display: block;
  }
  .component--table tr > td:first-child > strong + strong {
    margin-top: 0.5rem;
  }
  .column-debug {
    display: block;
    font-size: 110%;
    letter-spacing: 1px;
    text-decoration: none;
    code {
      font-weight: bold;
    }
  }
  a.column-debug {
    color: #322CBE;
  }
  .component--table td a {
    text-decoration: none;
  }
  .component--table td:nth-child(4), .component--table td:nth-child(5) {
    padding-bottom: 0.5rem;
    .component--button {
      display: inline-block;
      vertical-align: top;
      margin: 0 0.5rem 0.5rem 0;
      width: auto !important;
      height: auto !important;
      button {
        padding: 0.25rem;
      }
      &.thing img {
        width: 15px;
      }
    }
  }
  .component--table tr > :nth-child(1) > * + * {
    margin-top: 0.5rem;
  }
  .component--table tr > :nth-child(6) > * + * {
    white-space: nowrap;
  }
`

const COLUMNS = {
  link: 'Name',
  total: 'Total',
  time: 'Paid at',
  application: 'Flow',
  thing: 'Sticky',
  federatedUser: 'Team member',
  theirId: 'Reference',
  tip: 'Service'
}

export default function EventsTable_SESSION_CART_PAY ({ user, events, things, applications, federatedUsers }) {
  if (events.length === 0) {
    return (
      <Empty />
    )
  }

  const rows = events.map(e => {
    const foundThing = e.thingId && things.find(t => t.id === e.thingId)
    const foundApplication = e.applicationId && applications.find(t => t.id === e.applicationId)
    const foundFederatedUser = e.federatedUserId && federatedUsers.find(_ => _.id === e.federatedUserId)
    return {
      link: e.paymentId && <>
        {e.extra.payment.userPaymentId && <strong><code>{e.extra.payment.userPaymentId}</code></strong>}
        {e.extra.payment.name && <strong>{e.extra.payment.name}</strong>}
        <Link className='column-debug' to={`/me/payments/${e.paymentId}`}><code>#{e.paymentId.substring(0, 8).toUpperCase()}</code> →</Link>
      </>,
      theirId: <code>{e.extra.payment.userPaymentId}</code>,
      total: <Price price={e.customData.readFrom('total')} currency={e.customData.readFrom('currency')} />,
      time: <Time showTimeAsFixed time={e.createdAt} isByDay />,
      application: foundApplication ? <LinkButton disabled={!(user.federatedUserCan('applications') && user.federatedUserCan('applications-advanced'))} isSecondary sameTab to={`/me/flows/${foundApplication.id}`} icon={foundApplication.baseIcon}>{foundApplication.name}</LinkButton> : undefined,
      thing: foundThing ? <LinkButton disabled={!user.federatedUserCan('things')} isSecondary className='thing' sameTab to={`/me/stickies/${foundThing.id}`} icon={foundThing.designUrl}>{foundThing.name}</LinkButton> : undefined,
      federatedUser: foundFederatedUser ? <Link disabled={!user.federatedUserCan('master')} to={`/me/team/${e.federatedUserId}`}><FederatedUser federatedUser={foundFederatedUser} /></Link> : null,
      tip: <Price price={e.customData.readFrom('tip')} currency={e.customData.readFrom('currency')} />
    }
  })

  const columns = {
    ...COLUMNS
  }
  if (!events.some(e => e.extra.payment.userPaymentId)) {
    delete columns.theirId
  }
  if (!events.some(e => e.federatedUserId)) {
    delete columns.federatedUser
  }
  if (events.every(e => e.customData.readFrom('tip') === 0)) {
    delete columns.tip
  }

  return (
    <StyledComponent className='component--events'>
      <ScrollableTable
        rows={rows}
        columns={columns}
      />
    </StyledComponent>
  )
}
EventsTable_SESSION_CART_PAY.propTypes = {
  events: PropTypes.array
}
