import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styled, { css } from 'styled-components'

const KEY_TAB = 'Tab'
const KEY_ESC = 'Escape'

const StyledModal = styled.div`
  z-index: 8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(0.5rem);
  .component--modal {
    width: calc(100% - 1.5rem);
    overflow: scroll;
    max-height: calc(100% - 1.5rem);
    max-width: 320px;
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    padding: 2rem;
    &.can-be-closed {
      padding-top: 4rem;
    }
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 6px;
    > p, > .component--h1 {
      color: var(--v2-color-on-white, #1A1F35);
    }
    > .icon--close {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 2.5rem;
      height: 2.5rem;
      background-color: transparent;
      transition: color 0.1s ease-in-out;
      outline: 0;
      ${props => css`
        color: var(--v2-color-on-white, ${props.$closeColor || '#1A1F35'});
      `}
      svg {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        margin: 0.5rem;
      }
    }
  }
`

class Modal extends Component {
  constructor (props) {
    super(props)
    this.handleGlobalKeyDown = this.handleGlobalKeyDown.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.references = {
      dialog: createRef()
    }
  }

  handleClose () {
    const { canBeClosed, onClose } = this.props
    console.log('[Modal] [handleClose]', { canBeClosed, onClose })
    canBeClosed && onClose()
  }

  getFocusableElements () {
    // this ref is null when when the component is shallow in enzyme
    // using mount makes the snapshots very big as the whole document
    // is snapshotted... the difference is an order of magnitude
    if (this.references.dialog.current === null) {
      return []
    }
    return Array.prototype.slice.call(
      this.references.dialog.current.querySelectorAll(
        'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]):not(.icon--close), details summary, [tabindex="0"]'
      )
    )
  }

  componentDidMount () {
    if (typeof window === 'undefined') return
    this.focusedElementBeforeOpening = document.activeElement
    document.body.addEventListener('keydown', this.handleGlobalKeyDown)
    const focusableElements = this.getFocusableElements()
    if (this.props.autoFocus && focusableElements.length > 0 && window.sticky.shouldAutoFocus()) {
      focusableElements[0].focus()
    }
  }

  componentWillUnmount () {
    document.body.removeEventListener('keydown', this.handleGlobalKeyDown)
    this.focusedElementBeforeOpening && this.focusedElementBeforeOpening.focus()
  }

  handleGlobalKeyDown (e) {
    // console.warn('[handleGlobalKeyDown]', e)
    switch (e.key) {
    case KEY_ESC:
      this.handleClose()
      break
    default:
    }
  }

  handleKeyDown (e) {
    // console.warn('[handleKeyDown]', e)
    const focusableElements = this.getFocusableElements()
    const activeElementIndex = focusableElements.indexOf(document.activeElement)
    const handleBackwardTab = () => {
      e.preventDefault()
      if (activeElementIndex > 0) {
        focusableElements[activeElementIndex - 1].focus()
      } else {
        focusableElements[focusableElements.length - 1].focus()
      }
    }
    const handleForwardTab = () => {
      e.preventDefault()
      if (activeElementIndex < focusableElements.length - 1) {
        focusableElements[activeElementIndex + 1].focus()
      } else {
        focusableElements[0].focus()
      }
    }
    switch (e.key) {
    case KEY_TAB:
      if (activeElementIndex === -1 || focusableElements.length === 1) {
        e.preventDefault()
        return
      }
      if (e.shiftKey) {
        handleBackwardTab()
      } else {
        handleForwardTab()
      }
      break
    }
  }

  render () {
    const { style, outerStyle, closeColor, canBeClosed, className, children } = this.props
    return (
      <StyledModal
        role="presentation"
        $closeColor={closeColor}
        style={outerStyle}
      >
        <div
          role="dialog"
          style={style}
          aria-modal="true"
          className={classnames('component--modal', className, { 'can-be-closed': canBeClosed })}
          ref={this.references.dialog}
          onKeyDown={this.handleKeyDown}
        >
          {children}
          {canBeClosed && (
            <button
              onClick={this.handleClose}
              title='Close'
              className='icon--close'
            >
              <svg fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeWidth="3" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m18 6-12 12"/><path d="m6 6 12 12"/></svg>
            </button>
          )}
        </div>
      </StyledModal>
    )
  }
}

Modal.propTypes = {
  canBeClosed: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  autoFocus: PropTypes.bool,
  closeColor: PropTypes.string,
  style: PropTypes.object,
  outerStyle: PropTypes.object
}
Modal.defaultProps = {
  canBeClosed: true,
  onClose: () => {},
  className: '',
  autoFocus: true
}

export default Modal
