import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Modal, Button, SearchInput } from '@openbox-app-shared'
import H1 from '../../../components/H1'
import SideChooser from '../../../components/SideChooser'
import _ from '../../../_'
import dashboardIcons from '../../../icons'

function CategoryChild({ bases, selectedIndices, setSelectedIndices, onChoose }) {
  return (
    <div className='buttons'>
      {bases.map(base => {
        const mediaImages = base.copyJson.media.filter(m => m.type === 'image')
        const isSelected = (selectedIndices || []).find(_ => _.id === base.id)
        return (
          <Button
            key={base.id}
            icon={mediaImages.length > 0 ? mediaImages[0].url : undefined}
            bigIcon
            isSecondary
            turnedOn={isSelected ? '#a55eea' : false}
            onClick={() => {
              Array.isArray(selectedIndices) && (() => {
                isSelected && setSelectedIndices([
                  ...selectedIndices.filter(_ => _.id !== base.id)
                ])
                !isSelected && setSelectedIndices([
                  ...selectedIndices,
                  base
                ])
              })()
              !Array.isArray(selectedIndices) && onChoose(base)
            }}
          >
            {base.copyJson.name}
            {/* {typeof base.copyJson.price === 'number' && <>
              <br />
              <Price price={base.copyJson.price} currency={base.copyJson.currency} />
            </>} */}
          </Button>
        )
      })}
    </div>
  )
}
CategoryChild.propTypes = {
  bases: PropTypes.array,
  selectedIndices: PropTypes.array,
  setSelectedIndices: PropTypes.func,
  onChoose: PropTypes.func
}

function getCategory(selectedIndices, setSelectedIndices, categoryId, baseFilter, name, bases, onChoose) {
  const filteredBases = bases.filter(baseFilter)
  return filteredBases.length > 0 ? {
    id: categoryId,
    name,
    children: (<>
      <div className='buttons-top'>
        {!Array.isArray(selectedIndices) && <Button
          title='Select'
          InlineIcon={dashboardIcons.select}
          isSecondary
          onClick={() => setSelectedIndices([])}
        >
          Select
        </Button>}
        {Array.isArray(selectedIndices) && (
          <>
            {selectedIndices.length > 0 && (
              <Button
                className='openbox--pulsing-1'
                backgroundColor='#a55eea'
                onClick={() => onChoose(selectedIndices)}
                InlineIcon={dashboardIcons.add}
              >
                Add to category
              </Button>
            )}
            <Button
              onClick={() => {
                const newIndices = [
                  ...filteredBases
                ]
                setSelectedIndices(newIndices)
              }}
              isSecondary
              InlineIcon={dashboardIcons.selectAll}
            >
              Select all
            </Button>
            <Button
              onClick={() => {
                setSelectedIndices(undefined)
              }}
              isSecondary
            >
              Cancel
            </Button>
          </>
        )}
      </div>
      <CategoryChild bases={filteredBases} selectedIndices={selectedIndices} setSelectedIndices={setSelectedIndices} onChoose={chosenBase => onChoose([chosenBase])} />
    </>)
  } : undefined
}

const StyledModal = styled.div`
  .component--modal {
    width: calc(100vw - 2rem);
    max-width: 690px;
    padding-bottom: 1rem;
    padding-right: 1rem;
    .component--side-chooser {
      margin-top: 1rem;
    }
    .component--input.search {
      max-width: 16rem;
      margin: 0 auto 2rem auto;
    }
  }
  .component--h1, .component--list {
    margin-right: 1rem;
  }
  .component--h1 {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .buttons-top {
    .component--button {
      display: inline-block;
      vertical-align: top;
      margin: 0 1rem 1rem 0;
    }
  }
  .buttons {
    .component--button {
      display: inline-block;
      vertical-align: top;
      width: 8rem;
      height: 8rem;
      margin: 0 1rem 1rem 0;
      padding: 0.125rem 0 0.125rem 0;
      font-size: 95%;
      strong {
        line-height: 1.2rem;
      }
      .component--price {
        line-height: 1.5rem;
        opacity: 0.5;
      }
    }
  }
`

export default {
  trigger: async ({ setState, productCategory, why }) => {
    const bases = await window.sticky.products.bases.getAll()
    setState({
      NEW_BASE_PRODUCT: {
        bases,
        productCategory,
        why
      }
    })
  },
  render: function ThisAction({ user, state, dispatch, setState }) {
    const [filter, setFilter] = useState('')
    const [selectedIndices, setSelectedIndices] = useState()
    const { NEW_BASE_PRODUCT } = state
    if (typeof NEW_BASE_PRODUCT !== 'object') {
      return null
    }
    const { bases, productCategory, why } = NEW_BASE_PRODUCT

    function cleanUp() {
      setFilter('')
      setSelectedIndices(undefined)
      setState({ NEW_BASE_PRODUCT: undefined })
    }

    const onChoose = (chosenBases) => {
      cleanUp()
      dispatch('NEW_BASE_PRODUCT_GOOD', { productCategory, why, bases: chosenBases })
    }

    const categories = [
      getCategory(selectedIndices, setSelectedIndices, 'partner', base => base.doesMatchFilter(filter) && base.category === 'partner', user.partner ? `From ${user.partner.name}` : 'From my partner', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'wet--soft', base => base.doesMatchFilter(filter) && base.category === 'wet--soft', 'Drinks', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'snacks', base => base.doesMatchFilter(filter) && base.category === 'snacks', 'Snacks', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'amounts', base => base.doesMatchFilter(filter) && base.category === 'amounts', 'Amounts', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'wet--alcohol--beer', base => base.doesMatchFilter(filter) && base.category === 'wet--alcohol--beer', 'Alcohol → Beer', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'wet--alcohol--spirits--gin', base => base.doesMatchFilter(filter) && base.category === 'wet--alcohol--spirits--gin', 'Alcohol → Gin', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'wet--alcohol--spirits--whisky', base => base.doesMatchFilter(filter) && base.category === 'wet--alcohol--spirits--whisky', 'Alcohol → Whisky', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'wet--alcohol--spirits--brandy', base => base.doesMatchFilter(filter) && base.category === 'wet--alcohol--spirits--brandy', 'Alcohol → Brandy', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'wet--alcohol--spirits--liqueur', base => base.doesMatchFilter(filter) && base.category === 'wet--alcohol--spirits--liqueur', 'Alcohol → Liqueur', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'wet--alcohol--vodka', base => base.doesMatchFilter(filter) && base.category === 'wet--alcohol--vodka', 'Alcohol → Vodka', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'wet--alcohol--rum', base => base.doesMatchFilter(filter) && base.category === 'wet--alcohol--rum', 'Alcohol → Rum', bases, onChoose),
      getCategory(selectedIndices, setSelectedIndices, 'cafe', base => base.doesMatchFilter(filter) && base.category === 'cafe', 'Café food', bases, onChoose),
      // getCategory(selectedIndices, setSelectedIndices, 'dry--hot--food', base => base.doesMatchFilter(filter) && base.category === 'dry--hot--food', 'Hot Food', bases, onChoose),
      // getCategory(selectedIndices, setSelectedIndices, 'dry--hot--food--sides', base => base.doesMatchFilter(filter) && base.category === 'dry--hot--food--sides', 'Hot Food → Sides', bases, onChoose),
      // getCategory(selectedIndices, setSelectedIndices, 'indian--sundries', base => base.doesMatchFilter(filter) && base.category === 'indian--sundries', 'Indian → Sundries', bases, onChoose),
      // getCategory(selectedIndices, setSelectedIndices, 'indian--sides', base => base.doesMatchFilter(filter) && base.category === 'indian--sides', 'Indian → Sides', bases, onChoose),
      // getCategory(selectedIndices, setSelectedIndices, 'indian--mains', base => base.doesMatchFilter(filter) && base.category === 'indian--mains', 'Indian → Mains', bases, onChoose),
      // getCategory(selectedIndices, setSelectedIndices, 'indian--starters', base => base.doesMatchFilter(filter) && base.category === 'indian--starters', 'Indian → Starters', bases, onChoose),
      // getCategory(selectedIndices, setSelectedIndices, 'hot--drinks', base => base.doesMatchFilter(filter) && base.category === 'hot--drinks', 'Hot drinks', bases, onChoose),
      // getCategory(selectedIndices, setSelectedIndices, 'alcohol--other', base => base.doesMatchFilter(filter) && base.category === 'alcohol--other', 'Alcohol → Other', bases, onChoose),
      // getCategory(selectedIndices, setSelectedIndices, 'all', base => base.doesMatchFilter(filter) && !base.category, 'More', bases, onChoose)
    ]
      .filter(c => c)
    return (
      <StyledModal>
        <Modal
          onClose={cleanUp}
        >
          <H1>Choose a product</H1>
          <SearchInput
            onChange={setFilter}
            autoFocus
          />
          <SideChooser
            items={categories}
          />
        </Modal>
      </StyledModal>
    )
  }
}
