/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styled from 'styled-components'
import InlineHtml from './InlineHtml'
import { List, ListItem } from './List'

const style = () => `
  position: relative;
  display: block;
  padding: 0.375rem 0.675rem 0.375rem 0.675rem;
  text-decoration: none;
  cursor: default;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, opacity 0.5s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: var(--v2-color-button-secondary, #1A1F35);
  border: 2px solid var(--v2-color-button-secondary, #1A1F35);
  color: var(--v2-color-button-primary, white);
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  :not([disabled]):hover {
    // background-color: green;
  }
  &:focus {
    box-shadow: 0 2px 4px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2), #cccbf0 0px 0px 0px 3px;
    outline: 0;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  > img, > svg {
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 0 auto 0 auto;
    // background-color: pink;
  }
  > strong {
    display: block;
    min-height: 1.5rem;
    line-height: 1.5rem;
    // background-color: orange;
  }
  &.icon-only.has-icon:not(.big-icon) {
    width: 2.5rem;
  }
  &.has-icon {
    padding: 0.5rem;
    > img, > svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  &.is-secondary {
    background-color: var(--v2-color-button-primary, #F4F5F5);
    border-color: var(--v2-color-button-primary, #F4F5F5);
    color: var(--v2-color-button-secondary, #1A1F35);
  }
  &.is-thin {
    font-size: 90%;
    margin-top: 0.25rem;
    height: 1.5rem;
    padding: 0 0.4rem 0 0.4rem;
    img, svg {
      width: 1rem;
      height: 1rem;
    }
    strong {
      line-height: 1rem !important;
      min-height: 1rem !important;
    }
  }
  &.has-icon:not(.big-icon) {
    > img, > svg {
      display: inline-block;
      vertical-align: top;
    }
    > strong {
      display: inline-block;
      vertical-align: top;
      min-height: 1.25rem;
      line-height: 1.25rem;
    }
    > img + strong, > svg + strong {
      margin-left: 0.5rem;
    }
    > strong + img, > strong + svg, > strong + div + img, > strong + div + svg {
      margin-left: 0.5rem;
    }
  }
  &.has-icon.big-icon {
    > img, > svg {
      width: 2.5rem;
      height: 2.5rem;
      margin-bottom: 0.5rem;
    }
    > strong {
      min-height: 1.25rem;
      line-height: 1.25rem;
    }
  }
  .component--button-dropdown-container {
    display: none;
    z-index: 5;
    background-color: white;
    position: absolute;
    top: calc(2.5rem + 4px);
    left: -2px;
    padding: 0.5rem;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    color: #1A1F35;
  }
`

const StyledButton = styled.button(style)

const Button = ({ backgroundColor, color, isSecondary = false, isThin = false, onClick, icon, InlineIcon, inlineIconString, bigIcon = false, hint = '', disabled = false, className, style = {}, children, turnedOn = false, listItems = [], DropdownElement, dropdownWidth = 192, onReady, iconToRight = false, ...props }) => {
  const ref = useRef()
  let timeout
  let [listItemsOpen, setListItemsOpen] = useState()

  const hasChildren = Array.isArray(children) ? children.some(c => c) : children
  const hasDropdown = listItems.length > 0 || DropdownElement

  function doCollapse () {
    setListItemsOpen(false)
  }
  function setDisplay (display) {
    ref.current.style.display = display
  }

  useEffect(
    () => {
      const e = ref.current
      const nav = e.querySelector('.component--button-dropdown-container')
      if ((typeof listItemsOpen !== 'boolean' && !DropdownElement) || !e || !nav) {
        onReady && onReady({
          setDisplay
        })
        return
      }
      const rectDocument = document.body.getBoundingClientRect()
      const rectRef = e.getBoundingClientRect()
      const bumpRight = rectDocument.width - rectRef.left < dropdownWidth
      const bumpRightLeft = Math.floor(-dropdownWidth + rectRef.width - 2)
      nav.style.width = `${dropdownWidth}px`
      nav.style.left = bumpRight ? `${bumpRightLeft}px` : '-2px'
      if (listItemsOpen || nav.dataset.componentButtonDropdownContainerOpen === 'false') {
        Array.from(document.querySelectorAll('.component--button-dropdown-container')).forEach(e => {
          e.dataset.componentButtonDropdownContainerOpen = 'false'
          e.style.display = 'none'
        })
        nav.dataset.componentButtonDropdownContainerOpen = 'true'
        nav.style.display = 'block'
      } else {
        nav.dataset.componentButtonDropdownContainerOpen = 'false'
        nav.style.display = 'none'
      }
      hasDropdown && onReady && onReady({
        doCollapse
      })
      return () => {
        clearTimeout(timeout)
      }
    },
    [
      listItemsOpen
    ]
  )
  const thisProps = {
    disabled,
    title: hint,
    onClick: e => {
      const isClickNotInDropdown = [
        ['IMG', 'SVG', 'RECT', 'PATH', 'STRONG', 'SPAN', 'CIRCLE', 'DIV'].includes(e.target.nodeName.toUpperCase()),
        e.target.classList.contains('component--button'),
      ].some(_ => _) && !e.target.classList.contains('in-dropdown')
      isClickNotInDropdown && onClick && onClick(listItemsOpen)
      if (hasDropdown && isClickNotInDropdown) {
        setListItemsOpen(!listItemsOpen)
      }
    },
    onKeyUp: e => {
      e.code === 'Space' && e.preventDefault()
    },
    className: classnames(className !== null ? 'component--button' : undefined, className, { 'is-secondary': isSecondary, 'is-thin': isThin, 'icon-only': icon && !hasChildren, 'has-icon': icon || InlineIcon || inlineIconString, 'big-icon': bigIcon, 'has-dropdown': hasDropdown }),
    color,
    style: {
      backgroundColor,
      borderColor: turnedOn ? turnedOn : backgroundColor,
      color,
      ...style
    },
    ref,
    ...props
  }
  return (
    <StyledButton {...thisProps}>
      {iconToRight && hasChildren && <strong>{children}</strong>}
      {icon && <img src={icon} alt={hint} />}
      {inlineIconString && <InlineHtml html={inlineIconString} />}
      {InlineIcon && <InlineIcon />}
      {!iconToRight && hasChildren && <strong>{children}</strong>}
      {listItems.length > 0 && (
        <nav className='component--button-dropdown-container'>
          <List>
            {listItems.map(li => {
              const { name, id, ...liProps } = li
              return <ListItem key={id} {...liProps}>{name}</ListItem>
            })}
          </List>
        </nav>
      )}
      {DropdownElement && <div className='component--button-dropdown-container'>
        {DropdownElement}
      </div>}
    </StyledButton>
  )
}

Button.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  isSecondary: PropTypes.bool,
  isThin: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  inlineIconString: PropTypes.string,
  bigIcon: PropTypes.bool,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  turnedOn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  listItems: PropTypes.arrayOf(
    PropTypes.object
  ),
  dropdownWidth: PropTypes.number,
  DropdownElement: PropTypes.node,
  style: PropTypes.object,
  InlineIcon: PropTypes.any,
  onReady: PropTypes.func
}

export default Button
