/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled, { css } from 'styled-components'

const StyledPrice = styled.span`
  ${props => css`
    &.struck-out {
      background-image: url('data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="1" height="1"><defs><clipPath id="a"><path d="M1,0 L1,1 L0,1 L0,0 L1,0 Z"/></clipPath></defs><g clip-path="url(#a)"><polygon fill="${props.isStruckOut}" points="0 0 1 0 1 1 0 1 0 0"/></g></svg>`)}');
      background-repeat: no-repeat;
      background-size: 100% 3px;
      background-position: center center;
      > span {
        opacity: 0.6;
      }
    }
  `}
`

const Price = ({ className, currency, price, isStruckOut, decimalPlaces = 2, ...props }) => {
  const newPriceAsInt = (price / 100)
  const newDecimalPlaces = !Number.isInteger(newPriceAsInt) ? decimalPlaces : 0
  if (currency === 'CRD') {
    return `${newPriceAsInt.toFixed(newDecimalPlaces)} credit${newPriceAsInt !== 1 ? 's' : ''}`
  }
  const lsObject = {
    style: 'currency',
    currency,
    maximumFractionDigits: newDecimalPlaces
  }
  // iOS 14
  if (newDecimalPlaces === 0) {
    lsObject.minimumFractionDigits = 0
  }
  //
  const newPriceAsString = !currency ? newPriceAsInt.toFixed(decimalPlaces) : newPriceAsInt.toLocaleString(undefined, lsObject)
  return (
    <StyledPrice className={classnames('component--price', { 'struck-out': isStruckOut }, className)} isStruckOut={isStruckOut} {...props}>
      <span>{newPriceAsString}</span>
    </StyledPrice>
  )
}

Price.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  price: PropTypes.number,
  isStruckOut: PropTypes.string,
  decimalPlaces: PropTypes.number
}

export default Price
