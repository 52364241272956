import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Button } from '@openbox-app-shared'
import H1 from '../components/H1'
import dashboardIcons from '../icons'

const BREAK_POINT = 700

const StyledMainFrame = styled.div`
  aside {
    position: relative;
    .component--user-and-partner + * {
      margin-top: 1rem;
    }
    .expand-container {
      margin-top: 0.75rem;
      margin-bottom: 0.5rem;
      height: 2rem;
      .component--button {
        width: 1.5rem;
        background-color: red;
        min-width: 1.5rem;
        float: left;
        padding: 0;
        font-size: 90%;
        height: 100%;
        border: 0;
        box-shadow: none;
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      :focus-within {
        box-shadow: none;
      }
      .component--h1 {
        ${props => css`
          ${!props.$asideVisible ? 'display: none;' : ''}
        `}
        margin-left: 2rem;
        color: var(--v2-color-primary, white);
        font-size: 1rem;
        > strong {
          display: block;
          height: 2rem;
          line-height: 2rem;
          white-space: nowrap;
          overflow: hidden; 
          text-overflow: ellipsis;
        }
      }
    }
    > div {
      display: block;
      .component--box + .component--box {
        margin-top: 1rem;
      }
    }
    > .generic-container > hr {
      border-color: white;
    }
    > .generic-container > .component--expand-collapse {
      background-color: transparent;
      border-radius: 6px;
      border: 0;
      box-shadow: 0 3px 6px 0 rgb(60 66 87 / 20%), 0 1px 2px 0 rgb(0 0 0 / 20%);
      summary {
        color: white;
        ::before {
          border-color: transparent transparent transparent white;
        }
      }
    }
  }
  ${props => css`
    aside {
      > div.generic-container {
        ${!props.$asideVisible ? 'display: none;' : ''}
      }
    }
    @media only screen and (min-width: ${BREAK_POINT}px) {
      aside {
        width: ${props.$asideVisible ? props.$asideWidth : 32}px;
        float: left;
        margin-bottom: 1rem;
      }
      > div {
        margin-left: ${`calc(${props.$asideVisible ? props.$asideWidth : 32}px + 1rem)`};
      }
    }
    @media only screen and (max-width: ${BREAK_POINT - 1}px) {
      .aside-hide-before-breakpoint {
        display: none !important;
      }
      > aside {
        .expand-container .component--h1 {
          display: block !important;
        }
      }
      > aside + div {
        margin-top: 0;
      }
    }
  `}
`

export default function MainFrame ({ user, aside, asideWidth = 272, main, autoUi }) {
  const vLoad = (k) => window.sticky.internals.readFromLocalStorage(`dashboard--${k}`)
  const vSave = (k, v) => window.sticky.internals.writeToLocalStorage(`dashboard--${k}`, v)
  let [isAsideExpanded, setIsAsideExpanded] = useState(vLoad('isAsideExpanded') === false ? false : true)
  const toggleExpandCollapse = () => {
    setIsAsideExpanded(isAsideExpanded ? false : true)
    vSave('isAsideExpanded', isAsideExpanded ? false : true)
  }

  return (
    <StyledMainFrame
      className={`component--main-frame ${isAsideExpanded ? 'expanded' : 'not-expanded'}`}
      $asideVisible={isAsideExpanded}
      $asideWidth={asideWidth}
    >
      <aside className='print-hide'>
        <div className='expand-container'>
          <Button
            isSecondary
            className='expand'
            onClick={toggleExpandCollapse}
            color='var(--v2-color-primary, white)'
            backgroundColor='transparent'
            InlineIcon={dashboardIcons.burger}
          >
          </Button>
          <H1>{user.name}</H1>
        </div>
        <div className='generic-container print-hide'>
          {aside}
        </div>
      </aside>
      <div className='content'>{main}</div>
    </StyledMainFrame>
  )
}
MainFrame.propTypes = {
  user: PropTypes.object.isRequired,
  aside: PropTypes.node,
  asideWidth: PropTypes.number,
  main: PropTypes.node,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
