import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, isUuid, Button, List, ListItem, Price } from '@openbox-app-shared'

import { dispatch, subscribe } from '../redux'
import dashboardIcons from '../icons'
import { log } from '../log'
import ChildrenAndInlineLinkFrame from './ChildrenAndInlineLinkFrame'

const StyledComponent = styled.div`
  .buttons {
    margin-bottom: 0.5rem;
    > * {
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .component--list .part {
    padding: 0.5px 4px 0.5px 5px;
    background-color: #ff9f43;
    color: white;
    border-radius: 4px;
    &.good {
      background-color: #40CF7A;
      color: white;
    }
    &.bad {
      background-color: #FF3838;
      color: white;
    }
  }
`

const COMPARATORS = {
  number: [
    { id: '>', name: 'is more than' },
    { id: '<', name: 'is less than' },
    { id: '==', name: 'is' },
    { id: '!=', name: 'is not' },
    { id: '>=', name: 'is more than or exactly' },
    { id: '<=', name: 'is less than or exactly' },
    { id: '%', name: 'is a multiple of' }
  ],
  time: [
    { id: '>=', name: 'after' }, { id: '>', name: 'after' },
    { id: '<=', name: 'before' }, { id: '<', name: 'before' },
    { id: '==', name: 'is' },
    { id: '!=', name: 'is not' }
  ]
}

const OPERATORS = [
  { id: '+', name: '+' },
  { id: '-', name: '-' },
  { id: '*', name: '*' },
  { id: '/', name: '/' }
]

function inlineFormatTime (seconds) {
  // Calculate the number of days, hours, minutes, and seconds
  const days = Math.floor(seconds / 86400) // 86400 seconds in a day
  seconds %= 86400 // Get the remainder of seconds after calculating days

  const hours = Math.floor(seconds / 3600) // 3600 seconds in an hour
  seconds %= 3600 // Get the remainder of seconds after calculating hours

  const minutes = Math.floor(seconds / 60) // 60 seconds in a minute
  const remainingSeconds = seconds % 60 // Remaining seconds

  // Format the time components with leading zeros if necessary
  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  // If the time includes days, format the output with days
  if (days > 0) {
    return `${days} day${days !== 1 ? 's' : ''} ${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`
  }
  // Otherwise, format it as hours:minutes:seconds
  return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`
}

function sanitiseV (v) {
  return (v + '').replaceAll('\n', '  ')
}

function getDisplayName (stuff, renderName, config) {
  if (renderName.length === 0) {
    return renderName
  }
  let parts = renderName.split(' ')
  return (
    <>
      {parts.map((p, i) => {
        if (p.startsWith('{boolean:') && p[p.length - 1] === '}') {
          const key = p.substring('{boolean:'.length, p.length - 1)
          const v = config[key]
          const toReturn = <><span className={`part ${v ? 'good' : 'bad'}`} key={`part--${i}`}>{v ? 'Yes' : 'No'}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{not:') && p[p.length - 1] === '}') {
          const key = p.substring('{not:'.length, p.length - 1)
          const v = config[key]
          const toReturn = v ? <><span className='part bad' key={`part--${i}`}>not</span>&nbsp;</> : null
          return toReturn
        }
        if (p.startsWith('{part:') && p[p.length - 1] === '}') {
          const k = p.substring('{part:'.length, p.length - 1)
          let v = config[k] !== '' ? config[k] : '?'
          const toReturn = <><span className='part' key={`part--${i}`}>{v}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{code:') && p[p.length - 1] === '}') {
          const key = p.substring('{code:'.length, p.length - 1)
          const v = sanitiseV(config[key])
          const toReturn = <span key={`part--${i}`}><code>{v}</code>&nbsp;</span>
          return toReturn
        }
        if (p.startsWith('{price:') && p[p.length - 1] === '}') {
          const key = p.substring('{price:'.length, p.length - 1)
          const v = config[key]
          const toReturn = <><span className='part' key={`part--${i}`}><Price price={v} /></span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{percentage:') && p[p.length - 1] === '}') {
          const key = p.substring('{percentage:'.length, p.length - 1)
          const v = config[key]
          const toReturn = <><span className='part' key={`part--${i}`}>{v}%</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{thing:') && p[p.length - 1] === '}') {
          const key = p.substring('{thing:'.length, p.length - 1)
          const entity = stuff && stuff.things && stuff.things.find(t => t.id === config[key])
          const toReturn = <><span className='part' key={`part--${i}`}>{(entity || { name: '(Unknown)' }).name}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{federatedUser:') && p[p.length - 1] === '}') {
          const key = p.substring('{federatedUser:'.length, p.length - 1)
          const value = config[key]
          const name = (() => {
            if (value === 'none') {
              return 'anyone'
            }
            const entity = stuff && stuff.federatedUsers && stuff.federatedUsers.find(t => t.id === value)
            return (entity || { name: '(Unknown)' }).name
          })()
          const backgroundColor = (() => {
            const entity = stuff && stuff.federatedUsers && stuff.federatedUsers.find(t => t.id === value)
            return entity ? entity.color : undefined
          })()
          const toReturn = <><span className='part' key={`part--${i}`} style={{ backgroundColor, color: 'white' }}>{name}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{action:') && p[p.length - 1] === '}') {
          const key = p.substring('{action:'.length, p.length - 1)
          const [action, data] = config[key].split('~~||~~')
          const toReturn = <><span className='part' key={`part--${i}`}>{data || '(Unknown)'}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{application:') && p[p.length - 1] === '}') {
          const key = p.substring('{application:'.length, p.length - 1)
          const entity = (() => {
            if (config[key].length === 0) {
              return { name: 'any flow' }
            }
            if (!isUuid(config[key])) {
              return { name: config[key] }
            }
            const foundA = stuff && stuff.applications && stuff.applications.find(t => t.id === config[key])
            return foundA || { name: '(Unknown)' }
          })()
          const toReturn = <><span className='part' key={`part--${i}`}>{entity.name}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{product:') && p[p.length - 1] === '}') {
          const key = p.substring('{product:'.length, p.length - 1)
          const entity = config[key].length === 0 ? { name: 'any product' } : (stuff && stuff.products && stuff.products.find(t => t.id === config[key]))
          const toReturn = <><span className='part' key={`part--${i}`}>{(entity || { name: '(Unknown)' }).name}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{productCategory:') && p[p.length - 1] === '}') {
          const key = p.substring('{productCategory:'.length, p.length - 1)
          const entity = config[key].length === 0 ? { name: 'any product category' } : (stuff && stuff.productCategories && stuff.productCategories.find(t => t.id === config[key]))
          const toReturn = <><span className='part' key={`part--${i}`}>{(entity || { name: '(Unknown)' }).name}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{minutesInTheDay:') && p[p.length - 1] === '}') {
          const key = p.substring('{minutesInTheDay:'.length, p.length - 1)
          const v = config[key] + (stuff ? (stuff.user.timezone / 60) : 0)
          const time = inlineFormatTime(v)
          const toReturn = <><span className='part' key={`part--${i}`}>{time}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{timeLength:') && p[p.length - 1] === '}') {
          const key = p.substring('{timeLength:'.length, p.length - 1)
          const v = config[key]
          const time = inlineFormatTime(v)
          const toReturn = <><span className='part' key={`part--${i}`}>{time}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{days:') && p[p.length - 1] === '}') {
          const key = p.substring('{days:'.length, p.length - 1)
          const v = config[key]
          const realValue = v.map(window.sticky.dateTime.renderDayOfWeek).join(' or ')
          const toReturn = <><span className='part' key={`part--${i}`}>{realValue}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{tags:') && p[p.length - 1] === '}') {
          const key = p.substring('{tags:'.length, p.length - 1)
          const v = config[key]
          const realTags = stuff ? stuff.user.tags.get().filter(_ => v.includes(_.id)) || [{ name: '(Unknown)' }] : [{ name: '(Unknown)' }]
          const toReturn = realTags.map((_, i) => <><span className='part' key={`part--${i}`} style={{ backgroundColor: _.color }}>{_.name}</span>{i < realTags.length - 1 ? ' or ' : ''}&nbsp;</>)
          return toReturn
        }
        if (p.startsWith('{comparator:') && p[p.length - 1] === '}') {
          const key = p.substring('{comparator:'.length, p.length - 1)
          const v = config[key]
          const realValue = COMPARATORS.number.find(o => o.id === v).name
          const toReturn = <span key={`part--${i}`}>{realValue}&nbsp;</span>
          return toReturn
        }
        if (p.startsWith('{comparatorTime:') && p[p.length - 1] === '}') {
          const key = p.substring('{comparatorTime:'.length, p.length - 1)
          const v = config[key]
          const realValue = COMPARATORS.time.find(o => o.id === v).name
          const toReturn = <span key={`part--${i}`}>{realValue}&nbsp;</span>
          return toReturn
        }
        if (p.startsWith('{date:') && p[p.length - 1] === '}') {
          const key = p.substring('{date:'.length, p.length - 1)
          const v = `date:${config[key]}`
          const realValue = window.sticky.getDeserializedValue(v, stuff ? stuff.user : undefined, false)
          const toReturn = <><span className='part' key={`part--${i}`}>{realValue}</span>&nbsp;</>
          return toReturn
        }
        if (p.startsWith('{operator:') && p[p.length - 1] === '}') {
          const key = p.substring('{operator:'.length, p.length - 1)
          const v = config[key]
          const realValue = OPERATORS.find(o => o.id === v).name
          const toReturn = <span key={`part--${i}`}>{realValue}&nbsp;</span>
          return toReturn
        }
        if (p.startsWith('{language:') && p[p.length - 1] === '}') {
          const key = p.substring('{language:'.length, p.length - 1)
          const [v] = config[key].split('--')
          const foundLanguage = window.sticky.internals.ISO_LANGUAGES.find(([code]) => code === v)
          const [, englishName, nativeName] = foundLanguage
          const finalRender = nativeName !== englishName ? `${nativeName} (${englishName})` : nativeName
          const toReturn = <><span className='part' key={`part--${i}`}>{finalRender}</span>&nbsp;</>
          return toReturn
        }
        if (p[0] === '{' && p[p.length - 1] === '}') {
          const key = p.substring(1, p.length - 1)
          const v = sanitiseV(config[key])
          const toReturn = <span key={`part--${i}`}>{v}&nbsp;</span>
          return toReturn
        }
        return <span key={`part--${i}`}>{p}&nbsp;</span>
      })}
    </>
  )
}

function getIcon (idAndConfigAndAb) {
  const foundImageC = idAndConfigAndAb.ab.defaultConfig.find(c => ['uploadImage', 'uploadImage--choose'].includes(c.type))
  const potentialV = foundImageC ? idAndConfigAndAb.idAndConfig.config[foundImageC.key] : undefined
  return potentialV || idAndConfigAndAb.ab.calculatedIcon
}

export default function ApplicationBlocksList ({ aBaseSettingsRender, allApplicationBlocks, wellKnownDefaults, events, eventKey, currentEventKey, onUpdate, previewLink }) {
  const getActualAb = someId => allApplicationBlocks.find(ab => ab.id === someId || ab.shortId === someId)
  const idAndConfigAndAbs = events
    .map(e => {
      const ab = getActualAb(e.id)
      return ab
        ? {
          idAndConfig: e,
          ab
        }
        : undefined
    })
    .filter(ab => ab)

  const [stuff, setStuff] = useState()
  const [selectMode, setSelectMode] = useState()
  let [selectedIndices, setSelectedIndices] = useState([])
  const [oldCurrentEk, setOldCurrentEk] = useState(currentEventKey)
  if (oldCurrentEk !== currentEventKey) {
    setOldCurrentEk(currentEventKey)
    setSelectMode(undefined)
    setSelectedIndices([])
  }

  useEffect(() => {
    const subscriptions = [
      subscribe(
        'APPLICATION_BLOCK_GOOD',
        ({ applicationBlock, config, index, toInsertIndex }) => {
          log('[applicationBlocks] [APPLICATION_BLOCK_GOOD] 1', { applicationBlock, config, index, toInsertIndex, stuff })
          if (typeof toInsertIndex === 'number') {
            events.splice(toInsertIndex, 0, {
              id: applicationBlock.id,
              config
            })
          } else {
            if (typeof index !== 'number') {
              events = [
                ...events,
                {
                  id: applicationBlock.id,
                  config
                }
              ]
            } else {
              events = events.map((_, i) => {
                if (i === index) {
                  return {
                    id: _.id,
                    config
                  }
                }
                return _
              })
            }
          }
          onUpdate(events)
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })
  useEffect(() => {
    async function getStuff () {
      setStuff({
        user: await window.sticky.users.get(),
        things: await window.sticky.things.getAll(),
        applications: await window.sticky.applications.getAll(),
        products: await window.sticky.products.getAll(),
        productCategories: await window.sticky.products.categories.getAll(),
        federatedUsers: await window.sticky.users.federated.getAll()
      })
    }
    !stuff && getStuff()
  })

  const onAction = (eventKey, index, action) => {
    log('[applicationBlocks] [onAction]', { eventKey, index, action })
    action === 'add' && (() => {
      dispatch(
        'APPLICATION_BLOCK',
        {
          wellKnownDefaults,
          config: undefined,
          doDisableIterator: _ => aBaseSettingsRender ? (_.disableInBaseSettingsRenders.includes(aBaseSettingsRender) || _.disableInBaseEventTypes.includes(eventKey)) : false,
          toInsertIndex: index + 1
        }
      )
    })()
    action === 'copy' && (() => {
      const foundItem = idAndConfigAndAbs[index]
      idAndConfigAndAbs.splice(index + 1, 0, {
        ...foundItem
      })
      onUpdate(idAndConfigAndAbs.map(_ => _.idAndConfig))
    })()
    action === 'paste' && (() => {
      if (!navigator.clipboard.readText) {
        dispatch('SHOW_MESSAGE', { message: <><p>Sorry, your clipboard couldn't be accessed.</p><p>This is probably because you are using Firefox.</p></>, canBeBadded: '' })
        return
      }
      navigator.clipboard.readText()
        .then((text) => {
          try {
            const asArrayOfObjects = JSON.parse(text)
            window.sticky.assert(Array.isArray(asArrayOfObjects), 'JSON is not an array')
            asArrayOfObjects.forEach(ask => {
              window.sticky.assert(typeof ask.id === 'string', 'JSON contains a non-string ID key')
              window.sticky.assert(typeof ask.config === 'object' && ask.config !== null, 'JSON contains a non-object config key')
            })
            events.splice(
              index + 1,
              0,
              ...asArrayOfObjects
            )
            onUpdate(events)
          } catch ({ message }) {
            dispatch('SHOW_MESSAGE', { message: <p>You don't have any steps on your clipboard ({message})</p>, canBeBadded: '' })
          }
        })
    })()
    action === 'delete' && (() => {
      onUpdate(
        idAndConfigAndAbs
          .filter((_, i) => {
            return (i !== index)
          })
          .map(_ => _.idAndConfig)
      )
    })()
  }

  const onChoose = (idAndConfigAndAbs, index) => {
    const { ab, idAndConfig } = idAndConfigAndAbs[index]
    if (ab.defaultConfig.length === 0) {
      return
    }
    log('[applicationBlocks] [onChoose]', { wellKnownDefaults, idAndConfigAndAbs, idAndConfig, index }) // ab--1 -> 1 (int)
    dispatch('APPLICATION_BLOCK', { wellKnownDefaults, applicationBlock: ab, config: idAndConfig.config, index })
  }

  let indentation = 0
  return (
    <ChildrenAndInlineLinkFrame
      inlineLinkFrameProps={previewLink && {
        link: previewLink,
        backgroundColor: wellKnownDefaults.colour2
      }}
      distance={8}
    >
      <StyledComponent>
        <div className='buttons'>
          <Button
            backgroundColor='#26de81'
            InlineIcon={dashboardIcons.add}
            onClick={() => {
              dispatch(
                'APPLICATION_BLOCK',
                {
                  wellKnownDefaults,
                  config: undefined,
                  doDisableIterator: _ => (aBaseSettingsRender ? _.disableInBaseSettingsRenders.includes(aBaseSettingsRender) : false) || _.disableInBaseEventTypes.includes(eventKey)
                }
              )
            }}
            disabled={selectMode ? true : false}
            className={idAndConfigAndAbs.length === 0 ? 'openbox--pulsing-2' : undefined}
          >
            Step
          </Button>
          {idAndConfigAndAbs.length > 0 && !selectMode && <Button
            onClick={() => {
              setSelectMode('select')
              setSelectedIndices([])
            }}
            title='Select'
            InlineIcon={dashboardIcons.select}
            isSecondary
          />}
          {selectedIndices.length > 0 && <Button
            onClick={() => {
              const toCopy = idAndConfigAndAbs
                .filter((_, i) => (selectedIndices.includes(i)))
                .map(ab => ab.idAndConfig)
              navigator.clipboard.writeText(JSON.stringify(toCopy, null, 2))
                .then(() => {
                  setSelectMode('copy')
                  setTimeout(() => {
                    setSelectedIndices([])
                    setSelectMode(undefined)
                  }, 1000)
                })
                .catch(() => {})
            }}
            InlineIcon={dashboardIcons.copy}
            isSecondary
          >
            {selectMode === 'copy' ? 'Copied!' : 'Copy'}
          </Button>}
          {selectedIndices.length > 0 && <Button
            title='Delete'
            backgroundColor='#ff3838'
            InlineIcon={dashboardIcons.delete}
            color='white'
            isSecondary
            onClick={() => {
              onUpdate(events.filter((__, eki) => (!selectedIndices.includes(eki))))
              setSelectedIndices([])
              setSelectMode(undefined)
            }}
          >
            Delete
          </Button>}
          {idAndConfigAndAbs.length > 0 && selectMode && events.length !== selectedIndices.length && <Button
            onClick={() => {
              const newIndices = []
              for (let i = 0; i < events.length; i++) newIndices.push(i)
              setSelectedIndices(newIndices)
            }}
            isSecondary
            InlineIcon={dashboardIcons.selectAll}
          >
            Select all
          </Button>}
          {idAndConfigAndAbs.length > 0 && selectMode && <Button
            onClick={() => {
              setSelectMode(undefined)
              setSelectedIndices([])
            }}
            isSecondary
          >
            Cancel
          </Button>}
          {!selectMode && <Button
            onClick={() => {
              if (!navigator.clipboard.readText) {
                dispatch('SHOW_MESSAGE', { message: <><p>Sorry, your clipboard couldn't be accessed.</p><p>This is probably because you are using Firefox.</p></>, canBeBadded: '' })
                return
              }
              navigator.clipboard.readText()
                .then((text) => {
                  try {
                    const asArrayOfObjects = JSON.parse(text)
                    window.sticky.assert(Array.isArray(asArrayOfObjects), 'JSON is not an array')
                    asArrayOfObjects.forEach(ask => {
                      window.sticky.assert(typeof ask.id === 'string', 'JSON contains a non-string ID key')
                      window.sticky.assert(typeof ask.config === 'object' && ask.config !== null, 'JSON contains a non-object config key')
                    })
                    events = [
                      ...events,
                      ...asArrayOfObjects
                    ]
                    onUpdate(events)
                  } catch ({ message }) {
                    dispatch('SHOW_MESSAGE', { message: <p>You don't have any steps on your clipboard ({message})</p>, canBeBadded: '' })
                  }
                })
                .catch(({ message }) => {
                  dispatch('SHOW_MESSAGE', { message: <><p>Sorry, your clipboard couldn't be accessed.</p><p>{message}</p></>, canBeBadded: '' })
                })
            }}
            isSecondary
            InlineIcon={dashboardIcons.paste}
          >
            Paste
          </Button>}
        </div>
        <List
          emptyText="Your flow doesn't have any steps yet."
          draggable
          onDrag={(from, to) => {
            window.sticky.swapArrayElements(idAndConfigAndAbs, from, to)
            onUpdate(idAndConfigAndAbs.map(_ => _.idAndConfig))
          }}
        >
          {idAndConfigAndAbs.map((idAndConfigAndAb, i) => {
            const { idAndConfig, ab } = idAndConfigAndAb
            if (
              indentation > 0 &&
              (ab.indent === window.sticky.applications.blocks.INDENT.DEDENT || ab.indent === window.sticky.applications.blocks.INDENT.INVERT)
            ) {
              indentation -= 36
            }
            const previousIdAndConfigAndAb = (i > 0 && idAndConfigAndAbs[i - 1])
            if (ab.indent !== window.sticky.applications.blocks.INDENT.DEDENT && previousIdAndConfigAndAb && previousIdAndConfigAndAb.ab.indent === window.sticky.applications.blocks.INDENT.INVERT) {
              indentation += 36
            }
            const toReturn = (
              <ListItem
                id={`ab--${i}`}
                key={`ab--${i}`}
                icon={selectMode ? (selectedIndices.includes(i) ? icons.generic.check : icons.generic.uncheck) : getIcon(idAndConfigAndAb)}
                actions={['add', 'copy', 'paste', 'delete']}
                onAction={(id, action) => onAction(eventKey, parseInt(id.substring('ab--'.length), 10), action)}
                indentationInner={indentation}
                onChoose={(id) => {
                  if (selectMode) {
                    if (selectedIndices.includes(i)) {
                      selectedIndices = selectedIndices.filter(si => si !== i)
                    } else {
                      selectedIndices.push(i)
                    }
                    setSelectedIndices([...selectedIndices])
                  } else {
                    onChoose(idAndConfigAndAbs, parseInt(id.substring('ab--'.length), 10))
                  }
                }}
              >
                {getDisplayName(stuff, ab.renderName, idAndConfig.config)}
              </ListItem>
            )
            if (ab.indent === window.sticky.applications.blocks.INDENT.INDENT) {
              indentation += 36
            }
            return toReturn
          })}
        </List>
      </StyledComponent>
    </ChildrenAndInlineLinkFrame>
  )
}

ApplicationBlocksList.propTypes = {
  aBaseSettingsRender: PropTypes.string,
  allApplicationBlocks: PropTypes.array,
  wellKnownDefaults: PropTypes.object,
  events: PropTypes.array,
  eventKey: PropTypes.string,
  currentEventKey: PropTypes.string,
  onUpdate: PropTypes.func,
  previewLink: PropTypes.string
}
