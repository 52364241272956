import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, CustomHelmet, Loading, Details, Tags, Tag, LinkButton } from '@openbox-app-shared'

import dashboardIcons from '../../icons'
import PartnerUsers from '../../components/PartnerUsers'
import MainFrame from '../../components/MainFrame'
import Box from '../../components/Box'
import TabBar from '../../components/TabBar'
import { log } from '../../log'

const StyledRoute = styled.div`
  padding: 1rem;
  .tab-container {
    margin-top: 1rem;
  }
  .component--details {
    width: 100%;
  }
  .component--copy-url {
    margin-bottom: 1rem;
  }
  .logo {
    display: block;
    width: 100%;
    max-width: 12rem;
    max-height: 12rem;
  }
  .component--scrollable-table {
    margin-top: 1rem;
  }
  .create-user {
    display: block;
  }
`

const TABS = [
  {
    id: 'users',
    name: 'Dashboards',
    inlineIcon: dashboardIcons.teamMember,
    child: context => <>
      <LinkButton
        to={`${window.location.origin}/${context.state.partner.signUpUrl}`}
        InlineIcon={dashboardIcons.add}
        className='create-user'
      >
        Add a dashboard
      </LinkButton>
      <PartnerUsers users={context.state.users} />
    </>
  }
]

export default class Route extends Component {
  constructor () {
    super()
    this.state = {
      currentTab: TABS[0].id
    }
  }

  async componentDidMount () {
    log('[Route-partner] [componentDidMount]')
    const partner = await window.sticky.internals.partners.get()
    this.freeStyle = window.sticky.addStyle(undefined, partner.css)
    log('[Route-partner] [componentDidMount]', { partner })
    const sdkUsers = await window.sticky.internals.partners.users.getAll(partner.id)
    const users = await window.sticky.map(sdkUsers, async (user) => {
      return { user, partner }
    })
    log('[Route-partner] [componentDidMount]', { sdkUsers, users })
    this.setState({
      partner,
      users
    })
  }

  componentWillUnmount () {
    this.freeStyle && this.freeStyle()
  }

  render () {
    const {
      partner,
      users,
      currentTab
    } = this.state
    const isReady = partner && Array.isArray(users)
    log('[Route-partner] [render]', { partner, users, isReady })

    const partnerDetails = partner ? new Map(
      [
        ['Status', <strong key='d--partner-status' style={{color: partner.statusColor}}>{partner.statusString}</strong>],
        ['ID', <code key='d--partner-id'>{partner.id}</code>],
        ['Private key', <code key='d--partner-id'>{partner.privateKey}</code>],
        ['New dashboard currency', partner.currency],
        ['New dashboard country', partner.country],
        [
          'New dashboard permissions',
          <Tags key='d--permissions'>
            {partner.permissions.toArray().map(ae => <Tag key={ae} tag={{ id: ae, name: ae, isSqueezed: true }} />)}
          </Tags>
        ]
      ]
        .filter(e => e)
    ) : undefined

    const aside = isReady ? (
      <>
        <Box>
          <img className='logo' src={partner.logoUrl} alt={partner.name} />
        </Box>
        <Box>
          <Details details={partnerDetails} />
        </Box>
      </>
    ) : undefined
    const main = isReady && (
      <Box>
        <TabBar
          tabs={TABS.map(t => ({ ...t, child: t.child(this)}))}
          selectedTab={currentTab}
          onClick={currentTab => this.setState({ currentTab })}
        />
      </Box>
    )

    return (
      <StyledRoute>
        <CustomHelmet
          title='Dashboards'
        />
        {!isReady && <Loading />}
        {isReady && <MainFrame user={{ name: partner ? partner.name : '???' }} autoUi={this.props.autoUi} aside={aside} main={main} />}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  match: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
