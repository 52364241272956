import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link, HotImage, Button, Time, icons } from '@openbox-app-shared'

import ScrollableTable from './ScrollableTable'
import dashboardIcons from '../icons'
import { dispatch } from '../redux'

const COLUMNS = {
  logo: '',
  name: '',
  actions: '',
  partner: 'Partner'
}

const StyledComponent = styled.div`
  p.user {
    > * {
      display: block;
    }
    > * + * {
      margin-top: 0.5rem;
    }
    .last-active {
      color: #6C7A89;
    }
  }
  hr {
    margin-top: 0;
  }
  /* 1 logo */
    .component--table td:nth-child(1) {
      width: 10rem; min-width: 10rem; max-width: 10rem;
    }
  /* 3 actions */
    .component--table td:nth-child(3) {
      width: 15rem; min-width: 15rem; max-width: 15rem;
      .buttons {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr 1fr;
        > * {
          // display: inline-block;
          vertical-align: top;
          // height: auto !important;
          margin: 0;
          font-size: 80%;
          padding: 0;
        }
        > *.key-1 {
          width: 6rem !important;
        }
      }
    }
  /* 4 partner */
    .component--table td:nth-child(4) {
      width: 10rem; min-width: 10rem; max-width: 10rem;
    }
`

export default function Users({ authenticatedUser, users }) {
  const rows = users ? users
    .map(({ user, partner }) => {
      return {
        filterBy: [user.name, user.email, user.id, user.privateKey].join(' '),
        logo: (
          <Link dontAttachQueryParameters to={`/me/flows?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}`} target='_blank'>
            <HotImage src={user.logoUrl} alt={user.name} />
          </Link>
        ),
        name: (
          <p className='user'>
            <Link dontAttachQueryParameters to={`/me/flows?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}`} target='_blank'>{user.name}</Link>
            <span className='last-active'>
              Last active: <Time time={user.lastActiveAt} />
            </span>
          </p>
        ),
        actions: <>
          <div className='buttons'>
            <Button className='key-1' isSecondary InlineIcon={dashboardIcons.copy} onClick={() => navigator.clipboard.writeText(user.id)}>
              ID
            </Button>
            <Button className='key-1' isSecondary InlineIcon={dashboardIcons.copy} onClick={() => navigator.clipboard.writeText(user.privateKey)}>
              PK
            </Button>
            <Button className='key-1' isSecondary onClick={() => dispatch('USER_EDIT', { otherUser: user })} icon={icons.generic.edit}>
              Features
            </Button>
            <Button className='key-1' isSecondary onClick={() => dispatch('USER_BILL', { otherUser: user })} InlineIcon={dashboardIcons.payment}>
              Bill
            </Button>
          </div>
        </>,
        partner: partner ? (
          <p>
            <Link dontAttachQueryParameters to={`/partners/${partner.id}?partnerPrivateKey=${partner.privateKey}`} target='_blank'>
              <HotImage src={partner.logoUrl} alt={partner.name} />
            </Link>
          </p>
        ) : undefined
      }
    }) : []

  const onControl = control => {
    control === 'add' && (() => {
      window.location = '/sign-up'
    })()
  }

  return (
    <StyledComponent>
      <ScrollableTable
        filterableKey='filterBy'
        onControl={onControl}
        columns={COLUMNS}
        rows={rows}
      />
    </StyledComponent>
  )
}
Users.propTypes = {
  authenticatedUser: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object)
}
