import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { tags, LinkButton, Label, Banner, ColourPicker, Dropdown, List, ListItem, Form, Input, NumberInput, Price, PriceInput, Button, CustomHelmet, Loading, Switch, PatchableSetList } from '@openbox-app-shared'
import _ from '../_'
import { dispatch } from '../redux'
import dashboardIcons from '../icons'

import Box from '../components/Box'
import TabBar from '../components/TabBar'
import MediaEditor from '../components/mediaEditor'

import { log } from '../log'

import MainFrame from '../components/MainFrame'
import User from '../components/User'
import ListEditor from '../components/ListEditor'
import SaveButton from '../components/SaveButton'
import ProductTagsVat from '../components/ProductTagsVat'

import QuestionModal from '../components/modals/Question'
import { TagChooser } from '../components/TagChooser'
import Stock from '../components/Stock'
import HeaderBar from '../components/HeaderBar'
import H2 from '../components/H2'
import TagChooserUser from '../components/TagChooserUser'
import SubProducts from '../components/SubProducts'

const StyledRoute = styled.div`
  padding: 1rem;
  .component--banner {
    margin-bottom: 1rem;
  }
  .component--banner.bag-sort-how-to {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .buttons {
    height: 2.5rem;
    margin-bottom: 1rem;
    > * {
      float: left;
    }
    > * + * {
      margin-left: 1rem;
    }
  }
  aside {
    hr {
      border-color: #CFD8DC;
    }
  }
  .component--box {
    .logo {
      display: block;
      max-width: 128px;
      margin-bottom: 2rem;
    }
    .component--h1, .component--h2 {
      margin-bottom: 0.5rem;
    }
    .component--details {
      width: 100%;
    }
    .component--form + .component--details, .component--list + .component--details, .component--list + .component--form {
      margin-top: 1rem;
    }
    label + .component--list {
      margin-top: 0.5rem;
    }
  }
  .component--tabbar {
    .tab {
      > * {
        margin-top: 1rem;
      }
    }
    .tab.questions .component--list, .tab.flow-steps .component--list, .tab.sub-products {
      max-width: 32rem;
    }
    .component--box.generic {
      max-width: 390px;
    }
  }
  .not-fat {
    width: 5rem;
  }
  .dont-care-first li:first-child {
    opacity: 0.5;
  }
  .price--currency {
    .component--input.price, .component--dropdown.currency {
      display: inline-block;
    }
    .component--dropdown.currency {
      width: calc(100% - 6rem);
      margin-top: 0;
      margin-left: 1rem;
    }
  }
  label + .component--patchable {
    margin-top: 0.5rem;
  }
  .tab.about {
    .description {
      margin-top: 0.25rem;
      max-width: 20rem;
    }
  }
`

function RenderChildQuestions ({ context }) {
  const { product } = context.state
  const [selectMode, setSelectMode] = useState()
  return (
    <div className='tab questions'>
      {!product && <Loading />}
      {product && (
        <>
          <div className='buttons'>
            <Button
              backgroundColor='#a55eea'
              onClick={context.addQuestion}
              InlineIcon={dashboardIcons.add}
            >
              Question
            </Button>
            {product.questions.length > 0 && <Button
              isSecondary
              InlineIcon={dashboardIcons.copy}
              onClick={e => {
                const asJson = product.questions.map(q => q.toJson())
                navigator.clipboard.writeText(JSON.stringify(asJson, null, 2))
                setSelectMode('copy')
                setTimeout(() => {
                  setSelectMode(undefined)
                }, 1000)
              }}
            >
              {selectMode === 'copy' ? 'Copied!' : 'Copy all'}
            </Button>}
            <Button
              isSecondary
              InlineIcon={dashboardIcons.paste}
              onClick={() => {
                navigator.clipboard.readText()
                  .then((text) => {
                    try {
                      const asArrayOfObjects = JSON.parse(text)
                      window.sticky.assert(Array.isArray(asArrayOfObjects), 'JSON is not an array')
                      asArrayOfObjects.forEach(ask => {
                        window.sticky.assert(typeof ask.type === 'string', 'JSON contains a non-string type key')
                        product.addQuestion(ask)
                      })
                      context.onUpdate('questions', product.questions)
                    } catch ({ message }) {
                      dispatch('SHOW_MESSAGE', { message: <p>You don't have any steps on your clipboard ({message})</p>, canBeBadded: '' })
                    }
                  })
              }}
            >
              Paste
            </Button>
          </div>
          <div className='list-container'>
            <List
              draggable
              onDrag={(from, to) => {
                window.sticky.swapArrayElements(product.questions, from, to)
                context.onUpdate('questions', product.questions)
              }}
              // eslint-disable-next-line quotes
              emptyText={"This product doesn't have any questions yet."}
            >
              {product.questions.map((a, index) => {
                return (
                  <ListItem
                    actions={['copy', 'delete']}
                    onAction={(string, action) => context.onQuestionAction(string, action)}
                    id={`index--${index}`}
                    key={`index--${index}`}
                    InlineIcon={dashboardIcons.questions}
                    onChoose={() => context.onQuestionEdit(a)}
                  >
                    {a.toString()}
                  </ListItem>
                )
              })}
            </List>
          </div>
        </>
      )}
    </div>
  )
}
RenderChildQuestions.propTypes = {
  context: PropTypes.object
}

const tabs = [
  {
    id: 'about',
    name: 'About',
    inlineIcon: dashboardIcons.product,
    to: (context) => context.state.product ? `/me/products/${context.state.product.id}/about` : undefined,
    child: function Child (context, extraProps) {
      const { user } = context.props
      const { product } = context.state
      return (
        <div className='tab about'>
          {!product && <Loading />}
          {product && (
            <>
              <Form {...extraProps.debouncedProps}>
                <Input
                  label='Description'
                  type='textarea'
                  name='description'
                  value={product.description}
                  className='description'
                />
                <Switch
                  name='showInStickyretail2'
                  checked={typeof product.showInStickyretail2 === 'boolean' ? product.showInStickyretail2 : true}
                >
                  Show this product in {window.sticky._('STICKY_PAY')}
                </Switch>
                <Switch
                  name='oneTapCheckout'
                  checked={product.oneTapCheckout}
                >
                  Skip product information screen
                </Switch>
                <Switch
                  name='showPrices'
                  checked={product.showPrices}
                >
                  Show price before selection
                </Switch>
                <Switch
                  name='skipConfigureQuantity'
                  checked={product.skipConfigureQuantity}
                >
                  Skip + / - quantity
                </Switch>
              </Form>
            </>
          )}
        </div>
      )
    }
  },
  {
    id: 'questions',
    name: 'Questions',
    inlineIcon: dashboardIcons.questions,
    to: (context) => context.state.product ? `/me/products/${context.state.product.id}/questions` : undefined,
    child: context => <RenderChildQuestions context={context} />
  },
  {
    id: 'media',
    name: 'Images',
    inlineIcon: dashboardIcons.media,
    to: (context) => context.state.product ? `/me/products/${context.state.product.id}/media` : undefined,
    child: function Child (context) {
      const { product } = context.state
      return (
        <div className='tab'>
          {!product && <Loading />}
          {product && (
            <MediaEditor
              media={product.media}
              onChange={context.setMedia}
              user={context.props.user}
            />
          )}
        </div>
      )
    }
  },
  {
    id: 'labels',
    name: 'Labels',
    inlineIcon: dashboardIcons.tags,
    to: (context) => context.state.product ? `/me/products/${context.state.product.id}/labels` : undefined,
    child: function Child (context, extraProps) {
      const { user } = context.props
      const { product } = context.state
      return (
        <div className='tab'>
          {!product && <Loading />}
          {product && (
            <>
              <TagChooser
                all={tags.filter(t => !t.id.startsWith('vat--') && t.canShow(user))}
                set={product.categories}
                onUpdate={(id) => {
                  product.categories.toggle(id)
                  context.onUpdate('categories', product.categories)
                }}
              />
              <H2>My labels</H2>
              <TagChooserUser
                user={user}
                tags={product.tags}
                onUpdate={(id) => {
                  product.tags.toggle(id)
                  context.onUpdate('tags', product.tags)
                }}
              />
            </>
          )}
        </div>
      )
    }
  },
  {
    id: 'sub-products',
    name: 'Sub products',
    inlineIcon: dashboardIcons.product,
    to: (context) => context.state.product ? `/me/products/${context.state.product.id}/sub-products` : undefined,
    child: function Child (context) {
      const { product, products } = context.state
      return (
        <div className='tab sub-products'>
          {!product && <Loading />}
          {product && (
            <SubProducts
              skipProductIds={product.id}
              subProducts={product.subProducts}
              allProducts={products}
              onChange={subProducts => {
                product.subProducts = subProducts
                context.onUpdate('subProducts', product.subProducts)
              }}
            />
          )}
        </div>
      )
    }
  },
  {
    id: 'advanced',
    name: '',
    inlineIcon: dashboardIcons.dotDotDot,
    to: (context) => context.state.product ? `/me/products/${context.state.product.id}/advanced` : undefined,
    child: function Child (context, extraProps) {
      const { user } = context.props
      const { product, applications } = context.state
      return (
        <div className='tab advanced flow-steps'>
          {!product && <Loading />}
          {product && (
            <>
              <Box className='generic'>
                <Form {...extraProps.debouncedProps}>
                  <Input
                    label='ID'
                    name='id'
                    disabled
                    value={product.id}
                  />
                  <Input
                    label='Your ID'
                    name='theirId'
                    value={product.theirId}
                  />
                  <Input
                    name='gtin'
                    label='Barcode'
                    value={product.gtin}
                  />
                  <ColourPicker
                    name='color'
                    label={`Color in ${window.sticky._('STICKY_PAY')}`}
                    currentColour={product.color}
                  />
                  <Label>Private functions on payment</Label>
                  <ListEditor
                    array={product.executableCodesOnPay.toArray()}
                    name='executableCodesOnPay'
                    fixedHeight={null}
                    entityName='Private function'
                    newEntity='StickyBalance'
                  />
                  <Label>Show flow steps from</Label>
                  <List
                    className='dont-care-first'
                    emptyText={window.sticky._('NO_APPLICATIONS')}
                  >
                    <ListItem
                      selected={!product.applicationId}
                      onChoose={(id) => context.onUpdate('applicationId', id || null)}
                      id=''
                    >
                      (None)
                    </ListItem>
                    {applications.map(a => {
                      return (
                        <ListItem
                          selected={product.applicationId === a.id}
                          onChoose={(id) => context.onUpdate('applicationId', id)}
                          onAction={() => dispatch('REDIRECT', { to: `/me/flows/${a.id}` })}
                          key={a.id}
                          id={a.id}
                          icon={a.baseIcon}
                          actions={
                            [
                              user.federatedUserCan('applications') && user.federatedUserCan('applications-advanced') && 'go-to'
                            ]
                              .filter(_ => _)
                          }
                          indentationOuter={a.indentation * 32}
                        >
                          {a.name}
                        </ListItem>
                      )
                    })}
                  </List>
                  <NumberInput
                    label='Bag sort'
                    className='not-fat'
                    name='fillCartIndex'
                    value={product.fillCartIndex}
                  />
                </Form>
                <Banner mood='good' className='bag-sort-how-to'>
                  <p>"Bag sort" controls the order of this product in the consumer's bag and therefore the order in "Live payments". When "Bag sort" is 0 or empty, Sticky respects the order in the "Products" tab.</p>
                  <p>You should set the "Bag sort" for every product if you set it for one.</p>
                  <p>The higher the "Bag sort", the further down the product will be in the consumer's bag. To push a product to the top of the bag, set its "Bag sort" to 1 and every other product's "Bag sort" to 2.</p>
                </Banner>
              </Box>
            </>
          )}
        </div>
      )
    }
  }
]

export default class Route extends Component {
  constructor() {
    super()
    this.state = {
      product: undefined,
      hasMadeUpdate: false
    }
    this.onSave = this.onSave.bind(this)
    this.setCurrency = this.setCurrency.bind(this)
    this.setMedia = this.setMedia.bind(this)

    this.addQuestion = this.addQuestion.bind(this)
    this.onQuestionAction = this.onQuestionAction.bind(this)
    this.onQuestionEdit = this.onQuestionEdit.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
  }

  async componentDidMount() {
    const {
      productId,
      view
    } = this.props.match.params
    log('[Route-product] [componentDidMount]', { productId, view })

    let product
    try {
      product = await window.sticky.products.get(productId, true)
      window.sticky.assert(product, 'This product was not found.\n\nIt has probably been deleted.\n\nPlease wait...')
    } catch ({ message }) {
      window.sticky.applications.blocks.showError(message)
      this.stHandler = setTimeout(
        () => {
          window.sticky.bodgeZone.howToFreeRenderInline && sticky.bodgeZone.howToFreeRenderInline()
          dispatch('REDIRECT', { to: '/me/products' })
        },
        2 * 1000
      )
    }

    const applications = await window.sticky.applications.getAll()
    const products = await window.sticky.products.getAll()
    this.setState({
      product,
      products,
      applications
    })
  }

  componentWillUnmount () {
    clearTimeout(this.stHandler)
  }

  setCurrency (id) {
    log('[Route-product] [setCurrency]', { id })
    this.onUpdate('currency', id)
    // this.onSave()
  }

  setMedia (media) {
    log('[Route-product] [setMedia]', { media })
    this.onUpdate('media', media)
    // this.onSave()
  }

  addQuestion () {
    const question = this.state.product.addQuestion()
    this.onUpdate('questions', this.state.product.questions)
    this.onQuestionEdit(question)
  }

  onQuestionAction (string, action) {
    const theIndex = parseInt(string.substring('index--'.length), 10)
    log('[Route-product] [onQuestionAction]', { string, action, theIndex })
    let { questions } = this.state.product
    const actions = {
      'copy': () => {
        const toCopy = questions[theIndex].clone()
        toCopy.question = `Copy of ${toCopy.question}`
        questions.splice(theIndex + 1, 0, toCopy)
        this.onUpdate('questions', questions)
      },
      'delete': () => {
        questions = questions.filter((q, i) => i !== theIndex)
        this.onUpdate('questions', questions)
      }
    }
    actions[action]()
  }

  onQuestionEdit (question) {
    log('[Route-product] [onQuestionEdit]', { question })
    this.setState({
      currentlyEditingQuestion: question
    })
  }

  onUpdate (key, value) {
    log('[Route-product] [onUpdate] 1', { key, value })
    let { product } = this.state
    product.patch({ [key]: value })
    log('[Route-product] [onUpdate] 2', { product })
    this.setState({
      product,
      hasMadeUpdate: true
    })
  }

  async onSave () {
    const { product } = this.state
    log('[Route-product] [onSave]', product)
    await window.sticky.products.save(product)
    this.setState({
      hasMadeUpdate: false
    })
    window.parent.postMessage({ event: 'reloadProducts' })
  }

  render () {
    const {
      user,
      match
    } = this.props
    const {
      product,
      products,
      hasMadeUpdate,
      currentlyEditingQuestion
    } = this.state
    const {
      view
    } = match.params

    const debouncedProps = {
      onChange: (key, value) => this.onUpdate(key, value)
    }

    const renderTabs = tabs.map(tab => ({
      ...tab,
      to: tab.to(this),
      child: tab.child(this, { debouncedProps })
    }))
    log('[Route-product] [render]', { user, product })

    const connectionName = product && product.connection && user.getConnection(product.connection).name
    return (
      <StyledRoute>
        <CustomHelmet title={`${product ? product.name : '?'} | Products | ${user.name}`} />
        {currentlyEditingQuestion &&
          <QuestionModal
            user={user}
            product={product}
            products={products}
            question={currentlyEditingQuestion}
            onUpdate={(name, value) => {
              currentlyEditingQuestion.answer = (() => {
                // borked
                return ({
                  'option': () => currentlyEditingQuestion.options.length > 0 ? currentlyEditingQuestion.options[0].name : undefined,
                  'options': () => []
                })[currentlyEditingQuestion.type]()
              })()
              this.forceUpdate()
            }}
            onSave={() => {
              this.onUpdate('questions', this.state.product.questions)
              this.setState({ currentlyEditingQuestion: undefined })
            }}
            onCancel={() => {
              this.setState({ currentlyEditingQuestion: undefined })
            }}
          />
        }
        {!product && <Loading />}
        {product && <>
          <MainFrame
            user={user}
            autoUi={this.props.autoUi}
            aside={<>
              <User user={user} whichPart={match.path} toShow={['semantic-home']} autoUi={this.props.autoUi} />
              <hr />
              <div className='buttons'>
                <LinkButton
                  to='/me/products'
                  sameTab
                  backgroundColor='transparent'
                >
                  ← Products
                </LinkButton>
                <SaveButton
                  color='#a55eea'
                  onSave={this.onSave}
                  canSave={hasMadeUpdate}
                />
              </div>
              {product && !product.forSale && (
                <Banner>
                  <p>
                    Customers can't buy this product. Check 'For sale' is switched on and that it has more than 0 stock.
                  </p>
                </Banner>
              )}
              {connectionName && (<Banner>
                <p>
                  <strong>{connectionName} manages this product.</strong>
                </p>
                <p>
                  Updates you make here may be overwritten by {connectionName}.
                </p>
                {product.theirId && <p><strong><code>{product.theirId}</code></strong></p>}
              </Banner>)}
              <Box>
                <Form {...debouncedProps}>
                  <Input
                    name='name'
                    label='Name'
                    value={product.name}
                    autocomplete='off'
                  />
                </Form>
              </Box>
              <Box>
                <Form {...debouncedProps} className='price--currency'>
                  <Switch
                    name='isEnabled'
                    checked={product.isEnabled}
                  >
                    For sale
                  </Switch>
                  <PriceInput
                    className='not-fat price'
                    name='price'
                    label='Price'
                    value={product.price}
                  />
                  <Dropdown
                    className='currency'
                    name='currency'
                    label='Currency'
                    items={window.sticky.Stickypay.CURRENCIES.map(c => ({ id: c.id, name: c.id }))}
                    selected={product.currency}
                  />
                  <ProductTagsVat
                    value={product.categories.toArray().find(t => t.startsWith('vat--'))}
                    onUpdate={value => {
                      [
                        ...tags.map(_ => _.id),
                        ...product.categories.toArray()
                      ]
                        .filter(t => t.startsWith('vat--'))
                        .forEach(v => {
                          product.categories.delete(v)
                        })
                      value && product.categories.add(value)
                      this.onUpdate('categories', product.categories)
                    }}
                  />
                </Form>
              </Box>
              <Box>
                <Stock
                  stock={product.stock}
                  stockNotify={product.stockNotify}
                  name='stock'
                  onChange={this.onUpdate}
                />
              </Box>
            </>}
            main={<>
              <HeaderBar text={`Products › ${product.name}`} Icon={dashboardIcons.product} user={user} />
              <Box>
                <TabBar
                  selectedTab={view}
                  tabs={renderTabs}
                />
              </Box>
            </>}
          />
        </>}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
