import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Switch, CustomHelmet, Button, Tags, Tag, Details, Dropdown, UrlInput, Label } from '@openbox-app-shared'

import { dispatch, subscribe } from '../../redux'
import SaveButton from '../../components/SaveButton'
import Box from '../../components/Box'
import KeyValuePairs from '../../components/KeyValuePairs'
import H2 from '../../components/H2'

const StyledComponent = styled.div`
  .debug, .webhook {
    max-width: 576px;
  }
  .debug {
    tr:last-child td:last-child {
      padding-bottom: 0.5rem;
    }
  }
  .component--h2 {
    margin-bottom: 0.5rem;
  }
  > * + * {
    margin-top: 1rem;
  }
`

const HTTP_METHODS = [
  { id: 'GET', name: 'GET' },
  { id: 'POST', name: 'POST' }
]

export default function RenderChildHome ({ context }) {
  const { user, onUpdateUser, onSaveUser } = context.props
  const [hasMadeUpdate, setHasMadeUpdate] = useState(false)

  useEffect(() => {
    const subscriptions = [
      subscribe(
        'SURE_DELETE_GOOD',
        ({ why }) => {
          why === 'user--advanced--private-key' && (async () => {
            onUpdateUser({ privateKey: 'INTERNAL_UUID' })
            await onSaveUser(['privateKey'], false)
            dispatch('REDIRECT', { to: `${window.location.origin}/developer/about?userPrivateKey=${user.privateKey}&userPublicKey=${user.publicKey}`, reload: true })
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  const dbfProps = {
    onChange: (key, value) => {
      onUpdateUser({ [key]: value })
      setHasMadeUpdate(true)
    }
  }

  const entityDetails = new Map([
    ['ID', <code key='d--user-id'>{user.id}</code>],
    ['Public key', <code key='d--user-publicKey'>{user.publicKey}</code>],
    [
      'Private key',
      <>
        <code key='d--user-privateKey'>{user.privateKey}</code>
        <Button
          isSecondary
          onClick={() => {
            dispatch('SURE_DELETE', { hint: 'Are you sure? This will break all external code.', why: 'user--advanced--private-key' })
          }}
        >
          Generate a new private key
        </Button>
      </>
    ],
    [
      'Legacy VIP code',
      <>
        <code>{user.activationCode}</code>
      </>
    ],
    ['Permissions', (
      <>
        <Tags>
          {user.permissions.toArray().map(ae => <Tag tag={{ id: ae, name: ae, color: '#6C5CE7' }} key={ae} />)}
        </Tags>
        {user.partner && user.partner.permissions.toArray().length > 0 && (
          <Tags>
            {user.partner.permissions.toArray().map(ae => <Tag tag={{ id: ae, name: ae }} key={ae} />)}
          </Tags>
        )}
      </>
    )]
  ])

  return (
    <StyledComponent>
      <CustomHelmet title={`Developer | ${user.name}`} />
      <SaveButton
        onSave={async () => {
          await onSaveUser()
          setHasMadeUpdate(false)
        }}
        color={hasMadeUpdate ? '#FF3838' : undefined}
        canSave={hasMadeUpdate}
      />

      <Details className='debug' details={entityDetails} />

      {user.can('webhook') && (
        <Box className='webhook'>
          <H2>Activity webhook</H2>
          <Form {...dbfProps}>
            <Dropdown
              style={{ maxWidth: '192px' }}
              label='Method'
              name='webhookType'
              selected={user.webhookType || HTTP_METHODS[0].id}
              items={HTTP_METHODS}
            />
            <UrlInput
              name='webhookUrl'
              label='URL'
              value={user.webhookUrl}
              doValidate={false}
              enforceHttps
            />
            <Label>Additional headers</Label>
            <KeyValuePairs
              actionText='Additional header'
              user={user}
              object={user.webhookHeaders.get()}
              newDefaults={['x-key', 'value']}
              onUpdate={({ name, value }) => {
                if (value === null) {
                  user.webhookHeaders.delete(name)
                } else {
                  user.webhookHeaders.writeTo(name, value)
                }
                dbfProps.onChange('webhookHeaders', user.webhookHeaders)
              }}
              emptyText='There are no additional headers. Webhooks will work fine.'
            />
          </Form>
        </Box>
      )}

      <Form {...dbfProps}>
        <Switch
          name='lightMode'
          checked={user.lightMode}
        >
          Use forced long polling for Real Time Flow Messages (RTFM)
        </Switch>
      </Form>
    </StyledComponent>
  )
}
RenderChildHome.propTypes = {
  context: PropTypes.object
}
