import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icons, List, ListItem, Button } from '@openbox-app-shared'
import { dispatch, subscribe } from '../redux'
import dashboardIcons from '../icons'

const StyledListEditor = styled.div`
  .buttons {
    height: 2.5rem;
    margin-top: 0.5rem;
    > * {
      float: left;
    }
    > * + * {
      margin-left: 1rem;
    }
  }
  .list-container {
    min-height: 7.75rem;
    padding: 0.5rem;
    border: 2px solid #F0F1F3;
    border-radius: 4px;
  }
`
export default function ListEditor ({ array, entityName, newEntity, onChange, fixedHeight = 144, customWhyKey = '' }) {
  useEffect(() => {
    const subscriptions = [
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, entity, string }) => {
          why === `list-editor-add--${customWhyKey}` && (() => {
            const newArray = [...array, string]
            onChange && onChange(newArray)
          })()
          why === `list-editor-update--${customWhyKey}` && (() => {
            const newArray = [...array]
            newArray[entity] = string
            onChange && onChange(newArray)
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  return (
    <StyledListEditor>
      <div className='list-container'>
        <List
          draggable
          onDrag={(from, to) => {
            window.sticky.swapArrayElements(array, from, to)
            onChange && onChange(array)
          }}
          fixedHeight={fixedHeight}
        >
          {array.map((o, index) => {
            return (
              <ListItem
                id={o}
                key={o}
                icon={icons.generic.listItem}
                onAction={(string, action) => {
                  const actions = {
                    'rename': () => {
                      dispatch('GET_INPUT', { why: `list-editor-update--${customWhyKey}`, entity: index, string })
                    },
                    'delete': () => {
                      const newArray = [...array]
                      newArray.splice(index, 1)
                      onChange && onChange(newArray)
                    }
                  }
                  actions[action]()
                }}
                onChoose={() => {
                  dispatch('GET_INPUT', { why: `list-editor-update--${customWhyKey}`, entity: index, string: o })
                }}
                actions={['rename', 'delete']}
              >
                {o}
              </ListItem>
            )
          })}
        </List>
      </div>
      <div className='buttons'>
        <Button
          InlineIcon={dashboardIcons.add}
          isSecondary
          onClick={() => {
            dispatch('GET_INPUT', { why: `list-editor-add--${customWhyKey}`, string: newEntity || `${entityName} ${array.length + 1}` })
          }}
        >
          {entityName}
        </Button>
      </div>
    </StyledListEditor>
  )
}
ListEditor.propTypes = {
  array: PropTypes.array,
  entityName: PropTypes.string.isRequired,
  newEntity: PropTypes.string,
  onChange: PropTypes.func,
  fixedHeight: PropTypes.number,
  customWhyKey: PropTypes.string
}
