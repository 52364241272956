import symbol from './files/symbol.svg'
import verify from './files/verify.svg'
import unverify from './files/unverify.svg'
import currency from './files/currency.svg'
import listItem from './files/list-item.svg'
import check from './files/check.svg'
import uncheck from './files/uncheck.svg'
import location from './files/location.svg'
import appearance from './files/appearance.svg'
import account from './files/account.svg'
import discount from './files/discount.svg'
import undo from './files/undo.svg'
import connections from './files/connections.svg'
import barcode from './files/barcode.svg'
import edit from './files/edit.svg'
import video from './files/video.svg'

import refresh from './files/refresh.svg'
import debug from './files/debug.svg'

import scopeSectorApplication from './files/scope-sector-application.svg'
import scopeSectorUser from './files/scope-sector-user.svg'

import viewList from './files/view-list.svg'
import viewGrid from './files/view-grid.svg'
import viewGridName from './files/view-grid-name.svg'

import actionMoveUpmost from './files/action-move-upmost.svg'
import actionMoveDownmost from './files/action-move-downmost.svg'

import actionAdd from './files/action-add.svg'
import actionEdit from './files/action-edit.svg'
import actionDelete from './files/action-delete.svg'
import actionGoTo from './files/action-go-to.svg'
import actionCopy from './files/action-copy.svg'
import actionPaste from './files/action-paste.svg'
import actionNone from './files/action-none.svg'

import operatorPlus from './files/operatorPlus.svg'
import operatorMinus from './files/operatorMinus.svg'
import operatorDivide from './files/operatorDivide.svg'
import operatorMultiply from './files/operatorMultiply.svg'

export default {
  // generic
  symbol,
  verify,
  unverify,
  currency,
  listItem,
  check,
  uncheck,
  location,
  appearance,
  account,
  discount,
  undo,
  connections,
  barcode,
  edit,
  video,

  // epos
  refresh,
  debug,

  // scope sectors
  scopeSectors: {
    application: scopeSectorApplication,
    user: scopeSectorUser
  },

  // views
  views: {
    list: viewList,
    grid: viewGrid,
    gridName: viewGridName
  },

  // actions
  actions: {
    move: {
      upmost: actionMoveUpmost,
      downmost: actionMoveDownmost
    },
    add: actionAdd,
    edit: actionEdit,
    delete: actionDelete,
    goTo: actionGoTo,
    copy: actionCopy,
    paste: actionPaste,
    none: actionNone
  },

  // operators
  operators: {
    plus: operatorPlus,
    minus: operatorMinus,
    divide: operatorDivide,
    multiply: operatorMultiply
  }
}
