/* eslint-disable react/prop-types */
/* eslint-disable quotes */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, ApplicationBlocks, Form, ColourPicker } from '@openbox-app-shared'
import { dispatch } from '../../../redux'

const StyledModal = styled.div`
  .component--modal {
    max-width: 480px;
    .component--application-blocks {
      margin: 0 auto 0 auto;
    }
    .component--form {
      width: fit-content;
      margin: 2rem auto 2rem auto;
    }
  }
  .component--modal.uw-step-1 {
  }
  .component--modal.uw-step-2 {
    .component--application-blocks {
    }
  }
  .component--ultimate-wizard {
    &.step-1 {
      .component--application-blocks {
        button {
          padding: 16px !important;
        }
      }
    }
  }
  *[data-application-block-id="5dc584b0-b0ef-4e4f-a8cc-476e3c8fc36b"] button {
    padding: 16px 16px 8px 16px !important;
  }
`

import UltimateWizard from '../../UltimateWizard'

const baseUseCase = {
  'applicationBlocks': []
}

// await window.sticky.applications.remove(applicationProducts)

const useCases = [
  // {
  //   ...baseUseCase,
  //   'id': 'MERCHANT_VIRTUAL_TERMINAL',
  //   'name': 'Virtual terminal',
  //   'color': '#607481',
  //   applicationBlocks: () => [],
  //   'go': async ({ user }, abData) => {
  //     const allApplications = await window.sticky.applications.getAll()
  //     const applicationProducts = allApplications.find(v => v.wellKnownIdentifier === 'products')
  //     const finalBackgroundColor = applicationProducts ? applicationProducts.backgroundColor : undefined
  //     const finalPrimaryColor = applicationProducts ? applicationProducts.primaryColor : undefined

  //     await window.sticky.applications.create({
  //       name: _('ONBOARDING_MERCHANT_VIRTUAL_TERMINAL'),
  //       stickyretail: JSON.stringify({ "payRejectTotalZero": true, "isMoto": true, "rememberCard": false, "caresAboutCardName": false }),
  //       wellKnownIdentifier: 'ONBOARDING_MERCHANT_VIRTUAL_TERMINAL',
  //       backendLogic: 'return `${input.consumerAppUrl}/${!input.v2.application.lightMode ? "flow" : "light"}`;',
  //       backgroundColor: finalBackgroundColor,
  //       primaryColor: finalPrimaryColor,
  //       createdAt: applicationProducts ? applicationProducts.createdAt - 1 : undefined,
  //       events: {
  //         on_load: [
  //           {
  //             "id": "d8ff6114-5f47-476c-975f-35cc6030f39b",
  //             "config": {
  //               "maxWidth": 128
  //             }
  //           },
  //           {
  //             "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
  //             "config": {}
  //           },
  //           {
  //             "id": "b099236f-a87b-43fe-acff-037b63579881",
  //             "config": {
  //               "showShadow": true,
  //               "havePadding": true,
  //               "showBackgroundColor": false,
  //               "showBackgroundColorInverted": false,
  //               "blockOnDoneEarly": false,
  //               "doScrolling": false
  //             }
  //           },
  //           {
  //             "id": "d4d0aa59-3265-4165-aa38-48e1f24db66c",
  //             "config": {
  //               "federatedUserId": "none"
  //             }
  //           },
  //           {
  //             "id": "2d17f33f-f961-420e-9afe-6eef73b22e4f",
  //             "config": {
  //               "value": 100
  //             }
  //           },
  //           {
  //             "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
  //             "config": {
  //               "label": "Amount",
  //               "type": " → Total",
  //               "value": "1.00",
  //               "disabled": false,
  //               "required": false,
  //               "stashUser": false
  //             }
  //           },
  //           {
  //             "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
  //             "config": {
  //               "label": "Name",
  //               "type": " → Name",
  //               "value": "",
  //               "disabled": false,
  //               "required": false,
  //               "stashUser": false
  //             }
  //           },
  //           {
  //             "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
  //             "config": {
  //               "label": "Email",
  //               "type": " → Email",
  //               "value": "",
  //               "disabled": false,
  //               "required": false,
  //               "stashUser": false
  //             }
  //           },
  //           {
  //             "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
  //             "config": {
  //               "label": "Reference",
  //               "type": " → Your ID",
  //               "value": "",
  //               "disabled": false,
  //               "required": false,
  //               "stashUser": false
  //             }
  //           },
  //           {
  //             "id": "a21eddf2-aa86-4b6a-a2af-8ac279b246f7",
  //             "config": {
  //               "label": "Take payment",
  //               "action": "pay~~||~~~~||~~false",
  //               "colour": finalPrimaryColor,
  //               "foregroundColour": finalBackgroundColor,
  //               "icon": "check",
  //               "fullWidth": false,
  //               "dropShadowAndRoundedCorners": true,
  //               "letterSpacing": 1,
  //               "backgroundImage": ""
  //             }
  //           },
  //           {
  //             "id": "2bea604e-f202-4e63-99b0-b5d280313430",
  //             "config": {}
  //           },
  //           {
  //             "id": "eab1198f-f924-442c-90d7-fca408ee9ef8",
  //             "config": {
  //               "what": "Please log in",
  //               "type": "Subheading",
  //               "colour": finalPrimaryColor
  //             }
  //           },
  //           {
  //             "id": "b5c4dd38-b459-434a-a49d-3ab07949f8c8",
  //             "config": {
  //               "behaviour": "Email pop up",
  //               "mode": "Password",
  //               "doReload": true,
  //               "message1": "",
  //               "message2": "",
  //               "withBlockerBackground": false
  //             }
  //           },
  //           {
  //             "id": "d01142a2-2cd2-4e8e-b2ca-04960f80152e",
  //             "config": {}
  //           }
  //         ]
  //           .filter(_ => _),
  //         on_pay: [
  //           {
  //             "id": "4c0bcefa-088e-4701-8be2-0bea82478575",
  //             "config": {
  //               "variable": "name",
  //               "scope": "cross-user"
  //             }
  //           },
  //           {
  //             "id": "4c0bcefa-088e-4701-8be2-0bea82478575",
  //             "config": {
  //               "variable": "email",
  //               "scope": "cross-user"
  //             }
  //           },
  //           {
  //             "id": "a33ebfef-fa9a-46e7-a528-bfac93c62183",
  //             "config": {
  //               "runForever": false
  //             }
  //           },
  //           {
  //             "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
  //             "config": {}
  //           },
  //           {
  //             "id": "debug",
  //             "config": {
  //               "what": "Payment successful!",
  //               "font": `${finalPrimaryColor}--center--120%--false`,
  //               "backgroundColour": "#FFFFFF",
  //               "icon": ""
  //             }
  //           }
  //           // {
  //           //   "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
  //           //   "config": {}
  //           // },
  //           // {
  //           //   "id": "a21eddf2-aa86-4b6a-a2af-8ac279b246f7",
  //           //   "config": {
  //           //     "label": "Take another payment",
  //           //     "action": "reloadApplication~~||~~~~||~~false",
  //           //     "colour": finalPrimaryColor,
  //           //     "foregroundColour": finalBackgroundColor,
  //           //     "icon": "arrowRight",
  //           //     "fullWidth": false,
  //           //     "dropShadowAndRoundedCorners": true,
  //           //     "letterSpacing": 1,
  //           //     "backgroundImage": ""
  //           //   }
  //           // }
  //         ],
  //         on_pay_fail: [
  //           {
  //             "id": "71d05208-3781-4c24-996e-c4c0d1c6b228",
  //             "config": {
  //               "what": "{paymentFailureReason}",
  //               "font": `${finalPrimaryColor}--center--100%--false`
  //             }
  //           },
  //           {
  //             "id": "71d05208-3781-4c24-996e-c4c0d1c6b228",
  //             "config": {
  //               "what": "The customer has not been charged.",
  //               "font": `${finalPrimaryColor}--center--100%--false`
  //             }
  //           }
  //         ]
  //       }
  //     })

  //     dispatch('REFRESH_REDUX')
  //     return '/my/account/payment-provider'
  //   }
  // },
  {
    ...baseUseCase,
    'id': 'CUSTOMER_VIRTUAL_TERMINAL',
    'name': 'Website',
    'color': '#607481',
    applicationBlocks: () => [],
    'go': async ({ user }, abData) => {
      const allApplications = await window.sticky.applications.getAll()
      const applicationProducts = allApplications.find(v => v.wellKnownIdentifier === 'products')
      const finalBackgroundColor = applicationProducts ? applicationProducts.backgroundColor : undefined
      const finalPrimaryColor = applicationProducts ? applicationProducts.primaryColor : undefined
      const currencyObject = window.sticky.Stickypay.CURRENCIES.find(c => c.id == user.currency)

      await window.sticky.applications.create({
        name: _('ONBOARDING_CUSTOMER_VIRTUAL_TERMINAL'),
        stickyretail: JSON.stringify({ "payRejectTotalZero": true, "isMoto": false, "rememberCard": true, "caresAboutCardName": false }),
        wellKnownIdentifier: 'ONBOARDING_CUSTOMER_VIRTUAL_TERMINAL',
        backendLogic: 'return `${input.consumerAppUrl}/${!input.v2.application.lightMode ? "flow" : "light"}`;',
        backgroundColor: finalBackgroundColor,
        primaryColor: finalPrimaryColor,
        createdAt: applicationProducts ? applicationProducts.createdAt - 1 : undefined,
        events: {
          on_load: [
            {
              "id": "d8ff6114-5f47-476c-975f-35cc6030f39b",
              "config": {
                "maxWidth": 256
              }
            },
            {
              "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
              "config": {}
            },
            {
              "id": "d7e4ecf2-4886-4423-885d-2bb059494953",
              "config": {}
            },
            {
              "id": "eab1198f-f924-442c-90d7-fca408ee9ef8",
              "config": {
                "what": "Payment details",
                "type": "Subheading",
                "colour": finalPrimaryColor
              }
            },
            {
              "id": "d7e4ecf2-4886-4423-885d-2bb059494953",
              "config": {}
            },
            {
              "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
              "config": {
                "label": "Reference (if known)",
                "type": " → Your ID",
                "value": "",
                "disabled": false,
                "required": true,
                "stashUser": false,
                "isHidden": false,
                "priceMin": 0,
                "priceMax": 50000000,
                "stashPayment": false,
                "stashThing": false
              }
            },
            {
              "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
              "config": {
                "label": (currencyObject && currencyObject.symbol) ? `Amount (${currencyObject.symbol})` : 'Amount',
                "type": " → Total",
                "value": "",
                "disabled": false,
                "required": true,
                "stashUser": false,
                "isHidden": false,
                "priceMin": 0,
                "priceMax": 50000000,
                "stashPayment": false,
                "stashThing": false
              }
            },
            {
              "id": "d7e4ecf2-4886-4423-885d-2bb059494953",
              "config": {}
            },
            {
              "id": "eab1198f-f924-442c-90d7-fca408ee9ef8",
              "config": {
                "what": "Your details",
                "type": "Subheading",
                "colour": finalPrimaryColor
              }
            },
            {
              "id": "d7e4ecf2-4886-4423-885d-2bb059494953",
              "config": {}
            },
            {
              "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
              "config": {
                "label": "Name",
                "type": " → Name",
                "value": "",
                "disabled": false,
                "required": true,
                "stashUser": false,
                "isHidden": false,
                "priceMin": 0,
                "priceMax": 50000000,
                "stashPayment": false,
                "stashThing": false
              }
            },
            {
              "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
              "config": {
                "label": "Email",
                "type": " → Email",
                "value": "",
                "disabled": false,
                "required": true,
                "stashUser": false,
                "isHidden": false,
                "priceMin": 0,
                "priceMax": 50000000,
                "stashPayment": false,
                "stashThing": false
              }
            },
            {
              "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
              "config": {
                "label": "Phone",
                "type": " → Phone",
                "value": "",
                "disabled": false,
                "required": false,
                "stashUser": false,
                "isHidden": false,
                "priceMin": 0,
                "priceMax": 50000000,
                "stashPayment": false,
                "stashThing": false
              }
            },
            {
              "id": "a21eddf2-aa86-4b6a-a2af-8ac279b246f7",
              "config": {
                "label": "Pay now",
                "action": "pay~~||~~~~||~~false",
                "colour": finalPrimaryColor,
                "foregroundColour": finalBackgroundColor,
                "icon": "check",
                "fullWidth": false,
                "dropShadowAndRoundedCorners": true,
                "letterSpacing": 1,
                "backgroundImage": ""
              }
            }
          ]
            .filter(_ => _),
          on_pay: [
            {
              "id": "a33ebfef-fa9a-46e7-a528-bfac93c62183",
              "config": {
                "runForever": false
              }
            },
            {
              "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
              "config": {}
            },
            {
              "id": "debug",
              "config": {
                "what": "Payment successful!",
                "font": `${finalPrimaryColor}--center--120%--false`,
                "backgroundColour": "#FFFFFF",
                "icon": ""
              }
            }
          ],
          on_pay_fail: [
            {
              "id": "71d05208-3781-4c24-996e-c4c0d1c6b228",
              "config": {
                "what": "{paymentFailureReason}",
                "font": `${finalPrimaryColor}--center--100%--false`
              }
            },
            {
              "id": "71d05208-3781-4c24-996e-c4c0d1c6b228",
              "config": {
                "what": "You have not been charged.",
                "font": `${finalPrimaryColor}--center--100%--false`
              }
            }
          ]
        }
      })

      dispatch('REFRESH_REDUX')
      return '/my/account/payment-provider'
    }
  },
  {
    ...baseUseCase,
    'id': 'CHARITY',
    'name': 'Charity',
    'color': '#a55eea',
    'applicationBlocks': ({ user }) => [
      {
        "id": "eab1198f-f924-442c-90d7-fca408ee9ef8",
        "config": {
          "what": "What donations will you take?",
          "type": "Subheading",
          "colour": "#352F33",
          "doAnimate": false
        }
      },
      {
        "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
        "config": {}
      },
      {
        "id": "65ffec0a-c1df-4fac-bab4-70a372c6d6d5",
        "config": {
          "items": [
            "2.00",
            "5.00",
            "10.00",
            "20.00"
          ],
          "label": ""
        }
      },
      {
        "id": "100ada2b-1375-42c0-958a-49e7187a7d73",
        "config": {
          "label": _('ONBOARDING_CUSTOM_AMOUNT'),
          "value": true,
          "required": false,
          "stashUser": false,
          "stashThing": false,
          "specialBehaviour": "None",
          "unhideFormElements": []
        }
      },
      {
        "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
        "config": {}
      }
    ],
    'go': async ({ user }, abData) => {
      const originalAmounts = abData[''].split(', ')
      const amounts = originalAmounts.map(_ => Math.floor(parseFloat(_) * 100))
      window.sticky.assert(amounts.every(a => !isNaN(a)), 'One of the donation amounts was not valid. Use the "xx.yy" format.')
      const customAmount = window.sticky.getDeserializedValueRawPrimitive(abData[_('ONBOARDING_CUSTOM_AMOUNT')])

      const allApplications = await window.sticky.applications.getAll()
      const applicationProducts = allApplications.find(v => v.wellKnownIdentifier === 'products')
      const finalBackgroundColor = applicationProducts ? applicationProducts.backgroundColor : undefined
      const finalPrimaryColor = applicationProducts ? applicationProducts.primaryColor : undefined

      let newApplicationCustomAmount = allApplications.find(v => v.wellKnownIdentifier === 'ONBOARDING_CUSTOM_AMOUNT')

      if (customAmount) {
        newApplicationCustomAmount = newApplicationCustomAmount || await window.sticky.applications.create({
          name: _('ONBOARDING_CUSTOM_AMOUNT'),
          stickyretail: JSON.stringify({ "payRejectTotalZero": true }),
          wellKnownIdentifier: 'ONBOARDING_CUSTOM_AMOUNT',
          backendLogic: 'return `${input.consumerAppUrl}/${!input.v2.application.lightMode ? "flow" : "light"}`;',
          backgroundColor: finalBackgroundColor,
          primaryColor: finalPrimaryColor,
          indentation: 1,
          createdAt: applicationProducts ? applicationProducts.createdAt + 1 : undefined,
          events: {
            on_load: [
              {
                "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
                "config": {}
              },
              {
                "id": "d8ff6114-5f47-476c-975f-35cc6030f39b",
                "config": {
                  "maxWidth": 128
                }
              },
              {
                "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
                "config": {}
              },
              {
                "id": "2d17f33f-f961-420e-9afe-6eef73b22e4f",
                "config": {
                  "value": amounts[0]
                }
              },
              {
                "id": "0e1f0565-5e05-471c-b855-bbe44c20527d",
                "config": {
                  "label": "Amount",
                  "type": " → Total",
                  "value": (amounts[0] / 100).toFixed(2),
                  "priceMin": 0,
                  "priceMax": 50000000
                }
              },
              {
                "id": "b099236f-a87b-43fe-acff-037b63579881",
                "config": {
                  "showBackgroundColor": false,
                  "showShadow": true,
                  "havePadding": true,
                  "blockOnDoneEarly": false,
                  "doScrolling": false
                }
              },
              {
                "id": "a21eddf2-aa86-4b6a-a2af-8ac279b246f7",
                "config": {
                  "label": _('ONBOARDING_DONATE'),
                  "action": "pay~~||~~~~||~~false",
                  "colour": finalPrimaryColor,
                  "foregroundColour": finalBackgroundColor,
                  "icon": "check",
                  "dropShadowAndRoundedCorners": true,
                  "letterSpacing": 1,
                  "fullWidth": false
                }
              },
              applicationProducts && {
                "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
                "config": {}
              },
              applicationProducts && {
                "id": "a21eddf2-aa86-4b6a-a2af-8ac279b246f7",
                "config": {
                  "label": _('BACK'),
                  "action": `application~~||~~${applicationProducts.id}~~||~~false`,
                  "colour": finalBackgroundColor,
                  "foregroundColour": finalPrimaryColor,
                  "icon": "arrowLeft",
                  "dropShadowAndRoundedCorners": true,
                  "letterSpacing": 1,
                  "fullWidth": false
                }
              }
            ]
              .filter(_ => _),
            on_pay: [
              {
                "id": "a33ebfef-fa9a-46e7-a528-bfac93c62183",
                "config": {
                  "runForever": false
                }
              },
              {
                "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
                "config": {}
              },
              {
                "id": "debug",
                "config": {
                  "what": "Thank you for your donation!",
                  "font": `${finalPrimaryColor}--center--120%--false`,
                  "backgroundColour": "#FFFFFF",
                  "icon": ""
                }
              }
            ],
            on_pay_fail: [
              {
                "id": "71d05208-3781-4c24-996e-c4c0d1c6b228",
                "config": {
                  "what": "{paymentFailureReason}",
                  "font": `${finalPrimaryColor}--center--100%--false`
                }
              },
              {
                "id": "71d05208-3781-4c24-996e-c4c0d1c6b228",
                "config": {
                  "what": "You have not been charged.",
                  "font": `${finalPrimaryColor}--center--100%--false`
                }
              }
            ]
          }
        })
      }

      let amountProducts = []
      for (let i = 0; i < amounts.length; i++) {
        const amount = amounts[i]
        const newProduct = await window.sticky.products.create({
          name: window.sticky.Stickypay.formatPriceV2(user.currency, amount),
          price: amount,
          oneTapCheckout: true,
          media: [
            {
              type: 'image',
              url: window.sticky.internals.dynamicSvg.currency({ backgroundColor: '#1A1F35', primaryColor: '#ffffff' }, user.currency, amount),
            }
          ],
          forceNoUserCountryCategories: true
        })
        amountProducts.push(newProduct)
      }
      await window.sticky.products.categories.create({
        name: _('ONBOARDING_DONATE'),
        view: 'grid',
        color: finalPrimaryColor,
        foregroundColor: finalBackgroundColor,
        showName: false,
        products: amountProducts.map(_ => _.id)
      })

      if (applicationProducts) {
        applicationProducts.name = _('ONBOARDING_DONATE')
        applicationProducts.stickyretail.writeTo('oneTapCheckout', true)
        applicationProducts.events.on_load = [
          {
            "id": "b099236f-a87b-43fe-acff-037b63579881",
            "config": {
              "showBackgroundColor": false,
              "showShadow": true,
              "havePadding": true,
              "blockOnDoneEarly": false,
              "doScrolling": false
            }
          },
          {
            "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
            "config": {}
          },
          {
            "id": "d8ff6114-5f47-476c-975f-35cc6030f39b",
            "config": {
              "maxWidth": 128
            }
          },
          {
            "id": "7c9c0a35-d2fc-4306-bcfa-f635fdbda40a",
            "config": {
              "autoExpand": false,
              "pcTheirIdContains": ""
            }
          },
          customAmount && {
            "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
            "config": {}
          },
          customAmount && {
            "id": "a21eddf2-aa86-4b6a-a2af-8ac279b246f7",
            "config": {
              "label": _('ONBOARDING_CUSTOM_AMOUNT'),
              "action": `application~~||~~${newApplicationCustomAmount.id}~~||~~false`,
              "colour": applicationProducts.primaryColor,
              "foregroundColour": applicationProducts.backgroundColor,
              "icon": "gift",
              "fullWidth": false,
              "dropShadowAndRoundedCorners": true,
              "letterSpacing": 1,
              "backgroundImage": ""
            }
          }
        ]
          .filter(_ => _)
        applicationProducts.events.on_pay = [
          {
            "id": "a33ebfef-fa9a-46e7-a528-bfac93c62183",
            "config": {
              "runForever": false
            }
          },
          {
            "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
            "config": {}
          },
          {
            "id": "debug",
            "config": {
              "what": "Thank you for your donation!",
              "font": `${applicationProducts.primaryColor}--center--120%--false`,
              "backgroundColour": "#FFFFFF",
              "icon": ""
            }
          }
        ]
        await window.sticky.applications.save(applicationProducts)
      }
      
      dispatch('REFRESH_REDUX')
      return '/my/account/payment-provider'
    }
  },
  {
    ...baseUseCase,
    'id': 'UNKNOWN',
    'name': 'Other',
    'color': '#607481',
    applicationBlocks: () => [],
    'go': () => {}
  }
]

function ThisModal ({ user, onUpdate, onDone, onClose }) {
  const [currentUwStep, setCurrentUwStep] = useState(0)
  let [abstractState, _setAbstractState] = useState(
    {
      useCase: 'UNKNOWN',
      logoUrl: user.logoUrl,
      primaryColor: '#1A1F35'
    }
  )
  const setAbstractState = (k, v) => {
    _setAbstractState({ ...abstractState, [k]: v })
    onUpdate(k, v)
  }
  useEffect(
    () => {
      window.sticky.bodgeZone.sdkHookCallback = (whatFunction, whatArguments) => {
        const [k, v] = whatArguments
        whatFunction === 'applicationBlockFormValue' && k === '' && (() => {
          setAbstractState('logoUrl', v.substring('uploadImage:'.length))
        })()
        whatFunction === 'applicationBlockFormValue' && k === 'useCase' && (() => {
          setAbstractState('useCase', v)
        })()
      }
      return () => {
        window.sticky.bodgeZone.sdkHookCallback = undefined
      }
    },
    []
  )
  const whichUseCase = useCases.find(_ => _.id === abstractState.useCase)
  return (
    <StyledModal>
      <Modal
        onClose={onClose}
        className={`uw-step-${currentUwStep}`}
      >
        <UltimateWizard
          steps={[
            {
              child: () => (
                <>
                  <ApplicationBlocks applicationBlocks={[
                    {
                      "id": "eab1198f-f924-442c-90d7-fca408ee9ef8",
                      "config": {
                        "what": `Welcome to ${user.partner ? user.partner.name : 'Sticky'}!`,
                        "type": "Heading",
                        "colour": "#352F33"
                      }
                    },
                    {
                      "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
                      "config": {}
                    },
                    {
                      "id": "eab1198f-f924-442c-90d7-fca408ee9ef8",
                      "config": {
                        "what": "Let's get started with your logo",
                        "type": "Subheading",
                        "colour": "#352F33"
                      }
                    },
                    {
                      "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
                      "config": {}
                    },
                    {
                      "id": "4e6f993d-ddc9-43ea-aadb-7080bee647bc",
                      "config": {
                        "label": "",
                        "buttonText": "Upload",
                        "customValue": `uploadImage:${abstractState.logoUrl}`,
                        "doDropShadow": false
                      }
                    }
                  ]} />
                </>
              ),
              canGoNext: () => 1
            },
            {
              child: ({ onNext }) => <>
                <ApplicationBlocks
                  applicationBlocks={[
                    {
                      "id": "eab1198f-f924-442c-90d7-fca408ee9ef8",
                      "config": {
                        "what": "What's your main colour?",
                        "type": "Subheading",
                        "colour": "#352F33",
                        "doAnimate": false
                      }
                    }
                  ]}
                />
                <Form
                  onChange={setAbstractState}
                >
                  <ColourPicker
                    name='primaryColor'
                    label=''
                    currentColour={abstractState.primaryColor}
                    cellSize={1.5}
                  />
                </Form>
              </>,
              canGoPrevious: () => 1,
              canGoNext: () => 1
            },
            {
              child: ({ onNext }) => <>
                <ApplicationBlocks applicationBlocks={[
                  {
                    "id": "eab1198f-f924-442c-90d7-fca408ee9ef8",
                    "config": {
                      "what": "How should this dashboard be set up?",
                      "type": "Subheading",
                      "colour": "#352F33",
                      "doAnimate": false
                    }
                  },
                  {
                    "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
                    "config": {}
                  },
                  {
                    "id": "5dc584b0-b0ef-4e4f-a8cc-476e3c8fc36b",
                    "config": {
                      "masterReference": "useCase",
                      "howTo": "Custom2",
                      "label": "",
                      "customValue": abstractState.useCase,
                      "doAnimate": false,
                      "list": useCases.map(uc => ({
                        name: uc.name,
                        nameKey: uc.id,
                        imageUrl: `${window.sticky.cdn}/onboarding-use-cases/${uc.id}.svg`
                      })),
                      "columns": 3
                    }
                  },
                  {
                    "id": "6121bb17-a3b4-4df4-b64e-1149ce4d7140",
                    "config": {}
                  }
                ]}/>
              </>,
              canGoPrevious: () => 1,
              canGoNext: () => whichUseCase && whichUseCase.applicationBlocks({ user }).length > 0 ? 1 : 0,
              canGoFinish: () => whichUseCase && whichUseCase.applicationBlocks({ user }).length === 0
            },
            {
              child: ({ onNext }) => {
                return (
                  <>
                    <ApplicationBlocks applicationBlocks={whichUseCase.applicationBlocks({ user })} />
                  </>
                )
              },
              canGoPrevious: () => 1,
              canGoNext: () => 0,
              canGoFinish: () => true
            }
          ]}
          onChangeStep={setCurrentUwStep}
          onDone={() => {
            ;(async () => {
              const abFormData = window.sticky.applications.blocks.getFormData()
              dispatch('LOADING')
              try {
                const maybeRedirectTo = await whichUseCase.go({ user }, abFormData)
                onDone({ maybeRedirectTo })
              } catch ({ message }) {
                window.sticky.flow.showError(message, true)
                return
              } finally {
                dispatch('STOP_LOADING')
              }
            })()
          }}
        />
      </Modal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState }) => {
    setState({ ONBOARDING: true })
  },
  render: function ONBOARDING ({ state, setState, dispatch, user }) {
    const { ONBOARDING } = state
    if (!ONBOARDING) {
      return null
    }

    const onUpdate = (k, v) => {
      k === 'logoUrl' && (async () => {
        try {
          user.logoUrl = v
          await window.sticky.users.save(undefined, ['logoUrl'])
          dispatch('REFRESH_REDUX')
        } catch (_e) {
        }
      })()
      k === 'primaryColor' && (async () => {
        try {
          const applications = await window.sticky.applications.getAll()
          for (let i = 0; i < applications.length; i++) {
            const a = applications[i]
            a.patch({ primaryColor: v })
            await window.sticky.applications.save(a)
          }
          dispatch('REFRESH_REDUX')
        } catch (_e) {}
      })()
    }
    const onDone = ({ maybeRedirectTo }) => {
      dispatch('ONBOARDING_GOOD', { maybeRedirectTo })
      setState({
        ONBOARDING: undefined
      })
    }
    const onClose = () => {
      dispatch('ONBOARDING_GOOD')
      setState({
        ONBOARDING: undefined
      })
    }

    return (
      <ThisModal
        user={user}
        onUpdate={onUpdate}
        onDone={onDone}
        onClose={onClose}
      />
    )
  }
}
