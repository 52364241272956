import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, Button, ApplicationBlocks } from '@openbox-app-shared'

import Box from './Box'
import H2 from './H2'
import dashboardIcons from '../icons'

const StyledComponent = styled.div`
  .grid {
    > * + * {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 960px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0 !important;
      }
    }
  }
  > .connections {
    .connection {
      > .component--box {
        > * + * {
          margin-top: 1rem;
        }
      }
    }
    .instructions {
      padding: 1rem;
    }
  }
  .buttons {
    margin-bottom: 0;
    > * {
      display: inline-block;
      margin: 0 1rem 0 0;
      vertical-align: top;
    }
    > *:last-child {
      margin-right: 0;
    }
  }
  > .new-connection {
    margin-top: 1rem;
    padding-bottom: 0;
    padding-right: 0;
    .connections {
      padding-top: 0.5rem;
      > * {
        display: inline-block;
        vertical-align: top;
        margin: 0 1rem 1rem 0 !important;
        width: 12rem;
        > * {
          display: block;
        }
        > img {
          width: 6rem;
          height: 6rem;
          margin: 0 auto 0 auto;
        }
        > .component--button {
          width: 100%;
          margin-top: 1rem;
          padding-left: 0;
          padding-right: 0;
          font-size: 90%;
        }
      }
      > *:last-child {
        margin-right: 0;
      }
    }
  }
`

export default function Connections ({ allConnections, connectedConnections, onUpdate }) {
  const connectionsNotConfigured = allConnections.filter(c => !connectedConnections.find(_ => _.id === c.id))
  let [configArrays, setConfigArrays] = useState(connectedConnections.map(c => c.config))

  function onAdd (id, config) {
    const toPush = {
      id,
      config
    }
    connectedConnections.push(toPush)
    configArrays.push(config)
    setConfigArrays([ ...configArrays ])
    onUpdate(connectedConnections)
  }

  function onDelete (id, cIndex) {
    connectedConnections = connectedConnections.filter(c => c.id !== id)
    configArrays = configArrays.filter((e, i) => i !== cIndex)
    setConfigArrays([ ...configArrays ])
    onUpdate(connectedConnections)
  }

  return (
    <StyledComponent className='component--connections'>
      {connectedConnections.length > 0 && (
        <div className='connections'>
          {connectedConnections.map(({ id, config }, cIndex) => {
            const thisConnection = allConnections.find(_ => _.id === id) || { color: '#607481', name: 'Unknown connection', configNames: [], instructions: [] }
            return (
              <Box key={id} className='connection grid'>
                <Box>
                  <H2 color={thisConnection.color}>{thisConnection.name}</H2>
                  <Form>
                    {thisConnection.configNames.map((cn, cfIndex) => {
                      return (
                        <Input
                          key={cn}
                          label={cn}
                          color={thisConnection.color}
                          value={configArrays[cIndex][cfIndex]}
                          onChange={v => {
                            configArrays[cIndex][cfIndex] = v
                            setConfigArrays([ ...configArrays ])
                            onUpdate(connectedConnections)
                          }}
                        />
                      )
                    })}
                  </Form>
                  <div className='buttons'>
                    <Button
                      isSecondary
                      InlineIcon={dashboardIcons.void}
                      onClick={() => onDelete(id, cIndex)}
                    >
                      Disconnect
                    </Button>
                  </div>
                </Box>
                {thisConnection.instructions.length > 0 && (<div className='instructions' style={{ border: `4px solid ${thisConnection.color}`, borderRadius: '8px' }}>
                  <ApplicationBlocks
                    applicationBlocks={thisConnection.instructions}
                  />
                </div>)}
              </Box>
            )
          })}
        </div>
      )}
      {connectionsNotConfigured.length > 0 && (
        <Box className='new-connection'>
          <H2>Connect to...</H2>
          <div className='connections'>
            {connectionsNotConfigured.map(c => {
              return (
                <Box className='connection' key={c.id}>
                  <img src={c.logo} alt={c.name} />
                  <Button
                    backgroundColor={c.color}
                    InlineIcon={dashboardIcons.add}
                    onClick={() => onAdd(c.id, c.configDefaults)}
                  >
                    {c.name}
                  </Button>
                </Box>
              )
            })}
          </div>
        </Box>
      )}
    </StyledComponent>
  )
}
Connections.propTypes = {
  allConnections: PropTypes.arrayOf(PropTypes.object),
  connectedConnections: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func
}
