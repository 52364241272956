/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dashboardIcons from '../../icons'
import { dispatch } from '../../redux'

import { CustomHelmet, List, ListItem, Loading, Button } from '@openbox-app-shared'
import { subscribe } from '../../redux'
import { TagChooser } from '../../components/TagChooser'

const StyledComponent = styled.div`
  padding-top: 1rem;
  .component--button + .component--list {
    margin-top: 1rem;
  }
`

function RenderChild ({ context }) {
  const { user } = context.props
  const [shortLinks, setShortLinks] = useState()

  function onAction (id, action) {
    const entity = shortLinks.find(_ => _.id === id)
    action === 'copy' && (() => {
      window.sticky.shortLinks.share(entity.getUrl)
    })()
    action === 'delete' && (() => {
      dispatch('SURE_DELETE', { why: 'shortLinks', entity })
    })()
  }

  async function getShortLinks (force) {
    const _ = (await window.sticky.shortLinks.getAll(force))
    setShortLinks(_)
  }

  async function getShortLinksWrapper (force) {
    try {
      await getShortLinks(force)
    } catch (e) {
      dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
    }
  }

  useEffect(
    () => {
      const subscriptions = [
        subscribe(
          'CREATE_SHORT_LINK_GOOD',
          ({ shortLink }) => {
            window.sticky.shortLinks.share(shortLink.getUrl)
            getShortLinksWrapper(true)
          }
        ),
        subscribe(
          'SURE_DELETE_GOOD',
          async ({ why, entity }) =>  {
            why === 'shortLinks' && (async () => {
              try {
                await window.sticky.shortLinks.remove(entity)
                getShortLinks()
              } catch ({ message }) {
                window.sticky.applications.blocks.showError(message)
              }
            })()
          }
        )
      ]
      getShortLinksWrapper()
      return () => {
        subscriptions.forEach(s => s())
      }
    },
    []
  )

  return (
    <StyledComponent>
      <CustomHelmet title={`Links | Account | ${user.name}`} />
      <Button
        InlineIcon={dashboardIcons.add}
        onClick={() => {
          dispatch('CREATE_SHORT_LINK')
        }}
      >
        Link
      </Button>
      {!shortLinks && (
        <Loading />
      )}
      {shortLinks && (
        <>
          <List>
            {shortLinks.map(_ => {
              const tags = []
              _.application && tags.push({ name: _.application.name, color: '#F0F1F3', foregroundColor: '#1A1F35', icon: _.application.baseIcon })

              const currentRhsC = [
                _.internalTags.includes('used') && {
                  id: 'used',
                  name: 'Opened',
                  color: '#fd9644'
                },
                _.internalTags.includes('paymentPaid') && {
                  id: 'paymentPaid',
                  name: 'Paid',
                  color: '#40cf7a'
                }
              ]
                .filter(_ => _)

              const rightSideChildren = <TagChooser
                all={currentRhsC}
                set={window.sticky.newPatchableSet(currentRhsC.map(_ => _.id))}
                can={false}
              />
              return (
                <ListItem
                  id={_.id}
                  key={_.id}
                  InlineIcon={dashboardIcons.link}
                  actions={['copy', 'delete']}
                  onAction={onAction}
                  tags={tags}
                  goTo={_.getUrl}
                  target='_blank'
                  rightSideChildren={rightSideChildren}
                >
                  {_.name}
                  {/* {_.name} ［ <code>{_.customSlug}</code> ］ */}
                </ListItem>
              )
            })}
          </List>
        </>
      )}
    </StyledComponent>
  )
}

const tab = {
  id: 'short-links',
  name: (context) => 'Links',
  inlineIcon: dashboardIcons.link,
  to: (context) => '/my/account/short-links',
  child: props => <RenderChild {...props} />
}

export default tab
